import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AddSecurityLabelComponent } from './steps/add-security-label/add-security-label.component';
import { MapFieldsComponent } from './steps/map-fields/map-fields.component';
import { ProcessingComponent } from './processing.component';

@NgModule({
  declarations: [
	AddSecurityLabelComponent, 
  	MapFieldsComponent, 
  	ProcessingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
	FormsModule,
	ReactiveFormsModule    
  ],
  entryComponents: [
	AddSecurityLabelComponent, 
  	MapFieldsComponent  
  ],
  exports: [
  	ProcessingComponent
  ]
})
export class ProcessingModule { }
