import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ISuperOfficeField } from './action.interface';
import { ActionService } from './action.service';

@Injectable({
    providedIn: 'root'
})
export class SuperOfficeFieldResolver implements Resolve<ISuperOfficeField> {
    constructor(private actionService: ActionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ISuperOfficeField> | Promise<ISuperOfficeField> | ISuperOfficeField {
        return this.actionService.getSuperOfficeFields();
    }
}
