import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-signup-date',
  templateUrl: './ec-dk-signup-date.component.html',
  styleUrls: ['./ec-dk-signup-date.component.scss']
})
export class EcDkSignupDateComponent implements OnInit {

  periodTypes = [
    { id: 'on', name: 'on' },
    { id: 'before', name: 'before' },
    { id: 'after', name: 'after' },
    { id: 'within', name: 'within' },
    { id: 'withinLast', name: 'within the last' },
    { id: 'today', name: 'today' },
    { id: 'yesterday', name: 'yesterday' },
    { id: 'blank', name: 'is blank'}
  ];

  relativePeriodTypes = [
    { id: 'minutes', name: 'Minutes' },
    { id: 'hours', name: 'Hours' },
    { id: 'days', name: 'Days' },
    { id: 'weeks', name: 'Weeks' },
    { id: 'months', name: 'Months' }
  ];

  negatedPeriodTypes = new Map<string, string>([
    ['on', 'notOn'],
    ['before', 'notBefore'],
    ['after', 'notAfter'],
    ['within', 'notWithin'],
    ['withinLast', 'notWithinLast'],
    ['blank', 'notBlank'],
    ['today', 'notToday'],
    ['yesterday', 'notYestarday'],
  ])


  form: FormGroup;

  checkboxText: string = "not";
  periodTypePlaceholder: string = "Select SignupDate";

  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dialogConfig: DialogConfig) {

    this.criterionId = this.dialogConfig.data.id;
  }


  get negated() {
    return this.form.get('negated');
  }

  get periodType() { 
    return this.form.get('periodType'); 
  }

  get relativePeriodType() {
    return this.form.get('relativePeriodType');
  }

  get startDate() {
    return this.form.get('startDate');
  }

  get endDate() {
    return this.form.get('endDate');
  }  

  get offset() {
    return this.form.get('offset');
  }

  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

    console.log('------------DATA--------------------------------')
    console.log(JSON.stringify(this.data))
    console.log('------------DATA--------------------------------')  

    this.form =  this.formBuilder.group({
      'negated': this.formBuilder.control(this.data.negated),
      'periodType': this.formBuilder.control(this.data.periodType, [Validators.required]),
      'relativePeriodType': this.formBuilder.control(this.data.relativePeriodType, [Validators.required]),  		
      'startDate': this.formBuilder.control(this.data.startDate, [Validators.required]),
      'endDate': this.formBuilder.control(this.data.endDate, [Validators.required]),
      'offset': this.formBuilder.control(this.data.offset, [Validators.required]),
    });

    if (['blank', 'today', 'yesterday'].includes(this.data.periodType)) {
      this.startDate.reset();
      this.startDate.disable();
    }

    if (['blank', 'today', 'yesterday'].includes(this.data.periodType)) {
      this.startDate.reset(null, { emitEvent: false });
      this.startDate.disable({ emitEvent: false });
      this.relativePeriodType.disable({ emitEvent: false });
      this.offset.disable({ emitEvent: false });
      this.endDate.disable({ emitEvent: false });    
    } else if (['on', 'before', 'after'].includes(this.data.periodType)) {
      this.startDate.enable({ emitEvent: false });
      this.relativePeriodType.disable({ emitEvent: false });
      this.offset.disable({ emitEvent: false });
      this.endDate.disable({ emitEvent: false });    
    } else if (this.data.periodType == 'within') {
      this.startDate.enable({ emitEvent: false });  
      this.endDate.enable({ emitEvent: false });
      this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });  
    } else if (this.data.periodType == 'withinLast') {
      this.offset.enable({ emitEvent: false });
      this.relativePeriodType.enable({ emitEvent: false });
      this.startDate.disable({ emitEvent: false });
      this.endDate.disable({ emitEvent: false });    
    }  
    
    let formValue = this.form.value;
    formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, formValue);

    this.form.valueChanges.subscribe(value => {

      if (['blank', 'today', 'yesterday'].includes(value.periodType)) {
        this.startDate.reset(null, { emitEvent: false });
        this.startDate.disable({ emitEvent: false });
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });
        this.endDate.disable({ emitEvent: false });
      }

      if (['on', 'before', 'after'].includes(value.periodType)) {
        this.startDate.enable({ emitEvent: false });
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });
        this.endDate.disable({ emitEvent: false });
      }

      if (value.periodType == 'within') {
        this.startDate.enable({ emitEvent: false });	
        this.endDate.enable({ emitEvent: false });
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });		
      }

      if (value.periodType == 'withinLast') {
        this.offset.enable({ emitEvent: false });
        this.relativePeriodType.enable({ emitEvent: false });
        this.startDate.disable({ emitEvent: false });
        this.endDate.disable({ emitEvent: false });
      }

      value.invalid = this.form.invalid;
      value.offset = value.offset && value.offset.toString().replace(/^0+(?=.)/, '');
      value.description = this.buildDescription();
      this.criterionService.saveData(this.criterionId, value);
    });
  }

  onCheckBoxClick(event: any) {
    this.negated.setValue(event.checked);
  }
  

buildDescription() {
  let timeRestriction: string;
  let description: string;

  if (this.form.invalid) {
       description = 'Configuration has validation errors. Double click to configure.';
  } else {
    const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == this.periodType.value);
    if (periodTypeIndex > -1 && this.periodType.value == 'within' && this.startDate.value && this.endDate.value && !this.negated.value) {
        timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${this.startDate.value} and ${this.endDate.value}`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'within' && this.startDate.value && this.endDate.value && this.negated.value) {
      timeRestriction =  `not between "${this.startDate.value}" and "${this.endDate.value}"`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'withinLast' && this.offset.value && this.relativePeriodType.value  && !this.negated.value) {
        timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${this.offset.value} ${this.relativePeriodType.value}`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'withinLast' && this.offset.value && this.relativePeriodType.value  && this.negated.value) {
        timeRestriction = `not within the last "${this.offset.value} ${this.relativePeriodType.value}"`;
    } else if (periodTypeIndex > -1 && ['on', 'before', 'after'].includes(this.periodType.value) && this.startDate.value  && !this.negated.value) {
        timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${this.startDate.value}`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'on' && this.startDate.value  && this.negated.value) {
        timeRestriction = `not on "${this.startDate.value}"`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'before' && this.startDate.value  && this.negated.value) {
        timeRestriction = `after "${this.startDate.value}"`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'after' && this.startDate.value  && this.negated.value) {
        timeRestriction = `before "${this.startDate.value}"`;
    } else if (periodTypeIndex > -1 && ['blank', 'today', 'yesterday'].includes(this.periodType.value)  && !this.negated.value) {
      timeRestriction = `${this.periodTypes[periodTypeIndex].name}`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'blank' && this.negated.value) {
      timeRestriction = `not blank`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'today' && this.negated.value) {
      timeRestriction = `not today`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'yesterday' && this.negated.value) {
      timeRestriction = `not yesterday`;
  }
     
    if (timeRestriction) {
      description = `Users with the Signup Date '${timeRestriction}'.`
    } else {
      description = "Double click to configure...";
    }	
  } 
  return description;
}
}




