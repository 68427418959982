import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ICdo } from './action.interface';
import { ActionService } from './action.service';

@Injectable({
    providedIn: 'root'
})
export class CdoResolver implements Resolve<ICdo> {
    constructor(private actionService: ActionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ICdo> | Promise<ICdo> | ICdo {
        return this.actionService.getCdos();
    }
}
