import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { OriginConnectionPosition, Overlay, OverlayConfig, OverlayConnectionPosition, OverlayRef, PositionStrategy, FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Observable, Subject } from 'rxjs';

import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuContentComponent } from './context-menu-content/context-menu-content.component';
import { ContextMenuItemDirective } from './context-menu-item.directive';

export interface ContextMenuClickEvent {
	contextMenu: ContextMenuComponent,
	contextMenuSubject: any,
	event: MouseEvent
}

export interface ContextMenuContext {
	target: ElementRef
	menuItems: ContextMenuItemDirective[]
}

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {

  //private show: Subject<ContextMenuClickEvent>;
  
  private registry: ContextMenuComponent[] = [];

  private currentOverlay: OverlayRef;

  private fake: any = {
    getBoundingClientRect: (): ClientRect => ({
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    })
  };  

  constructor(private overlay: Overlay) { 
  	//this.show = new Subject<ContextMenuClickEvent>();
  }

  /*
  sendMessage(message: ContextMenuClickEvent) {
  	this.show.next(message)
  }

  getMessage(): Observable<ContextMenuClickEvent> {
  	return this.show.asObservable();
  }
  */

  register(component: ContextMenuComponent) {
  	this.registry.push(component);
  }

  unregister(component: ContextMenuComponent) {
  	const index: number = this.registry.findIndex(c => c === component);
  	this.registry.splice(index, 1);
  }

  open(ctxEvent: ContextMenuClickEvent) {

  	this.fake.getBoundingClientRect = (): ClientRect => ({
  		bottom: ctxEvent.event.clientY,
        height: 0,
        left: ctxEvent.event.clientX,
        right: ctxEvent.event.clientX,
        top: ctxEvent.event.clientY,
        width: 0,
    });

	const contextMenu: ContextMenuComponent = 
		this.registry.find(component => component == ctxEvent.contextMenu);

  	const target: ElementRef = new ElementRef(this.fake);//new ElementRef(ctxEvent.event.target);

  	const originPos: OriginConnectionPosition = { 
  		originX: 'end', 
  		originY: 'top'
  	};

  	const overlayPos: OverlayConnectionPosition = { 
  		overlayX: 'start', 
  		overlayY: 'top'
  	};

  	const positionStrategy: PositionStrategy = this.overlay.position()
  		.connectedTo(target, originPos, overlayPos);

  	const overlayConfig: OverlayConfig = {
  		positionStrategy,
  		panelClass: "app-contextmenu"
  	};

    this.close();
  	this.currentOverlay = this.overlay.create(overlayConfig);
  	this.attach(this.currentOverlay, contextMenu.visibleMenuItems, ctxEvent.contextMenuSubject);
  }

  close() {
  	if (this.currentOverlay && this.currentOverlay.hasAttached()) {
  		this.currentOverlay.detach();
  		this.currentOverlay.dispose();
  	} 
  }

  private attach(overlay: OverlayRef, menuItems: ContextMenuItemDirective[], subject: any) {
  	const contextMenuRef: ComponentRef<ContextMenuContentComponent> = 
  		overlay.attach(new ComponentPortal(ContextMenuContentComponent));

  	contextMenuRef.instance.menuItems = menuItems;
  	contextMenuRef.instance.subject = subject;
  }

  /*
  openContextMenu(context: ContextMenuContext) {
  	const originPos: OriginConnectionPosition = { 
  		originX: 'end', 
  		originY: 'top'
  	};

  	const overlayPos: OverlayConnectionPosition = { 
  		overlayX: 'start', 
  		overlayY: 'top'
  	};

  	const positionStrategy: PositionStrategy = this.overlay.position()
  		.connectedTo(context.target, originPos, overlayPos);

  	const overlayConfig: OverlayConfig = {
  		positionStrategy
  	};

  	const overlay = this.overlay.create(overlayConfig);
  	this.attachContextMenu(overlay, context.menuItems);
  }

  private attachContextMenu(overlay: OverlayRef, menuItems: ContextMenuItemDirective[]) {

  	const injector = Injector.create([{
  		provide: 'menuItems',
  		useValue: menuItems
  	}]);

  	const contextMenuRef: ComponentRef<ContextMenuContentComponent> = 
  		overlay.attach(new ComponentPortal(ContextMenuContentComponent));

  	contextMenuRef.instance.menuItems = menuItems;
  }
  */
}
