import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-active-trial-users',
  templateUrl: './ec-dk-active-trial-users.component.html',
  styleUrls: ['./ec-dk-active-trial-users.component.scss']
})
export class EcDkActiveTrialUsersComponent implements OnInit {

  roles: ListItem<number, string>[] = [
    new ListItem(11, 'superusers'), 
    new ListItem(10, 'users'),
    new ListItem(12, 'superusers and users')
  ];

  periodTypes = [
  	{ id: 'on', name: 'on' },
  	{ id: 'before', name: 'before' },
  	{ id: 'after', name: 'after' },
  	{ id: 'within', name: 'within' },
  	{ id: 'withinLast', name: 'within the last' }
  ];

  relativePeriodTypes = [
    { id: 'days', name: 'Days' },
    { id: 'weeks', name: 'Weeks' },
    { id: 'months', name: 'Months' }
  ];

  form: FormGroup;

  placeholder: string = "Select user role!";
  periodicplaceholder: string = "SignupDate operator!";

  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  get role() {
  	return this.form.get('role');
  }

 /* get periodEnabled() {
  	return this.form.get('periodEnabled');
  }*/

  get periodType() { 
  	return this.form.get('periodType'); 
  }

  get relativePeriodType() {
  	return this.form.get('relativePeriodType');
  }

  get startDate() {
    return this.form.get('startDate');
  }

  get endDate() {
    return this.form.get('endDate');
  }  

  get offset() {
  	return this.form.get('offset');
  }

  ngOnInit() {
    const data = this.criterionService.getData(this.criterionId);

  	this.form =  this.formBuilder.group({
      'role': this.formBuilder.control(data.role, [Validators.required]),
    //  'periodEnabled': this.formBuilder.control(data.periodEnabled),
      'periodType': this.formBuilder.control(data.periodType, [Validators.required]),
      'relativePeriodType': this.formBuilder.control(data.relativePeriodType, [Validators.required]),
      'startDate': this.formBuilder.control(data.startDate, [Validators.required]),
      'endDate': this.formBuilder.control(data.endDate, [Validators.required]),
      'offset': this.formBuilder.control(data.offset, [Validators.required])
  	});

    if (['on', 'before', 'after'].includes(data.periodType)) {
  		this.startDate.enable({ emitEvent: false });
  	  	this.relativePeriodType.disable({ emitEvent: false });
  	  	this.offset.disable({ emitEvent: false });
  	  	this.endDate.disable({ emitEvent: false });		
  	} else if (data.periodType == 'within') {
  		this.startDate.enable({ emitEvent: false });	
  		this.endDate.enable({ emitEvent: false });
  		this.relativePeriodType.disable({ emitEvent: false });
  	  	this.offset.disable({ emitEvent: false });	
  	} else if (data.periodType == 'withinLast') {
  		this.offset.enable({ emitEvent: false });
  		this.relativePeriodType.enable({ emitEvent: false });
  		this.startDate.disable({ emitEvent: false });
  		this.endDate.disable({ emitEvent: false });		
  	} else {
  		this.startDate.enable({ emitEvent: false });
  	  	this.relativePeriodType.disable({ emitEvent: false });
  	  	this.offset.disable({ emitEvent: false });
  	  	this.endDate.disable({ emitEvent: false });			
    }

   /* if (this.periodEnabled.value) {
  		this.periodType.enable();
  	} else {
  		this.periodType.disable();
  	}*/
    
    let formValue = this.form.value;
    formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription(formValue);
    this.criterionService.saveData(this.criterionId, formValue);

  	this.form.valueChanges.subscribe(value => {
      if (['on', 'before', 'after'].includes(value.periodType)) {
        this.startDate.enable({ emitEvent: false });
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });
        this.endDate.disable({ emitEvent: false });
      }

      if (value.periodType == 'within') {
        this.startDate.enable({ emitEvent: false });	
        this.endDate.enable({ emitEvent: false });
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });		
      }

      if (value.periodType == 'withinLast') {
        this.offset.enable({ emitEvent: false });
        this.relativePeriodType.enable({ emitEvent: false });
        this.startDate.disable({ emitEvent: false });
        this.endDate.disable({ emitEvent: false });
      }

      /*if (value.periodType) {
        this.periodTypes = null;
        this.periodType.reset(null, { emitEvent: false });
        value.periodType = this.periodType.value;
      }*/

      value.invalid = this.form.invalid;
      value.offset = value.offset && value.offset.toString().replace(/^0+(?=.)/, '');
      value.description = this.buildDescription(value);
  		this.criterionService.saveData(this.criterionId, value);
  	});
  }
 /* onTogglePeriod(event: MouseEvent) {
  	this.periodEnabled.setValue(!this.periodEnabled.value);

  	if (!this.periodEnabled.value) {
      this.periodType.reset();
  		this.periodType.disable();
  	} else {
  		this.periodType.enable();
  	}
  }
*/
  buildDescription(value: any) {
    let description: string;
    let timeRestriction: string;
	  if (this.form.invalid) { // is invalid
		  description = 'Configuration has validation errors. Double click to configure.';
    } else {
         const name = this.roles.find(role => role.id == this.role.value).name;
         const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == value.periodType);

         if (periodTypeIndex > -1 && value.periodType == 'within' && value.startDate && value.endDate) {
          timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate} and ${value.endDate}`;
       } else if (periodTypeIndex > -1 && value.periodType == 'within' && value.startDate && value.endDate) {
         timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate} and ${value.endDate}`;
       } else if (periodTypeIndex > -1 && value.periodType == 'withinLast' && value.offset && value.relativePeriodType) {
         timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.offset} ${value.relativePeriodType}`;
       } else if (periodTypeIndex > -1 && ['on', 'before', 'after'].includes(value.periodType) && value.startDate) {
        timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate}`;
      }
      
    /*     if (!value.PeriodEnabled) {
          description = `Contacts that are active trial  \'${name}\'`;
        } else */
        if (this.role.value  && timeRestriction) {
             description = `Contacts that are active trial  \'${name}\' ${timeRestriction}`;
         	} else {
  		description = `Double click to configure...`;
  	}
  }
  	return description;
  }
}
