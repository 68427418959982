import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, GrouppedListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-user-logins',
  templateUrl: './ec-dk-user-logins.component.html',
  styleUrls: ['./ec-dk-user-logins.component.scss']
})
export class EcDkUserLoginsComponent implements OnInit {

  operators = [
  	{ id: 'equal', name: 'Exactly' },
  	{ id: 'greater', name: 'Greater Than' },
  	{ id: 'less', name: 'Fewer Than' }
  ];  

  periodTypes = [
      { id: 'on', name: 'on' },
      { id: 'before', name: 'before' },
      { id: 'after', name: 'after' },
      { id: 'within', name: 'within' },
      { id: 'withinLast', name: 'within the last' }
    ];

  relativePeriodTypes = [
    { id: 'days', name: 'Days' },
    { id: 'weeks', name: 'Weeks' },
    { id: 'months', name: 'Months' }
  ];

  placeholder = "Select an operator";

  form: FormGroup;
  private readonly criterionId: number;

  constructor(private formBuilder: FormBuilder, private dialogConfig: DialogConfig, private criterionService: CriterionService) {
  	this.criterionId = this.dialogConfig.data.id;
  }

  get operator() { 
  	return this.form.get('operator'); 
  }

  get periodType() { 
  	return this.form.get('periodType'); 
  }

  get relativePeriodType() {
  	return this.form.get('relativePeriodType');
  }

  get startCount() {
    return this.form.get('startCount');
  }
/*
  get endCount() {
    return this.form.get('endCount');
  }
*/
  get startDate() {
    return this.form.get('startDate');
  }

  get endDate() {
    return this.form.get('endDate');
  }  

  get offset() {
  	return this.form.get('offset');
  }

  ngOnInit() {
  	const data = this.criterionService.getData(this.criterionId);

  	this.form =  this.formBuilder.group({
   		'operator': this.formBuilder.control(data.operator, [Validators.required]),
  		'periodType': this.formBuilder.control(data.periodType, [Validators.required]),
  		'relativePeriodType': this.formBuilder.control(data.relativePeriodType, [Validators.required]),
      'startCount': this.formBuilder.control(data.startCount, [Validators.required]),
      //'endCount': this.formBuilder.control(data.endCount, [Validators.required]),
      'startDate': this.formBuilder.control(data.startDate, [Validators.required]),
      'endDate': this.formBuilder.control(data.endDate, [Validators.required]),
      'offset': this.formBuilder.control(data.offset, [Validators.required])
  	});
/*
  	if (['equal', 'greater', 'less'].includes(data.operator)) {
  		this.endCount.disable({ emitEvent: false });
  	}
*/
  	if (['on', 'before', 'after'].includes(data.periodType)) {
  		this.startDate.enable({ emitEvent: false });
  	  this.relativePeriodType.disable({ emitEvent: false });
  	  this.offset.disable({ emitEvent: false });
  	  this.endDate.disable({ emitEvent: false });		
  	} else if (data.periodType == 'within') {
  		this.startDate.enable({ emitEvent: false });	
  		this.endDate.enable({ emitEvent: false });
  		this.relativePeriodType.disable({ emitEvent: false });
  	  this.offset.disable({ emitEvent: false });	
  	} else if (data.periodType == 'withinLast') {
  		this.offset.enable({ emitEvent: false });
  		this.relativePeriodType.enable({ emitEvent: false });
  		this.startDate.disable({ emitEvent: false });
  		this.endDate.disable({ emitEvent: false });		
  	} else {
  		this.startDate.enable({ emitEvent: false });
  	  this.relativePeriodType.disable({ emitEvent: false });
  	  this.offset.disable({ emitEvent: false });
  	  this.endDate.disable({ emitEvent: false });			
  	}

  	let formValue = this.form.value;
  	formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription(formValue);
    this.criterionService.saveData(this.criterionId, formValue);

    this.form.valueChanges.subscribe(value => {
    	/*	
    	if (['equal', 'greater', 'less'].includes(value.operator)) {
    		this.endCount.disable({ emitEvent: false });
    	}
     */
    	if (['on', 'before', 'after'].includes(value.periodType)) {
    		this.startDate.enable({ emitEvent: false });
  		  this.relativePeriodType.disable({ emitEvent: false });
  		  this.offset.disable({ emitEvent: false });
  		  this.endDate.disable({ emitEvent: false });
    	}

    	if (value.periodType == 'within') {
    		this.startDate.enable({ emitEvent: false });	
    		this.endDate.enable({ emitEvent: false });
    		this.relativePeriodType.disable({ emitEvent: false });
  		  this.offset.disable({ emitEvent: false });		
    	}

    	if (value.periodType == 'withinLast') {
    		this.offset.enable({ emitEvent: false });
    		this.relativePeriodType.enable({ emitEvent: false });
    		this.startDate.disable({ emitEvent: false });
    		this.endDate.disable({ emitEvent: false });
    	}
  		
    	value.invalid = this.form.invalid;
      value.startCount = value.startCount && value.startCount.toString().replace(/^0+(?=.)/, '');
      //value.endCount = value.endCount && value.endCount.toString().replace(/^0+(?=.)/, '');
      value.offset = value.offset && value.offset.toString().replace(/^0+(?=.)/, '');        
    	value.description = this.buildDescription(value);
    	this.criterionService.saveData(this.criterionId, value);
    	});
  }

  buildDescription(value: any) {
  	let activityRestriction: string;
  	let timeRestriction: string;
  	let description: string;

  	if (this.form.invalid) {
  		description = 'Configuration has validation errors. Double click to configure.';
  	} else {
  		const operatorIndex = this.operators.findIndex(operator => operator.id == value.operator);
  		const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == value.periodType);

      if (operatorIndex > -1 && ['equal', 'greater' , 'less'].includes(value.operator) && value.startCount !== '' && value.startCount == 0) {
  			activityRestriction = `${this.operators[operatorIndex].name} ${value.startCount} time`;
  		} else if (operatorIndex > -1 && ['equal', 'greater', 'less'].includes(value.operator) && value.startCount == 1) {
  			activityRestriction = `${this.operators[operatorIndex].name} ${value.startCount} time`;
  		} else if (operatorIndex > -1 && ['equal', 'greater', 'less'].includes(value.operator) && value.startCount > 2) {
  			activityRestriction = `${this.operators[operatorIndex].name} ${value.startCount} times`
  		}

  		
  		if (periodTypeIndex > -1 && value.periodType == 'within' && value.startDate && value.endDate) {
  			timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate} and ${value.endDate}`;
  		} else if (periodTypeIndex > -1 && value.periodType == 'within' && value.startDate && value.endDate) {
  			timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate} and ${value.endDate}`;
  		} else if (periodTypeIndex > -1 && value.periodType == 'withinLast' && value.offset && value.relativePeriodType) {
  			timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.offset} ${value.relativePeriodType}`;
  		} else if (periodTypeIndex > -1 && ['on', 'before', 'after'].includes(value.periodType) && value.startDate) {
  			timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate}`;
  		}

  		if (activityRestriction && timeRestriction) {
  			description = `Contacts with the number of logins  ${activityRestriction} ${timeRestriction}`;
  		} else {
  			description = "Double click to configure...";
  		}		
  	}

  	return description;
  }
}
