<div class="dialog">
  <div class="header">
  	<div>Contacts who received Kudos in Lithium...</div>
  </div>
  <div class="content">
    
    <form [formGroup]="form" #ngForm="ngForm">
      <ng-select 
      	class="custom" 
      	style="position: absolute; width: 170px;"
      	[items]="operators" 
      	bindLabel="name" 
      	bindValue="id" 
      	placeholder="{{ placeholder }}" 
      	[formControl]="operator">
      </ng-select>


      <label
        style="position: absolute; width: 40px; height: 24px; left: 180px; top: 0px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      	  <input type="text" maxlength="5096" 
      	    vmcOnlyNumber [formControl]="startCount" 
      	    style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;"> 
      	</span>
      </label>


     ,<!-- <input vmcOnlyNumber [formControl]="startCount" style="position: absolute; width: 40px; height: 24px; left: 180px; top: 0px;"> -->
      <div *ngIf="operator.value != 'between'" style="position: absolute; left: 231px; top: 5px; font-weight: normal">time(s)</div>

      <!-- visible only if 'between' -->
      <div *ngIf="operator.value == 'between'" style="position: absolute; left: 231px; top: 5px; font-weight: normal">and</div>

      <label *ngIf="operator.value == 'between'"
        style="position: absolute; width: 40px; height: 24px; left: 260px; top: 0px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="text" maxlength="5096" 
      		  vmcOnlyNumber [formControl]="endCount" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <!--<input *ngIf="operator.value == 'between'" vmcOnlyNumber [formControl]="endCount" style="position: absolute; width: 40px; height: 24px; left: 260px; top: 0px;">-->
      <div *ngIf="operator.value == 'between'" style="position: absolute; left: 310px; top: 5px; font-weight: normal">times</div>   
      

      <ng-select 
      	class="custom" 
      	style="position: absolute; width: 170px; top: 35px;"
      	[items]="periodTypes" 
      	bindLabel="name" 
      	bindValue="id" 
      	placeholder="{{ placeholder }}" 
      	[formControl]="periodType">
      </ng-select>


      <label *ngIf="periodType.value != 'withinLast'"
        style="position: absolute; width: 120px; height: 24px; top: 35px; left: 180px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="date" maxlength="5096" [formControl]="startDate" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <!--<input *ngIf="periodType.value != 'within the last'" [formControl]="startDate" style="position: absolute; width: 100px; height: 24px; top: 35px; left: 180px;">-->
      <!-- visible only if 'within'  -->
      <div *ngIf="periodType.value == 'within'" style="position: absolute; width: 40px; height: 24px; top: 37px; left: 300px; text-align: center;">and</div>

      <label *ngIf="periodType.value == 'within'" 
        style="position: absolute; width: 120px; height: 24px; top: 35px; left: 340px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="date" maxlength="5096" [formControl]="endDate" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>
      
      <!--<input *ngIf="periodType.value == 'within'" [formControl]="endDate" style="position: absolute; width: 100px; height: 24px; top: 35px; left: 320px;">-->
      
      
      <!-- visible only if 'within the last' -->
      <label *ngIf="periodType.value == 'withinLast'"
        style="position: absolute; width: 40px; height: 24px; left: 180px; top: 35px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="text" maxlength="5096" 
      		  vmcOnlyNumber [formControl]="offset" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <!--<input *ngIf="periodType.value == 'within the last'" vmcOnlyNumber [formControl]="offset" style="position: absolute; width: 40px; height: 24px; left: 180px; top: 35px;">-->
      <ng-select *ngIf="periodType.value == 'withinLast'"
      	class="custom" 
      	style="position: absolute; width: 100px; height: 24px; top: 35px; left: 230px;"
      	[items]="relativePeriodTypes" 
      	bindLabel="name" 
      	bindValue="id" 
      	[formControl]="relativePeriodType">
      </ng-select>
      
    </form>

   <!--
    <form [formGroup]="form" #ngForm="ngForm">
        <ng-select class="custom" style="width: 170px; display: inline-block;"[items]="operators" bindLabel="name" bindValue="name" placeholder="{{ placeholder }}" [formControl]="operator"></ng-select>
        <input vmcOnlyNumber [formControl]="startCount" style="position: absolute; width: 44px; height: 22px; left: 180px; top: 0px;"> 
        <div style="position: absolute; left: 231px; top: 5px; font-weight: normal">and</div>
        <input vmcOnlyNumber [formControl]="endCount" style="position: absolute; width: 44px; height: 22px; left: 260px; top: 0px;">
        <div style="position: absolute; left: 310px; top: 5px; font-weight: normal">times</div>
        
        <ng-select class="custom" style="width: 170px; top: 11px;"[items]="periodTypes" bindLabel="name" bindValue="name" placeholder="{{ placeholder }}" [formControl]="periodType"></ng-select>
	    <input  [formControl]="firstLogin">
        
      </form>
   -->
  </div>
</div>