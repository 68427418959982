import { Type } from '@angular/core';

export class PanelItem {
	public readonly configurationRequired: boolean;
	constructor(public type: string, public name: string, public description: string, public dialogComponentType: Type<any>, public imageUrl: string, public infoLink?: string) { 
		if (dialogComponentType) {
			this.configurationRequired = true
		} else {
			this.configurationRequired = false;
		}
	}
}