import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // Groups that are allowed to access the app
  private accessGroups: string[] = [
    'eci-d-vdl-economicdk-standard@visma.com',
    'eci-d-vdl-economicdk-eloquacloudapp@visma.com',
    'vit-d-vdl-corporate-developers@visma.com',
    'vit-d-vdl-vitcno-marketingautomationteam@visma.com',
    'swf-d-vdl-swfi-eloquacloudapp@visma.com',
    'vit-d-vdl-in-ic-standard@visma.com',
    'bld-d-vdl-endk-eloquacloudapp@visma.com'
  ];

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // if not authenticated, then redirect to login page
    let loggedIn: boolean = this.checkLogin();
    if (!loggedIn) {
    	localStorage.setItem('redirectUrl', state.url);
    	this.authService.redirect();
    }
    // if not authorized, then redirect to /accessdenied 
    let hasAccess: boolean = this.hasAccess();
    if (!hasAccess) {
      console.log('REDIRECT')
      this.router.navigate(['denied']);
    }
	  return true;
  }

  checkLogin(): boolean {
  	// jwt header.payload cookie
  	const jwtHeaderPayload = this.authService.getCookie('hpl');

    if (jwtHeaderPayload) {
    	const jwtPayload = jwtHeaderPayload.substring(jwtHeaderPayload.indexOf('.', 0) + 1);
      const payload = JSON.parse(atob(jwtPayload));
      const currentTimestamp = Math.round((new Date()).getTime() / 1000);

    	if (currentTimestamp < payload['exp']) {
    		return true;
    	}
    }
    return false;
  }

  hasAccess(): boolean {
    const jwtHeaderPayload = this.authService.getCookie('hpl');
    if (jwtHeaderPayload) {
      const jwtPayload = jwtHeaderPayload.substring(jwtHeaderPayload.indexOf('.', 0) + 1);
      const payload = JSON.parse(atob(jwtPayload));
      const groups = payload['groups'] as string[];

      let hasAccess: boolean = this.accessGroups.some(group => groups.includes(group));
      if (hasAccess) {
        return true
      }
    }
    return false;
  }
}
