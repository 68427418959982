import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-segment-company',
  templateUrl: './ec-dk-segment-company.component.html',
  styleUrls: ['./ec-dk-segment-company.component.scss']
})
export class EcDkSegmentCompanyComponent implements OnInit {

  segments: ListItem<number, string>[] = [];

  form: FormGroup;

  segmentPlaceholder: string = "Loading...";
  checkboxText: string = "not";
  
  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  get segment() {
  	return this.form.get('segment');
  }

  get negated() {
  	return this.form.get('negated');
  }

  
  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

    console.log('------------DATA--------------------------------')
    console.log(JSON.stringify(this.data))
    console.log('------------DATA--------------------------------')  

    this.dataService.getSegments().subscribe(segments => {
    	this.segments = segments;
    	this.segmentPlaceholder = 'Select the segment...';
    });

  	this.form =  this.formBuilder.group({
      'segment': this.formBuilder.control(this.data.segment, [Validators.required]),
      'negated': this.formBuilder.control(this.data.negated),
  	});
    
    let formValue = this.form.value;
    formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, formValue);

  	this.form.valueChanges.subscribe(value => {
      value.invalid = this.form.invalid;
      value.description = this.buildDescription();
  		this.criterionService.saveData(this.criterionId, value);
  	});
  }

  onCheckBoxClick(event: any) {
  	this.negated.setValue(event.checked);
  }


 buildDescription() {
  let description: string;

  if (this.form.invalid) {
       description = 'Configuration has validation errors. Double click to configure.';
  } else if (this.segments.length == 0) { 
    description = this.data.description;        
    } else if (this.segment.value && !this.negated.value) {
        const name = this.segments.find(segment => segment.id == this.segment.value).name;
        description = `Contacts having the segment ... \'${name}\'.`
	} else if (this.segment.value && this.negated.value) { // is valid and segment have already been loaded
  		const name = this.segments.find(segment => segment.id == this.segment.value).name;
  		description = `Contacts having the segment  ... \'${name}\'.`
    } else {
      description = "Double click to configure...";
    }	
  return description;
}
}


