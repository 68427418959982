import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-active-ao-users',
  templateUrl: './ec-dk-active-ao-users.component.html',
  styleUrls: ['./ec-dk-active-ao-users.component.scss']
})
export class EcDkActiveAOUsersComponent implements OnInit {

  roles: ListItem<number, string>[] = [new ListItem(11, 'superusers'), new ListItem(10, 'users'),
  new ListItem(12, 'superusers and users')];

  form: FormGroup;

  placeholder: string = "Select user role!";

  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  get role() {
  	return this.form.get('role');
  }

 // get negated() {
 // 	return this.form.get('negated');
//  }

  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);
/*
    this.dataService.getRanks().subscribe(ranks => {
    	this.ranks = ranks;
    	this.placeholder = 'Select a role...';
    });
*/

  	this.form =  this.formBuilder.group({
  		'role': this.formBuilder.control(this.data.role, [Validators.required]),
  	//	'negated': this.formBuilder.control(this.data.negated)
  	});

    const value = this.form.value;
    value.invalid = this.form.invalid;
    value.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, this.form.value);

  	this.form.valueChanges.subscribe(value => {
  		value.description = this.buildDescription();
          value.invalid = this.form.invalid;
          console.log('==================================== = ' );
          console.log('ROLE = ' + value['role']);
  		this.criterionService.saveData(this.criterionId, value);

  		//this.criterionService.saveData(this.criterionId, { description: this.buildDescription(), rankId: value });
  		//const name = this.ranks.find(rank => rank.id == value).name;
  		//this.submit({ id: value, name: name });
  	});
  }

 // onCheckBoxClick(event: any) {
  //	this.negated.setValue(event.checked);
//  }

  buildDescription() {
  	let description: string;
	  if (this.form.invalid) { // is invalid
		  description = 'Configuration has validation errors. Double click to configure.';
    } else if (this.roles.length == 0) { // is valid (means configured), but roless have not been loaded yet
      description = this.data.description;
	  } else if (this.role.value) { // is valid and roles have already been loaded
  		const name = this.roles.find(role => role.id == this.role.value).name;
  		description = `Contacts that are ... \'${name}\'.`
  //	} else if (this.rank.value  && this.negated.value) { // is valid and ranks have already been loaded
  //		const name = this.ranks.find(rank => rank.id == this.rank.value).name;
  //		description = `Contacts having the role that is not equal to \'${name}\'.`
  	} else {
  		description = `Double click to configure...`;
  	}

  	return description;
  }
}
