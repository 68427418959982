import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, ReplaySubject, from } from 'rxjs';
import { delay, toArray, map } from 'rxjs/operators';

export class ListItem<T, U> {
	constructor(public id: T, public name: U) { }
}

export class GrouppedListItem<T, U, V> {
  constructor(public id: T, public name: U, public groupKey: V) { }
}
/*
export class EventCategory {
  constructor(public id: number, public name: string, public level1CategoryId: number, public level1CategoryName: string, public disabled?: boolean) { }
}
*/

export class EventCategory {
  constructor(public id: number, public name: string, public parentId: number, public hasChildren: boolean, public distance: number, public disabled?: boolean) { }
}

export class EduAdminEvent {
  constructor(public id: number, public name: string, public categoryId: number, public categoryName: string, public startDate: string, public endDate: string, public city: string) { }
}

export class SuperOfficeField {
  constructor(public fieldName: string, public fieldLabel: string, public soDataType: string, public bqDataType: string) { }
}


@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _roles: ReplaySubject<ListItem<number, string>[]> = new ReplaySubject<ListItem<number, string>[]>();
  private _salestages: ReplaySubject<ListItem<number, string>[]> =  new ReplaySubject<ListItem<number, string>[]>();
  private _eventCategories: ReplaySubject<EventCategory[]> =  new ReplaySubject<EventCategory[]>();
  private _events: ReplaySubject<EduAdminEvent[]> =  new ReplaySubject<EduAdminEvent[]>();
  private _vlsApplicationTypes: ReplaySubject<ListItem<number, string>[]> = new ReplaySubject<ListItem<number, string>[]>();
  private _productVersions: ReplaySubject<{ id: string, name: string}[]> = new ReplaySubject<{ id: string, name: string}[]>();
  private _licensePartners: ReplaySubject<ListItem<number, string>[]> = new ReplaySubject<ListItem<number, string>[]>();
  private _r12s: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _soFields: ReplaySubject<SuperOfficeField[]> = new ReplaySubject<SuperOfficeField[]>();
  private _courses: ReplaySubject<GrouppedListItem<string, string, string>[]> = new ReplaySubject<GrouppedListItem<string, string, string>[]>();
  private _courseGroups: ReplaySubject<GrouppedListItem<string, string, string>[]> = new ReplaySubject<GrouppedListItem<string, string, string>[]>();
  private _badges: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _certificates: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _userTypes: ReplaySubject<GrouppedListItem<string, string, string>[]> = new ReplaySubject<GrouppedListItem<string, string, string>[]>();
  private _accountViewFeatures: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _subscriptions: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _cstables: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _clicks: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _displays: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _signupSources: ReplaySubject<ListItem<string, string>[]> = new ReplaySubject<ListItem<string, string>[]>();
  private _doceboCourses: ReplaySubject<ListItem<number, string>[]> = new ReplaySubject<ListItem<number, string>[]>();
  
  public roles$: Observable<ListItem<number, string>[]> = this._roles.asObservable();
  public salestages$: Observable<ListItem<number, string>[]> = this._salestages.asObservable();
  public eventCategories$: Observable<EventCategory[]> =  this._eventCategories.asObservable();
  public events$: Observable<EduAdminEvent[]> =  this._events.asObservable();
  public vlsApplicationTypes$: Observable<ListItem<number, string>[]> =  this._vlsApplicationTypes.asObservable();
  public productVersions$: Observable<{ id: string, name: string}[]> = this._productVersions.asObservable();
  public licensePartners$: Observable<ListItem<number, string>[]> = this._licensePartners.asObservable();
  public r12s$: Observable<ListItem<string, string>[]> = this._r12s.asObservable();
  public soFields$: Observable<SuperOfficeField[]> = this._soFields.asObservable();
  public courses$: Observable<GrouppedListItem<string, string, string>[]> = this._courses.asObservable();
  public courseGroups$: Observable<GrouppedListItem<string, string, string>[]> = this._courseGroups.asObservable();
  public badges$: Observable<ListItem<string, string>[]> = this._badges.asObservable();
  public certificates$: Observable<ListItem<string, string>[]> = this._certificates.asObservable();
  public userTypes$: Observable<GrouppedListItem<string, string, string>[]> = this._userTypes.asObservable();
  public accountViewFeatures$: Observable<ListItem<string, string>[]> = this._accountViewFeatures.asObservable();
  public subscriptions$: Observable<ListItem<string, string>[]> = this._subscriptions.asObservable();
  public cstables$: Observable<ListItem<string, string>[]> = this._cstables.asObservable();
  public clicks$: Observable<ListItem<string, string>[]> = this._clicks.asObservable();
  public displays$: Observable<ListItem<string, string>[]> = this._displays.asObservable();
  public signupSources$: Observable<ListItem<string, string>[]> = this._signupSources.asObservable();
  public doceboCourses$: Observable<ListItem<number, string>[]> = this._doceboCourses.asObservable();

  constructor(private http: HttpClient) { }

  // GET /api/feeders/ranks
  getRanks(): Observable<ListItem<number, string>[]> {
  	/*
  	const arr = [new ListItem(53, 'visma-global'), new ListItem(54, 'visma-loon')];
    return from(arr).pipe(delay(63)).pipe(toArray());
  	*/
    // should change
  	return this.http.get<ListItem<number, string>[]>('/api/feeders/ranks');
  }


  // GET /api/feeders/spcsseranks
  getSpcsSeRanks(): Observable<ListItem<number, string>[]> {
  	return this.http.get<ListItem<number, string>[]>('/api/feeders/spcsseranks');
  }

  //GET /api/feeders/addons
  getAddons(): Observable<ListItem<number, string>[]> {
  	return this.http.get<ListItem<number, string>[]>('/api/feeders/addons');
  }

 //GET /api/feeders/segments
  getSegments(): Observable<ListItem<number, string>[]> {
  	return this.http.get<ListItem<number, string>[]>('/api/feeders/segments');
  }

  //GET /api/feeders/subscriptions
  getSubscriptions(){
    this.http.get<ListItem<string, string>[]>('/api/feeders/subscriptions')
        .subscribe(subscriptions => this._subscriptions.next(subscriptions));
    }

  //GET /api/feeders/signupSources
  getSignupSources(){
    this.http.get<ListItem<string, string>[]>('/api/feeders/signupSources')
        .subscribe(signupSources => this._signupSources.next(signupSources));
    }

  //GET /api/feeders/contentServiceTable
  getTables() {
    this.http.get<ListItem<string, string>[]>('/api/feeders/cstables')
        .subscribe(cstables => this._cstables.next(cstables));
  }

  //GET /api/feeders/clicks
    getClicks() {
      this.http.get<ListItem<string, string>[]>('/api/feeders/clicks')
          .subscribe(clicks => this._clicks.next(clicks));
    }

    //GET /api/feeders/displays
    getDisplays() {
      this.http.get<ListItem<string, string>[]>('/api/feeders/displays')
    .subscribe(displays => this._displays.next(displays));
    }

  // GET /api/feeders/ranks
  getRoles() {
    /*
    const arr = [new ListItem(53, 'NO_SW_visma-global'), new ListItem(54, 'NO_SW_visma-loon'), new ListItem(55, 'NO_SW_visma_test')];
    let subject = from(arr).pipe(delay(2000)).pipe(toArray());
    subject.subscribe(data => this._roles.next(data));*/
    //return from(arr).pipe(delay(2000)).pipe(toArray());
    
    // should change
  	this.http.get<ListItem<number, string>[]>('/api/feeders/roles')
        .subscribe(roles => this._roles.next(roles));
  }

  // GET /api/feeders/spcsseroles
  getSpcsSeRoles() {
  	this.http.get<ListItem<number, string>[]>('/api/feeders/spcsseroles')
        .subscribe(roles => this._roles.next(roles));
  }

  getSalesStages(buId: string): Observable<ListItem<number, string>[]> {
    return this.http.get('/api/feeders/salestages?bu=' + buId).pipe(
      map((items: any[]) => {
        let stages = [];
        items.forEach(item => stages.push(new ListItem(item.name, item.name)));
        return stages;
      }))

    /*
    const arr = [new ListItem(3, '[SR_SALE_SOLD]'), new ListItem(2, '[SR_SALE_OPEN]'),
      new ListItem(3, '[SR_SALE_LOST]'), new ListItem(4, '[SR_SALE_STALLED]')];
    return from (arr).pipe(delay(63)).pipe(toArray());*/
  }

  getEvents() {   
    this.http.get<EduAdminEvent[]>('/api/feeders/events')
      .subscribe(events => this._events.next(events));
    
    /*
    const arr = [
      new EduAdminEvent(1, 'First', 999, 'Ikke publiserte kurs, Ikke publiserte kurs', '2019-10-01', '2019-10-01T12:00:00.000', 'Oslo'),
      new EduAdminEvent(2, 'Second', 999, 'ZZZZZZ', '2019-10-02', '2019-10-02T11:00:00.000', 'Oslo'),
      new EduAdminEvent(3, 'Third', 999, 'ZZZZZZ', '2019-10-02', '2019-10-02T11:00:00.000', 'Oslo'),
      new EduAdminEvent(4, 'Fourth', 999, 'Ikke publiserte kurs, Ikke publiserte kurs', '2019-10-01', '2019-10-01T12:00:00.000', 'Oslo'),
      new EduAdminEvent(5, 'Fifth', 999, 'ZZZZZZ', '2019-10-02', '2019-10-02T11:00:00.000', 'Oslo'),
      new EduAdminEvent(6, 'Sixth', 999, 'ZZZZZZ', '2019-10-02', '2019-10-02T11:00:00.000', 'Oslo')
    ]

    this._events.next(arr);*/
  }

  getEventCategories(): Observable<EventCategory[]> {
    return this.http.get('/api/feeders/eventcategories').pipe(
      map((items: any[])=> {
        let categories = [];
        items.forEach(item => {
          categories.push(new EventCategory(item.id, item.name, item.parentId, 
            item.hasChildren, item.distance, item.distance == 0 ? false : true))
        })
        return categories;
      })
    )
    /*
    const arr = [
      new EventCategory(1, 'Administrasjon', undefined, false, 0, false),
      new EventCategory(2, 'Ikke publiserte kurs', undefined, true, 0, false),
      new EventCategory(3, 'Sessions', 2, false, 1, true),
      new EventCategory(4, 'Microsoft Office', undefined, true, 0),
      new EventCategory(5, 'Excel', 4, true, 1, true),
      new EventCategory(6, 'Sessions', 5, true, 2, true),
      new EventCategory(7, 'Participation Excel Update', 6, false, 3, true),
      new EventCategory(8, 'Outlook', 4, false, 1, true),
      new EventCategory(9, 'PowerPoint', 4, false, 1, true)
    ];*/

    /*
    const arr = [
      new EventCategory(1, 'Administrasjon', undefined, undefined),
      new EventCategory(2, 'Ikke publiserte kurs', undefined, undefined),
      new EventCategory(3, 'Sessions', 2, 'Ikke publiserte kurs'),
      new EventCategory(4, 'Microsoft Office', undefined, undefined),
      new EventCategory(5, 'Excel', 4, 'Microsoft Office'),
      new EventCategory(6, 'Sessions', 5, 'Excel'),
      new EventCategory(7, 'Participation Excel Update', 6, 'Sessions'),
      new EventCategory(8, 'Outlook', 5, 'Excel'),
      new EventCategory(9, 'PowerPoint', 5, 'Excel')
    ]
    */

    //return from(arr).pipe(delay(70)).pipe(toArray());
  }

  getApplicationTypes(distributor: string): Observable<ListItem<string, string>[]> {

    const params = {
      distributor: distributor
    }

    return this.http.get('/api/feeders/applicationtypes', { params }).pipe(
      map((items: any[]) => {
        let applicationTypes = [];
        items.forEach(item => applicationTypes.push(new ListItem(item.applicationType, item.applicationType)))
        return applicationTypes;
      }));
    
    /*const arr = [new ListItem('Visma .NET Financials', 'Visma .NET Financials'), new ListItem('Visma Banking', 'Visma Banking')];
    return from(arr).pipe(delay(63)).pipe(toArray());*/
  }

  getVLSApplicationTypes(distributor: string) {
    const params = {
      buId: distributor
    }

    this.http.get('/api/feeders/vlsApplicationTypes', { params }).pipe(
      map((items: any[]) => {
        let applicationTypes = [];
        items.forEach(item => applicationTypes.push(new ListItem(item.id, item.name)))
        return applicationTypes;
      })).subscribe(applicationTypes => this._vlsApplicationTypes.next(applicationTypes));
    /*
    setTimeout(() => this._vlsApplicationTypes.next([
      new ListItem(1, 'Visma .NET Financials'), 
      new ListItem(2, 'Visma Banking')
    ]), 100);
    */
  }

  getProductVersions(applicationTypeId: number, distributor: string) {
    const params = {
      applicationTypeId: applicationTypeId.toString(),
      buId: distributor
    }    
    this.http.get('/api/feeders/productversions', { params }).pipe(
      map((items: any[]) => {
        let applicationTypes = [];
        items.forEach(item => applicationTypes.push({ id: item.version, name: item.version }))
        return applicationTypes;
      })).subscribe(productVersions => this._productVersions.next(productVersions)); 
    
    /*
    if (applicationTypeId == 1) {
      setTimeout(() => this._productVersions.next([
        { id: "Financials 1", name: "Financials 1" }, 
        { id: "Financials 2", name: "Financials 2" }, 
        { id: "Financials 3", name: "Financials 3" }
      ]), 100);
    } else {
      setTimeout(() => this._productVersions.next([
        { id: "Banking 1", name: "Banking 1" }, 
        { id: "Banking 2", name: "Banking 2" }
      ]), 100);
    }
    */
  }

  getLicensePartners(distributor: string) {
    const params = {
      buId: distributor
    }

    this.http.get('/api/feeders/partners', { params }).pipe(
      map((items: any[]) => {
        let partners = [];
        items.forEach(item => partners.push(new ListItem(item.id, item.name)))
        return partners;
      })).subscribe(partners => this._licensePartners.next(partners));
    /*
    setTimeout(() => this._licensePartners.next([
      new ListItem(1, 'Partner 1'),
      new ListItem(2, 'Partner 2'),
      new ListItem(3, 'Partner 3')
    ]), 100)
    */
  }

  getR12s(distributor: string) {
    const params = {
      buId: distributor
    }
    this.http.get('/api/feeders/r12', { params }).pipe(
      map((items: any[]) => {
        let r12s = [];
        items.forEach(item => r12s.push(new ListItem<string, string>(item.id, item.name)))
        return r12s;
      })).subscribe(r12s => this._r12s.next(r12s));
    
    
   /*setTimeout(() => this._r12s.next([
      new ListItem<string, string>('103', 'Visma Control'),
      new ListItem<string, string>('411', 'AutoInvoice'),
      new ListItem<string, string>('999', 'Other')
    ]), 100)*/
  }

  getSuperOfficeFields(distributor: string, entity: string) {   
    const params = {
      distributor: distributor,
      entity: entity
    }
    
    this.http.get<SuperOfficeField[]>('/api/feeders/sofields', { params })
      .subscribe(fields => this._soFields.next(fields));

    /* 
    const arr = [
      new SuperOfficeField('Status', 'Status', 'ShortText', 'STRING'),
      new SuperOfficeField('email_emailAddress', 'Email', 'ShortText', 'STRING'),
    ]

    this._soFields.next(arr);   
    */   
  }

  getCourses(branchIds: string[]) {
    const params = {
      branchIds: branchIds.join(',')
    }

    this.http.get('/api/feeders/talentlmscourses', { params }).pipe(
      map((items: any[]) => {
        let courses = [];
        items.forEach(item => courses.push(new GrouppedListItem<string, string, string>(String(item.courseId), item.courseName, item.branchName)))
        return courses;
      })).subscribe(courses => this._courses.next(courses))
    
   
   /*setTimeout(() => this._courses.next([
      new GrouppedListItem<string, string, string>('103', 'Course1', 'softwarebv'),
      new GrouppedListItem<string, string, string>('444', 'Course2', 'softwarebv'),
      new GrouppedListItem<string, string, string>('999', 'Course3','internalsoftwarebv')
    ]), 100) */
  }

  getDoceboCourses() {
	  this.http.get<ListItem<number, string>[]>('/api/feeders/docebocourses')
	    .subscribe(courses => this._doceboCourses.next(courses));
  }

  getCourseGroups(branchIds: string[]) {
    const params = {
      branchIds: branchIds.join(',')
    }

    this.http.get('/api/feeders/talentlmscategories', { params }).pipe(
      map((items: any[]) => {
        let courseGroups = [];
        items.forEach(item => courseGroups.push(new GrouppedListItem<string, string, string>(String(item.categoryId), item.categoryName, item.branchName)))
        return courseGroups;
      })).subscribe(courseGroups => this._courseGroups.next(courseGroups))

   
   /*setTimeout(() => this._courses.next([
      new GrouppedListItem<string, string, string>('103', 'Group1', 'softwarebv'),
      new GrouppedListItem<string, string, string>('444', 'Group2', 'softwarebv'),
      new GrouppedListItem<string, string, string>('999', 'Group3','internalsoftwarebv')
    ]), 100)*/
  }

  getBadges() {
  	/*const arr = [
      new ListItem<string, string>('Activity Newbie', 'Activity Newbie (4 logins)'),
      new ListItem<string, string>('Activity Explorer', 'Activity Explorer (32 logins)'),
      new ListItem<string, string>('Perfectionsim Star', 'Perfectionism Star (16 passed tests or assignments with score 90%+)')];
    return from(arr).pipe(delay(63)).pipe(toArray());*/
    this.http.get<ListItem<string, string>[]>('/api/feeders/badges')
      .subscribe(badges => this._badges.next(badges));
  }

  getAccountViewFeatures() {
    this.http.get<ListItem<string, string>[]>('/api/feeders/accountviewfeatures')
      .subscribe(features => this._accountViewFeatures.next(features));
  }

  getCertificates(branchIds: string[]) {
    const params = {
      branchIds: branchIds.join(',')
    }
    const arr = [new ListItem<string, string>('NL_cert_deelname', 'NL_cert_deelname')];
    this._certificates.next(arr);
    /*this.http.get('/api/feeders/certificates', { params }).pipe(
      map((items: any[]) => {
        let certificates = [];
        items.forEach(item => certificates.push(new ListItem<string, string>(item.id, item.name)));
        return certificates;
      })).subscribe(certificates => this._certificates.next(certificates));*/
  }

  getUserTypes(buId:  string) {
    const params = {
      buId: buId
    }

    this.http.get('/api/feeders/usertypes', { params }).pipe(
      map((items: any[]) => {
        let userTypes = [];
        items.forEach(item => userTypes.push(new GrouppedListItem<string, string, string>(item.name, item.name, item.source)))
        return userTypes;
      })).subscribe(userTypes => this._userTypes.next(userTypes))
  
    /*
    setTimeout(() => this._userTypes.next([
      new GrouppedListItem<string, string, string>('AutoReport User', 'AutoReport User', 'ODP'),
      new GrouppedListItem<string, string, string>('Financials Administrator', 'Financials Administrator', 'ODP'),
      new GrouppedListItem<string, string, string>('Primary contact', 'Primary contact','VLS'),
      new GrouppedListItem<string, string, string>('Application administrator', 'Application administrator','VLS')
    ]), 100)*/
  }
}
