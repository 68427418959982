  import { Component, OnInit } from '@angular/core';
  import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
  import { DialogConfig } from '../../../../shared/dialog/dialog-config';
  import { CriterionService } from '../criterion.service';
  import { DataService, GrouppedListItem } from '../../../data.service';
  
  @Component({
    selector: 'app-ec-dk-mobile-app-used',
    templateUrl: './ec-dk-mobile-app-used.component.html',
    styleUrls: ['./ec-dk-mobile-app-used.component.scss']
  })
  export class EcDkMobileAppUsedComponent implements OnInit {
   
    periodTypes = [
      { id: 'withinLast', name: 'Within the last' },
      { id: 'blank', name: 'Blank' }
    ];
  
    relativePeriodTypes = [
      { id: 'days', name: 'Days' },
      { id: 'weeks', name: 'Weeks' },
      { id: 'months', name: 'Months' }
    ];
  
    placeholder = "Select an operator";
  
    form: FormGroup;
    private readonly criterionId: number;
  
    constructor(
      private formBuilder: FormBuilder, 
      private dialogConfig: DialogConfig, 
      private criterionService: CriterionService) {
        this.criterionId = this.dialogConfig.data.id;
    }
  
    get periodType() { 
      return this.form.get('periodType'); 
    }
  
    get relativePeriodType() {
      return this.form.get('relativePeriodType');
    }

    get offset() {
      return this.form.get('offset');
    }
  
    ngOnInit() {
      const data = this.criterionService.getData(this.criterionId);
  
      this.form =  this.formBuilder.group({
        'periodType': this.formBuilder.control(data.periodType, [Validators.required]),
        'relativePeriodType': this.formBuilder.control(data.relativePeriodType, [Validators.required]),
        'offset': this.formBuilder.control(data.offset, [Validators.required])
      });

      if (data.periodType == 'withinLast') {
        this.offset.enable({ emitEvent: false });
        this.relativePeriodType.enable({ emitEvent: false });
      } else {
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });		
      }
  
      let formValue = this.form.value;
      formValue.invalid = this.form.invalid;
      formValue.description = this.buildDescription(formValue);
      this.criterionService.saveData(this.criterionId, formValue);
  
      this.form.valueChanges.subscribe(value => {
  
        if (value.periodType == 'withinLast') {
          this.offset.enable({ emitEvent: false });
          this.relativePeriodType.enable({ emitEvent: false });
        }
        
        value.invalid = this.form.invalid;
        value.offset = value.offset && value.offset.toString().replace(/^0+(?=.)/, '');        
        value.description = this.buildDescription(value);
        this.criterionService.saveData(this.criterionId, value);
        });
    }
  
    buildDescription(value: any) {
      let timeRestriction: string;
      let description: string;
  
      if (this.form.invalid) {
        description = 'Configuration has validation errors. Double click to configure.';
      } else {
        const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == value.periodType);
        
        if (periodTypeIndex > -1 && value.periodType == 'withinLast' && value.offset && value.relativePeriodType) {
          timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.offset} ${value.relativePeriodType}`;
          description = `Contacts that have used the mobile app ${timeRestriction}`;
        } else if (periodTypeIndex > -1 && value.periodType == 'blank') {
          timeRestriction =` ${this.periodTypes[periodTypeIndex].name}`;
          description = `Contacts that have never used the mobile app`;
        }
  
        if (timeRestriction) {
              description = description;
        } else {
          description = "Double click to configure...";
        }		
      }
  
      return description;
    }
  }
  