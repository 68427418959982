export interface IActivity {
    id: number;
    description: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    typeId: number;
}

export interface IActivityType {
    id: number;
    name: string;
}

export interface ICdo {
    id: number;
    name: string;
}

export interface ISuperOfficeField {
    id: number;
    type: string;
    name: string;
}

export enum EloquaFieldType {
    contact = 'contact',
    company = 'company',
    project = 'project',
    cdo = 'cdo',
    cdoContact = 'cdoContact',
    cdoCompany = 'cdoCompany',
    cdoProject = 'cdoProject',
    activity = 'activity',
    source = 'source',
    action = 'action'
}

export enum EloquaFieldName {
    email = 'EmailAddress',
    firstName = 'FirstName'
}

export enum SuperOfficeFieldName {
    email = 'Emails',
    firstName = 'Firstname',
    name = 'Name'
}

export interface IEloquaField {
    id: number;
    type: string;
    name: string;
    statement: string;
    cdoId: number;
}

export interface IFieldMapping {
    sourceFieldId: number;
    targetFieldId: number;
    configurationId: number;
    eloquaField: IEloquaField;
    superOfficeField: ISuperOfficeField;
}

export enum ConfigurationStepTitles {
    entities = 'Select Entities',
    fields = 'Select Fields',
    activity = 'Create Activity',
    submittedSuccess = 'Success',
    submittedFail = 'Failed'
}

export interface IConfiguration {
    id: number;
    instanceId: string;
    cdoId: number;
    activityId: number;
    projectNumber: string;
    activity: IActivity;
    fieldMappings: Array<IFieldMapping>;
    createOrUpdate: boolean;
    isProject: boolean;
    updateOnly: boolean;
}

export interface ISaveConfiguration {
    cdoId: number;
    isContact: boolean;
    isCompany: boolean;
    isProject: boolean;
    isActivity: boolean;
    updateOnly: boolean;
    fieldMappings: Array<IFieldMapping>;
    instanceId: string;
    cdo: ICdo;
    activity: IActivity;
}
