import { 
	AfterViewInit, 
	ChangeDetectorRef, 
	Component,
	ComponentFactoryResolver, 
	ComponentRef,
	Injector,
	OnInit, 
	Type,
	ViewChild, 
	ViewContainerRef
} from '@angular/core';

import { Lexer } from './lexer';
import { FilterInjector } from './filter-injector';
import { CanvasService } from '../canvas.service';
import { Criterion, Operator, OperatorConfig, ParenthesesConfig } from './filter.model';
import { CriterionComponent, CriterionEvent } from './criterion/criterion.component';
import { ContextMenuComponent } from '../../shared/context-menu';
import { OperatorComponent } from './operator/operator.component';
import { ParenthesesComponent, ParenthesesEvent } from './parentheses/parentheses.component';
import { DndDropEvent } from 'ngx-drag-drop';
import { PanelItem } from '../panel/panel.model';
import { FilterService } from './filter.service';
import { DialogService } from '../../shared/dialog/dialog.service';


@Component({
  selector: 'vmc-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewInit {

  saveButtonDisabled: boolean = false;
  groupButtonDisabled: boolean = true;  
  groupButtonVisible: boolean = true;

  operators: Operator[] = [
    new Operator('AND'),
    new Operator('OR')
  ]

  @ViewChild(ContextMenuComponent)
  contextMenu: ContextMenuComponent;

  @ViewChild('container', { read: ViewContainerRef })
  container: ViewContainerRef;
  
  private criteria: Criterion[];
  private statement: string;
  private uuid: number;
  private componentRefs: ComponentRef<CriterionComponent | OperatorComponent | ParenthesesComponent>[] = [];
  private startIdx: number;
  private endIdx: number;
  private lastSelectedIdx: number;

  constructor(private changeDetectorRef: ChangeDetectorRef, 
    private componentFactoryResolver: ComponentFactoryResolver,
    private filterService: FilterService, private injector: Injector, private canvasService: CanvasService,
    private dialogService: DialogService) { }

  ngOnInit() {
  	this.canvasService.stored.subscribe(() => { this.updateCriteria(); console.log('STORED'); });

  	this.criteria = this.canvasService.getCriteria();

    if (this.criteria.length > 0) {
      this.uuid = Math.min(...this.criteria.map(criterion => criterion.initialId)) || 0;
    } else {
      this.uuid = 0;
    }

    this.statement = this.canvasService.getStatement();
  }

  ngAfterViewInit() {
  	if (this.statement) {
  		this.loadComponents();
  		this.changeDetectorRef.detectChanges();
  	}
  }

  // loads components dynamically
  loadComponents() {
  	this.container.clear();
  	const lexer = new Lexer(this.statement);
    let identationLevel = 0;
    let symbol = lexer.nextSymbol();
    while (symbol !== Lexer.INVALID) {
      const index = this.container.length;

      if (symbol == Lexer.CRITERION) {
        const criterion = this.criteria
          .find(criterion => criterion.id == lexer.nvalue);
        
        criterion.identationLevel = identationLevel;
        criterion.data.index = index;

        if (criterion) {
          this.addCriterion(index, criterion);
        }
      }

      if (symbol == Lexer.OPERATOR) {
        this.addOperator(index, identationLevel, lexer.svalue);
      }

      if (symbol == Lexer.LEFT) {
        this.addParentheses(index, lexer.svalue, identationLevel);
        identationLevel++;
      }

      if (symbol == Lexer.RIGHT) {
      	identationLevel--;
      	this.addParentheses(index, lexer.svalue, identationLevel);
      }

      symbol = lexer.nextSymbol();
    } 
  }

  onDrop(event: DndDropEvent) {
    const index = this.container.length;
    const panelItemIndex = event['data']['index'];
    const identationLevel = 0;

    // drop event carries data associated with the draggable
    const panelItem = this.canvasService.getPanelItem(panelItemIndex);

    if (!this.isEmptyFilter()) {
      this.addOperator(index, identationLevel);
      this.createCriterion(index + 1, panelItem, identationLevel);
    } else {
      this.createCriterion(index, panelItem, identationLevel);
    }

    // update statement of the feeder config
    setTimeout(() => {
      this.buildStatement();
      this.canvasService.setStatement(this.statement);
    }, 100)
  }

  // creates criterion component in the DOM and notifies back-end
  private createCriterion(index: number, panelItem: PanelItem, identationLevel: number): ComponentRef<CriterionComponent> {
    const id = --this.uuid;
    const imageUrl = panelItem.imageUrl.replace('16', '32');
    const invalid = panelItem.configurationRequired;
    const infoLink = panelItem.infoLink;

    const criterion = new Criterion(id, id, panelItem.type, 
      panelItem.name, panelItem.description, imageUrl, infoLink, panelItem.dialogComponentType, 
      { index: index, value: { invalid: invalid } }, identationLevel);

    console.log('-------------------------------------------------------------')
    console.log(JSON.stringify(criterion))
    console.log('-------------------------------------------------------------')

    // add criterion component to the DOM
    const ref = this.addCriterion(index, criterion);

    //const dialogRef = this.dialogService.open(ref.location, panelItem.dialogComponentType, { data: { id: id }});
    // add criterion to the feeder config
    this.canvasService.addCriterion(criterion);

    // call api to create a criterion in the database and enable
    /*
    this.feederService.createCriterion()
      .subscribe(criterion => {
        ref.instance.id = criterion.id;        
        ref.instance.enable();
      }
    );
    */

    return ref;
  }

  // add criterion component to the DOM
  private addCriterion(index: number, config: Criterion): ComponentRef<CriterionComponent> { 
    const map = new WeakMap<any, any>();
    console.log('CONFIG---------------------------------------------------')
    console.log(JSON.stringify(config))
    console.log('CONFIG---------------------------------------------------')
    map.set(Criterion, config);
   //map.set(FeederService, this.feederService);
    map.set(ContextMenuComponent, this.contextMenu);

    const ref = this.createComponent(index, CriterionComponent, map);

    // subscribe to output events of the new component
    ref.instance._select.subscribe(event => this.onSelect(event));
    ref.instance._drop.subscribe(event => this.onInsert(event));
    //ref.instance._context.subscribe(event => this.onContext(event));

    return ref;
  }

  // add operator component to the DOM
  private addOperator(index: number, identationLevel: number, value?: string) {
    const map = new WeakMap<any, any>();
    map.set(OperatorConfig, new OperatorConfig(index, this.operators, identationLevel, value));

    const ref = this.createComponent(index, OperatorComponent, map);
    // subscribe to output event of the component
    ref.instance._click.subscribe(() => setTimeout(() => {
    	console.log('statement = ' + this.buildStatement());
    	this.canvasService.setStatement(this.statement);
    }, 100));

    return ref;
  }

  // add group node component to the DOM
  private addParentheses(index: number, value: any, identationLevel: number) {
    const map = new WeakMap<any, any>();
    map.set(ParenthesesConfig, new ParenthesesConfig(index, value, identationLevel));

    const ref = this.createComponent(index, ParenthesesComponent, map);
    // subsribe to output events of the new component
    ref.instance._select.subscribe(event => this.onGroupSelect(event));
    return ref;
  }

  private createComponent(index: number, componentType: Type<any>, config: WeakMap<any, any>) {

    const componentFactory = this.componentFactoryResolver
      .resolveComponentFactory(componentType);

    const injector = new FilterInjector(this.injector, config);
    const ref = this.container.createComponent(componentFactory, index, injector);   

    this.componentRefs.splice(index, 0, ref);
    this.updateIndexes();

    return ref;
  }  

  private removeComponent(index: number) {
    // detach and destroy view
    const viewRef = this.container.detach(index);
    setTimeout(() => viewRef.destroy(), 50)
    // remove component ref
    const componentRef = this.componentRefs.splice(index, 1)[0];

    if (componentRef.instance instanceof CriterionComponent) {
      const ref = <ComponentRef<CriterionComponent>> componentRef;

      this.canvasService.removeCriterion(ref.instance.initialId);
    }
    // update index state
    this.updateIndexes();
  }

  private removeComponents(...indexes: number[]) {
    let deletedRefs: ComponentRef<CriterionComponent | OperatorComponent | ParenthesesComponent>[] = [];

    indexes.forEach(index => {
      console.log('index = ' + index)
      // detach and destroy view
      const viewRef = this.container.detach(index);
      setTimeout(() => viewRef.destroy(), 50)
      // remove component ref
      deletedRefs.push(this.componentRefs.splice(index, 1)[0]);
    });

    deletedRefs.forEach(componentRef => {
      console.log(componentRef)
      if (componentRef.instance instanceof CriterionComponent) {
        const ref = <ComponentRef<CriterionComponent>> componentRef;
        this.canvasService.removeCriterion(ref.instance.initialId);
      }
    })   
    // update index state
    this.updateIndexes();
  }

  private updateIndexes() {
    this.componentRefs.forEach((ref, idx) => {
      if (ref.instance.index !== idx) {
        ref.instance.index = idx;
      }
    })
  }

  private updateCriteria() {
  	this.criteria = this.canvasService.getCriteria();
  	this.statement = this.canvasService.getStatement();

  	this.componentRefs.forEach(componentRef => {
  		if (componentRef.instance instanceof CriterionComponent) {
  			const ref = <ComponentRef<CriterionComponent>> componentRef;
  			const initialId = ref.instance.initialId;
  			const criterion = this.criteria.find(c => c.initialId == initialId);
  			if (criterion) {
  				ref.instance.statement = criterion.id;
  			}
  		}
  	})
  }

  private buildStatement(): string {
	  let statement = '';
  	this.componentRefs.forEach(ref => {
  		if (ref.instance instanceof CriterionComponent || 
  			ref.instance instanceof OperatorComponent) {
  			statement += ref.instance.statement + ' ';
  		} else {
  			if (ref.instance.statement == '(') {
  				statement += ref.instance.statement;
  			} else {
  				statement = statement.trim();
  				statement += ref.instance.statement + ' ';
  			}
  		}
  	});

  	this.statement = statement;

  	return statement;
  }

  private isEmptyFilter() {
    return this.container.length <= 0 ? true: false;
  }  

  onMouseDown(event: MouseEvent) {
    console.log('mouse down on the dropzone')
    this.groupButtonVisible = true;
    this.filterService.onMouseDown({ event: event })
  }

  onContextMenu(event: MouseEvent) {
    console.log(event);
  }  

  onSelect(event: CriterionEvent) {
    console.log('select ' + event.index)
    const index = event.index;
    const mouseEvent = event.event;
    let prevIdx = index;

    if (mouseEvent.shiftKey) {
      prevIdx = this.lastSelectedIdx == undefined ? index : this.lastSelectedIdx;
    }

    let startIdx: number;
    let endIdx: number;

    if (prevIdx < index) {
      startIdx = prevIdx;
      endIdx = index + 1;
    } else if (prevIdx > index) {
      startIdx = index;
      endIdx = prevIdx + 1;
    } else {
      startIdx = index;
      endIdx = index + 1;
    }

    this.componentRefs
      .slice(startIdx, endIdx)
      .filter(ref => ref.instance instanceof CriterionComponent)
      .map(ref => <ComponentRef<CriterionComponent>> ref)
      .forEach(ref => ref.instance.select());

    this.startIdx = startIdx;
    this.endIdx = endIdx;
    this.lastSelectedIdx = index;

    let count = 0;
    const refs = this.componentRefs.slice(this.startIdx, this.endIdx)
    refs.forEach(ref => {
      if (ref.instance instanceof ParenthesesComponent) {
        const parenthesesComponent = <ParenthesesComponent> ref.instance;

        if (parenthesesComponent.statement == '(') {
          count++;
        } else {
          count--;
        }
      }
    });

    if (this.endIdx - this.startIdx >= 3 && count == 0) {
      this.groupButtonDisabled = false;
    } else {
      this.groupButtonDisabled = true;
    }
  }

  onGroupSelect(event: ParenthesesEvent) {
    const index = event.index
    const ref1 = <ComponentRef<ParenthesesComponent>> this.componentRefs[index];

    const pairedIndex = this.findPairedParenthesesIndex(index);
    const ref2 = <ComponentRef<ParenthesesComponent>> this.componentRefs[pairedIndex];

    ref1.instance.select();
    ref2.instance.select();

    if (index > pairedIndex) {
      this.startIdx = pairedIndex;
      this.endIdx = index;      
    } else {
      this.startIdx = index;
      this.endIdx = pairedIndex;
    }
    console.log(this.startIdx + '   ' + this.endIdx)
    this.groupButtonVisible = false;
  }  

  onInsert(event: CriterionEvent) {
    // index of a container element to insert before
    const index = event.index;
    const ref = this.componentRefs[index];
    const identationLevel = ref.instance.identationLevel;
    // index of a panel item 
    const panelItemIndex = event.data['index'];
    const panelItem = this.canvasService.getPanelItem(panelItemIndex);

    this.createCriterion(index, panelItem, identationLevel);
    this.addOperator(index + 1, identationLevel);

    setTimeout(() => {
      console.log('statement = ' + this.buildStatement());
      this.canvasService.setStatement(this.statement);
    }, 100)
  }

  onGroup(event: MouseEvent) {
    this.groupButtonDisabled = true;

    if (this.endIdx - this.startIdx > 1) {
      const ref = this.componentRefs[this.startIdx]
      const identationLevel = ref.instance.identationLevel;
      
      const prevRef = this.componentRefs[this.startIdx - 1];
      const nextRef = this.componentRefs[this.endIdx];

      let groupped = false;
      if (prevRef && prevRef.instance instanceof ParenthesesComponent) {
        if (nextRef && nextRef.instance instanceof ParenthesesComponent) {
          groupped = true;
        }
      }

      if (!groupped) {
        this.componentRefs.slice(this.startIdx, this.endIdx)
          .forEach(ref => ref.instance.addIdentation());

        console.log(this.startIdx + '   ' + this.endIdx);
        this.addParentheses(this.startIdx, '(', identationLevel);
        this.addParentheses(this.endIdx + 1, ')', identationLevel);

        setTimeout(() => {
          console.log('statement = ' + this.buildStatement());
          this.canvasService.setStatement(this.statement);
        }, 100)
      }
    }
  }

  onUngroup(index: number) {
    console.log(this.startIdx + '   ' + this.endIdx);
    this.removeComponents(this.startIdx, this.endIdx - 1);
    this.componentRefs.slice(this.startIdx, this.endIdx -1)
      .forEach(ref => ref.instance.removeIdentation());
    this.groupButtonVisible = true;

    console.log(this.buildStatement());
    this.canvasService.setStatement(this.statement);    
  }
  onDelete(index: number) {
    const indexes: number[] = [];
    indexes.push(index);

    const prevRef = this.componentRefs[index - 1];
    const nextRef = this.componentRefs[index + 1];

    if (prevRef == undefined && nextRef !== undefined) {
      indexes.push(index);
    }

    if (prevRef !== undefined && prevRef.instance instanceof OperatorComponent && 
        nextRef !== undefined && nextRef.instance instanceof ParenthesesComponent &&
        (<ParenthesesComponent> nextRef.instance).statement == ')') {

      indexes.push(index - 1);
      const pairedBracketIndex = this.findPairedParenthesesIndex(index + 1);
      const c = this.componentRefs[pairedBracketIndex + 1];

      if (index - pairedBracketIndex == 3 || c.instance instanceof ParenthesesComponent) {
        indexes.push(index - 1);
        indexes.push(pairedBracketIndex);

        this.componentRefs.slice(pairedBracketIndex + 1, index)
            .forEach(ref => ref.instance.removeIdentation());
      }

    }

    if (prevRef !== undefined && prevRef.instance instanceof OperatorComponent && 
      nextRef !== undefined && !(nextRef.instance instanceof ParenthesesComponent)) {

      indexes.push(index - 1);
    }

    if (prevRef !== undefined && prevRef.instance instanceof OperatorComponent && 
      nextRef === undefined) {
      indexes.push(index - 1);
    }  

    if (prevRef !== undefined && prevRef.instance instanceof ParenthesesComponent) {

      const pairedBracketIndex = this.findPairedParenthesesIndex(index - 1);
      indexes.push(index);
      const c = this.componentRefs[pairedBracketIndex - 1];
      if (pairedBracketIndex - index == 3 || c.instance instanceof ParenthesesComponent) {
        indexes.push(index - 1);
        indexes.push(pairedBracketIndex - 3);

        this.componentRefs.slice(index, pairedBracketIndex)
            .forEach(ref => ref.instance.removeIdentation())
      }    
    }

    this.removeComponents(...indexes);

    // update statement of the feeder config
    setTimeout(() => {
      console.log('statement = ' + this.buildStatement());
      this.canvasService.setStatement(this.statement);
    }, 100)
  }

  private findPairedParenthesesIndex(startIdx: number): number {
    const componentRef = this.componentRefs[startIdx];
    const forward = componentRef.instance.statement == '(' ? true: false;

    let i: number;
    if (forward) {
      i = startIdx + 1;
    } else {
      i = startIdx - 1;
    }

    let bracketCount = 1;
    let pairedBracketIndex = 0;

    while (bracketCount !== 0) {
      const ref = this.componentRefs[i];
      if (ref.instance instanceof ParenthesesComponent) {
        const parenthesesComponent = <ParenthesesComponent> ref.instance;

        if (parenthesesComponent.statement == componentRef.instance.statement) {
          bracketCount++;
          console.log(bracketCount);
        } else {
          bracketCount--;
          console.log(bracketCount)
        }
      }

      if (bracketCount == 0) {
        pairedBracketIndex = i;
      }

      if (forward) {
        i++;
      } else {
        i--;
      }
    }
    return pairedBracketIndex;
  } 

  onCalculate(event: any) {

  }

}
