import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuContentComponent } from './context-menu-content/context-menu-content.component';
import { ContextMenuDirective } from './context-menu.directive';
import { ContextMenuItemDirective } from './context-menu-item.directive';
import { ContextMenuComponent } from './context-menu.component';

@NgModule({
  declarations: [
  	ContextMenuContentComponent, 
  	ContextMenuDirective, 
  	ContextMenuItemDirective, 
  	ContextMenuComponent
  ],
  imports: [
    CommonModule
  ],
  entryComponents: [
  	ContextMenuContentComponent
  ],
  exports: [
    ContextMenuComponent,
  	ContextMenuDirective,
  	ContextMenuItemDirective  
  ]
})
export class ContextMenuModule { }
