import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-subscription',
  templateUrl: './ec-dk-subscription.component.html',
  styleUrls: ['./ec-dk-subscription.component.scss']
})
export class EcDkSubscriptionComponent implements OnInit {

  subscriptions: ListItem<string, string>[] = [];

  operators = [
      {id: "equal", name: "exactly"},
      {id: "contains", name: "contains"},
      {id: "startsWith", name: "starts with"}
    ];

  form: FormGroup;

  placeholderOperator = "Select an operator";
  Placeholder: string = "Loading...";
  
  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  get subscription() {
  	return this.form.get('subscription');
  }

  get operator() { 
  	return this.form.get('operator'); 
  }

  get textValue() {
    return this.form.get('textValue');
  }
 
  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

    console.log('------------DATA--------------------------------')
    console.log(JSON.stringify(this.data))
    console.log('------------DATA--------------------------------')  

    this.dataService.getSubscriptions()
    this.dataService.subscriptions$.subscribe(subscriptions => {
    	this.subscriptions = subscriptions;
    	this.Placeholder = 'Select the subscription...';
    });

  	this.form =  this.formBuilder.group({
      'subscription': this.formBuilder.control(this.data.subscription, [Validators.required]),
      'operator': this.formBuilder.control(this.data.operator, [Validators.required]),
      'textValue': this.formBuilder.control(this.data.textValue, [Validators.required])
    });
    
    if (this.data.operator == 'equal') {
      this.subscription.enable(),
      this.textValue.disable();
    } else {
      this.subscription.disable();
    //  this.textValue.reset('');
      this.textValue.enable();
    }
    
    let formValue = this.form.value;
    formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription(formValue);
    this.criterionService.saveData(this.criterionId, formValue);

  	this.form.valueChanges.subscribe(value => {

      if (value.operator == 'equal') {
        this.subscription.enable({ emitEvent: false }),
        this.textValue.disable({ emitEvent: false });
      } else {
        this.subscription.disable({ emitEvent: false });
   //     this.textValue.reset('', { emitEvent: false });
        this.textValue.enable({ emitEvent: false });
      }

      value.invalid = this.form.invalid;
      value.description = this.buildDescription(value);
  		this.criterionService.saveData(this.criterionId, value);
  	});
  }

/*
 buildDescription() {
  let description: string;

  if (this.form.invalid) {
       description = 'Configuration has validation errors. Double click to configure.';
  } else if (this.subscriptions.length == 0) { 
    description = this.data.description;        
    } else if (this.subscription.value) {
        const name = this.subscriptions.find(subscription => subscription.id == this.subscription.value).name;
        description = `Contacts having the subscription ... \'${name}\'.`
	} else { 
      description = "Double click to configure...";
    }	
  return description;
}*/
buildDescription(value: any) {
  let subscriptionValue: string;
  let description: string;

  if (this.form.invalid) {
    description = 'Configuration has validation errors. Double click to configure.';
  } else {
      if (this.operator.value == 'equal') {
        subscriptionValue = `${this.subscriptions.find(subscription => subscription.id == this.subscription.value).name}`;
    } else if (['contains', 'startsWith'].includes(this.operator.value)) {
        subscriptionValue = `${this.operators.find(o => o.id == this.operator.value).name} "${this.textValue.value}"`;
    }
    
    if (subscriptionValue) {
      description = `Contacts having the subscription ${subscriptionValue}`;
    } else {
      description = "Double click to configure...";
    }		
  }

  return description;
}
}



