import { Component, Input, OnInit } from '@angular/core';
import { CanvasService } from '../canvas.service';
import { PanelItem } from './panel.model';

@Component({
  selector: 'vmc-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  items: PanelItem[];

  draggables: any[];

  foundDraggables: any[];

  constructor(private canvasService: CanvasService) { }

  ngOnInit() {  
  	this.items = this.canvasService.getPanelItems();
    this.createDraggables();
    this.foundDraggables = this.draggables;
  }

  private createDraggables() {
    this.draggables = this.items.map((item, index) => 
        this.createDraggable(item, index));
  }

  private createDraggable(item: PanelItem, index: number) {
    const draggable = { 
      content: {
        name: item.name,
        iconUrl: item.imageUrl,
        index: index
      },
      effectAllowed: 'copy',
      disable: false,
      handle: false
    };
    return draggable;
  }

  onFound(event: any) {
    this.foundDraggables = event;
  }
}
