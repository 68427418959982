import { 
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy, 
  OnInit,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { DialogConfig } from './dialog-config';
import { DialogRef } from './dialog-ref';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;

  @ViewChild('pointer')
  pointerRef: ElementRef;

  @ViewChild('target', { read: ViewContainerRef })   
  viewContainerRef: ViewContainerRef;

  childComponentType: Type<any>;

  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();

  constructor(private renderer: Renderer2, 
    private componentFactoryResolver: ComponentFactoryResolver, 
    private cd: ChangeDetectorRef,
    private dialogRef: DialogRef) { }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  onOverlayClicked(event: MouseEvent) {
    this.dialogRef.close();
  }

  onDialogClicked(event: MouseEvent) {
    event.stopPropagation();
  }

  loadChildComponent(componentType: Type<any>) {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    this.viewContainerRef.clear();

    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
  }

  addPointerClass(pointerClass: string) {
    this.renderer.addClass(this.pointerRef.nativeElement, pointerClass);
  }  

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  close() {
    this._onClose.next();
  }
}
