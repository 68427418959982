<div class="dialog">
  <div class="header">
  	<div>Contacts having the in app click...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">
      <ng-select class="custom" 
      style="position: absolute; width: 140px;"
      [items]="operators" 
      bindLabel="name" 
      bindValue="id" 
      placeholder="{{ placeholderOperator }}"
      [formControl]="operator" required>
    </ng-select>

   <!-- visible only if contains or start with' -->
   <label *ngIf="operator.value != 'equal'"
    style="position: absolute; width: 480px; height: 24px; left: 150px;top: 0px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
    <span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
     <input type="text" maxlength="5096" 
       [formControl]="textValue" placeholder="Enter a value" 
       style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;"> 
    </span>
    </label>
 
    <ng-select *ngIf="operator.value == 'equal'"
      class="custom" 
      style="position: absolute; left: 150px; width: 480px; top: 0px;"
      [items]="clicks" 
      bindLabel="name" 
      bindValue="id" 
      placeholder="{{ clickPlaceholder }}"
      [formControl]="click" required>
    </ng-select>

    <ng-select 
      	class="custom" 
      	style="position: absolute; width: 140px; top: 35px;"
      	[items]="periodTypes" 
      	bindLabel="name" 
      	bindValue="id" 
      	placeholder="{{ periodTypePlaceholder }}" 
      	[formControl]="periodType">
      </ng-select>

      <label *ngIf="periodType.value != 'withinLast'"
        style="position: absolute; width: 120px; height: 24px; top: 35px; left: 150px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="date" maxlength="5096" [formControl]="startDate" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <div *ngIf="periodType.value == 'within'" style="position: absolute; width: 40px; height: 24px; top: 40px; left: 265px; text-align: center;">and</div>

      <label *ngIf="periodType.value == 'within'" 
        style="position: absolute; width: 120px; height: 24px; top: 35px; left: 300px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="date" maxlength="5096" [formControl]="endDate" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>
      
      <!-- visible only if 'within the last' -->
      <label *ngIf="periodType.value == 'withinLast'"
        style="position: absolute; width: 40px; height: 24px; left: 150px; top: 35px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="text" maxlength="5096" 
      		  vmcOnlyNumber [formControl]="offset" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <!--<input *ngIf="periodType.value == 'within the last'" vmcOnlyNumber [formControl]="offset" style="position: absolute; width: 40px; height: 24px; left: 180px; top: 35px;">-->
      <ng-select *ngIf="periodType.value == 'withinLast'"
      	class="custom" 
      	style="position: absolute; width: 100px; height: 24px; top: 35px; left: 200px;"
      	[items]="relativePeriodTypes" 
      	bindLabel="name" 
      	bindValue="id"  
      	[formControl]="relativePeriodType">
      </ng-select>      

    </form>
  </div>
</div>
