import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Feeder } from './feeder.model';
import { FeederService } from './feeder.service';

import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FeederResolver implements Resolve<Feeder> {
  
  constructor(private feederService: FeederService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Feeder> | Promise<Feeder> | Feeder {
  	const id = route.params['id'];
  	return this.feederService.getFeeder(id);
  }
}