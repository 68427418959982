  
<div class="sc-view" style="width: 380px; height: 80px; top: 30px; left: 7px; overflow: visible;">
  <div class="sc-view sc-label-view" style="top: 10px;">
    Choose a security label to add for the contacts being imported:
  </div>	
  <form [formGroup]="form" #ngForm="ngForm" >
  	<ng-select 
  	  class="custom"
  	  style="top: 30px;"
  	  [items]="securityLables" 
  	  bindLabel="name" 
  	  bindValue="id" 
  	  [formControl]="securityLabel"
  	  placeholder="Select a security label">
    </ng-select> 	
  </form>
</div>