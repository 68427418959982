import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActionComponent } from './action.component';
import { ActionResolver } from './action.resolver';
import { EloquaFieldResolver } from './eloqua-field.resolver';
import { SuperOfficeFieldResolver } from './super-office-field.resolver';
import { ActivityTypeResolver } from './activity-type.resolver';
import { CdoResolver } from './cdo.resolver';
import { AuthGuard } from '../core/auth.guard';


const routes: Routes = [{ 
    path: 'actions/:id/:campaignId/edit',
    component: ActionComponent,
    resolve: {
    	action: ActionResolver,
    	eloquaFields: EloquaFieldResolver,
    	superOfficeFields: SuperOfficeFieldResolver,
        activityTypes: ActivityTypeResolver,
        cdos: CdoResolver
    },
    canActivate: [AuthGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActionRoutingModule { }