import { Component, ElementRef, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

interface RoleEvent {
	id: number;
	event: MouseEvent;
}

@Component({
  selector: 'vmc-has-lithium-role',
  templateUrl: './has-lithium-role.component.html',
  styleUrls: ['./has-lithium-role.component.scss']
})
export class HasLithiumRoleComponent implements OnInit, OnDestroy {

  roles: ListItem<number, string>[] = []

  selectedRoleRef: ElementRef;
  selectedRoleIndex: number;
  selectedRoles: ListItem<number, string>[] = [];

  disableAdd: boolean = true;
  disableRemove: boolean = true;

  form : FormGroup;
  placeholder: string = "Loading..."

  data: any;

  private readonly criterionId: number;

  constructor(private criterionService: CriterionService, private dataService: DataService,
  	private formBuilder: FormBuilder, private dialogConfig: DialogConfig, private renderer: Renderer2) { 
  	this.criterionId = this.dialogConfig.data.id;
  }

  get role() { 
  	return this.form.get('role'); 
  }  

  ngOnInit() {
  	this.data = this.criterionService.getData(this.criterionId);

    this.dataService.getRoles();
  	this.dataService.roles$.subscribe(roles => {
  		// populate roles
  		this.roles = roles;

  		// select roles
  		if (this.data.roleIds) {
	  		this.selectedRoles = this.roles.filter(role => 
            this.data.roleIds.includes(role.id))
        /*this.selectedRoles.push(
	  			...this.roles.filter(role => 
	  				this.data.roleIds.includes(role.id)
	  			)
	  		)
        */
  		}
  		// change placeholder
  		this.placeholder = 'Select an item to add';
  	})
	
  	const value = {
  		invalid: this.data.roleIds == undefined || this.data.roleIds.length == 0 ? true: false,
  		description: this.buildDescription(),
  		roleIds: this.data.roleIds
  	}
  	this.criterionService.saveData(this.criterionId, value);
  	

  	this.form = this.formBuilder.group({
  		'role': this.formBuilder.control(undefined)
  	})

  	this.role.valueChanges.subscribe(roleId => {
  		if(roleId) {
  			this.disableAdd = false;
  		} else {
  			this.disableAdd = true;
  		}
  	})
  }

  ngOnDestroy() {
  	this.saveData();
  }

  onSelect(event: RoleEvent) {
  	const targetRef = new ElementRef(event.event.currentTarget);
  	this.renderer.addClass(targetRef.nativeElement, 'sel');

  	if (this.selectedRoleRef && this.selectedRoles[this.selectedRoleIndex].id != event.id) {
  		this.renderer.removeClass(this.selectedRoleRef.nativeElement, 'sel');
  	}
  	this.selectedRoleIndex = this.selectedRoles.findIndex(role => role.id == event.id);
  	this.selectedRoleRef = targetRef;
  	this.disableRemove = false;
  }

  onAdd(event: MouseEvent) {  	
  	const selectedRole = this.roles.find(role => role.id == this.role.value);
    
  	if(!this.selectedRoles.includes(selectedRole)) {
	  	this.selectedRoles.push(selectedRole);
	  	this.saveData();
	  }

	  this.role.reset();
  }

  onRemove(event: MouseEvent) {
  	this.selectedRoles.splice(this.selectedRoleIndex, 1);
  	this.selectedRoleRef = null;
  	this.disableRemove = true;

  	this.saveData();
  }

  saveData() {
  	const value = {
  		invalid: this.selectedRoles.length == 0 ? true: false,
  		description: this.buildDescription(),
  		roleIds: this.selectedRoles.map(role => role.id) || this.data.roleIds
  	}
  	this.criterionService.saveData(this.criterionId, value);
  }

  buildDescription() {
  	let description: string;
    
    if (this.selectedRoles.length == 0) { // closed earlier
      description = 'Configuration has validation errors. Double click to configure.';
  	} else if (this.selectedRoles.length == 1) {
  		description = `Contacts having Community role \'${this.selectedRoles[0].name}\'.`
  	} else if (this.selectedRoles.length > 1){
  		description = `Contacts having ${this.selectedRoles.length} chosen Community roles.`
  	} else {
  		description = `Double click to configure...`
  	}
  	return description;
  }
}
