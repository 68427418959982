import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Decision } from './decision.model';


@Injectable({
  providedIn: 'root'
})
export class DecisionService {

  constructor(private http: HttpClient) {  }

  // GET /api/decisions/:id
  getDecision(instanceId: string): Observable<any> { 
    const params = new HttpParams().set('instance', instanceId);
    return this.http.get('/api/decisions', { params });
  }

  // PUT /api/decisions/:id
  updateDecision(id: number, payload: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.put('/api/decisions/' + id, payload, httpOptions);
  }
}