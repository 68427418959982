import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DndModule } from 'ngx-drag-drop';
import { ContextMenuModule } from './context-menu/context-menu.module';
import { DialogModule } from './dialog/dialog.module';
import { ButtonModule } from './button/button.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { OnlyNumberDirective } from './only-number.directive';
import { SearchboxComponent } from './searchbox/searchbox.component';
import { FilterPipe } from './filter.pipe';
import { HeaderComponent } from './header/header.component';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import { ClarityModule } from '@clr/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [
    CheckboxComponent,
    OnlyNumberDirective,
    SearchboxComponent,
    FilterPipe,
    HeaderComponent,
    SaveDialogComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    ContextMenuModule,
    DialogModule,
    DndModule,
    NgSelectModule,
    NgProgressModule.withConfig({
      trickleSpeed: 200,
      min: 20,
      meteor: false,
      spinner: false
    }),
    NgProgressHttpModule,
    ClarityModule,
    NgxDatatableModule
  ],
  providers: [
  ],
  exports: [
    ButtonModule,
    DndModule,
    DialogModule,
  	ContextMenuModule,
    NgSelectModule,
    NgProgressModule,
    NgProgressHttpModule,    
    CheckboxComponent,
    OnlyNumberDirective,
    SearchboxComponent,
    FilterPipe,
    HeaderComponent,
    SaveDialogComponent,
    ClarityModule,
    NgxDatatableModule
  ],
  entryComponents: [
    SaveDialogComponent
  ]
})
export class SharedModule { }
