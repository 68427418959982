import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';

@Component({
  selector: 'app-ec-dk-industry-codes-company',
  templateUrl: './ec-dk-industry-codes-company.component.html',
  styleUrls: ['./ec-dk-industry-codes-company.component.scss']
})
export class EcDkIndustryCodesCompanyComponent implements OnInit {

  operators = [
	{id: "equal", name: "exactly"},
    {id: "contains", name: "contains"},
    {id: "startsWith", name: "starts with"},
    {id: "endsWith", name: "ends with"},
    {id: "between", name: "between"}
  ];

  negatedOperators = new Map<string, string>([
    ['equal', 'notEqual'],
    ['contains', 'doesNotContain'],
    ['startsWith', 'doesNotStartWith'],
    ['endsWith', 'doesNotEndWith'],
    ['between', 'notBetween']
  ])

	placeholder = "Select an operator";
	checkboxText: string = "not";

  form: FormGroup;
  private readonly criterionId: number;

  constructor(
    private formBuilder: FormBuilder, 
    private dialogConfig: DialogConfig, 
    private criterionService: CriterionService) {
  	this.criterionId = this.dialogConfig.data.id;
  }

  get operator() { 
  	return this.form.get('operator'); 
  }

  get negated() {
	return this.form.get('negated');
}

  get startCount() {
    return this.form.get('startCount');
  }

  get endCount() {
    return this.form.get('endCount');
  }

 ngOnInit() {
  	const data = this.criterionService.getData(this.criterionId);

  	this.form =  this.formBuilder.group({
		'operator': this.formBuilder.control(data.operator, [Validators.required]),
		'negated': this.formBuilder.control(data.negated),
        'startCount': this.formBuilder.control(data.startCount, [Validators.required]),
        'endCount': this.formBuilder.control(data.endCount, [Validators.required])
   	});

  	if (['equal', 'contains', 'startsWith', 'endsWith'].includes(data.operator)) {
  		this.endCount.disable({ emitEvent: false });
  	} else if (data.operator == 'between') {
  		this.endCount.enable({ emitEvent: false });
  	}

   	let formValue = this.form.value;
  	formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription(formValue);
    this.criterionService.saveData(this.criterionId, formValue);

    this.form.valueChanges.subscribe(value => {
    		
    		if (['equal', 'contains', 'startsWith', 'endsWith'].includes(value.operator)) {
    			this.endCount.disable({ emitEvent: false });
    		}

    		if (value.operator == 'between') {
    			this.endCount.enable({ emitEvent: false });
    		}
  		
    	value.invalid = this.form.invalid;
      value.startCount = value.startCount && value.startCount.toString().replace(/^0+(?=.)/, '');
      value.endCount = value.endCount && value.endCount.toString().replace(/^0+(?=.)/, '');
      value.description = this.buildDescription(value);
    	this.criterionService.saveData(this.criterionId, value);
    	});
  }

  onCheckBoxClick(event: any) {
	this.negated.setValue(event.checked);
}

  buildDescription(value: any) {
  	let activityRestriction: string;
  	let description: string;

  	if (this.form.invalid) {
  		description = 'Configuration has validation errors. Double click to configure.';
  	} else {
  	    if (this.operator.value == 'equal' && !this.negated.value) {
			activityRestriction = `is equal to "${this.startCount.value}"`;
		  } else if (this.operator.value == 'equal' && this.negated.value) {
			activityRestriction = `is not equal to "${this.startCount.value}"`;
		  } else if (['contains', 'startsWith', 'endsWith'].includes(this.operator.value) && !this.negated.value) {
			activityRestriction = `${this.operators.find(o => o.id == this.operator.value).name} "${this.startCount.value}"`;
		  } else if (this.operator.value == 'contains' && this.negated.value) {
			activityRestriction = `does not contain "${this.startCount.value}"`;
		  } else if (this.operator.value == 'startsWith' && this.negated.value) {
			activityRestriction = `does not start with "${this.startCount.value}"`;
          } else if (this.operator.value == 'endsWith' && this.negated.value) {
		    activityRestriction = `does not end with "${this.startCount.value}"`;
		  } else if (this.operator.value == 'between' && !this.negated.value) {
			activityRestriction = `between "${this.startCount.value}" and "${this.endCount.value}"`;
		  } else if (this.operator.value == 'between' && this.negated.value) {
			activityRestriction = `not between "${this.startCount.value}" and "${this.endCount.value}"`;
		  }
		  
  		if (activityRestriction) {
  			description = `Contacts with industry code ${activityRestriction}`;
  		} else {
  			description = "Double click to configure...";
  		}		
  	}

  	return description;
  }
}
