import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Feeder, Criterion, FeederCount, QueueInfo } from './feeder.model';


@Injectable({
  providedIn: 'root'
})
export class FeederService {

  constructor(private http: HttpClient) {  }

  // GET /api/feeders/:id
  getFeeder(instanceId: string): Observable<any> {
  /*   const ob = of({
      id: 32,
      status: "Draft",
      instanceId: "765feb21-8f90-419e-b82e-6ebc25760a7f",
      statement: "1 ",
      criteria: [
        {
          id: 1,
          initialId: -8,
          type: "HasLicenseCriterion",
          name: "Has License",
          description: "Contacts of customers who have license for 'Visma .NET Financials' version 'Financials 1, Financials 2'",
          product: {
            type: "NumericValueCondition",
            operator: "equal",
            value: 1
          },
          version: {
            type: "TextSetCondition",
            versions: [
              "Financials 1",
              "Financials 2"
            ]
          },
          partner: {
            type: 'NumericSetCondition',
            partners: [
              1,
              2
            ]
          },
          source: {
            type: "TextValueCondition",
            operator: "equal",
            value: "SuperOffice"
          }
        }
      ],
      userId: 9
    });
    return ob;  
    */
    const params = new HttpParams().set('instance', instanceId);
    return this.http.get('/api/feeders', { params });

  }

  // PUT /api/feeders/:id
  updateFeeder(id: number, payload: any): Observable<any> {
   /* console.log(JSON.stringify('payload'));
    console.log(JSON.stringify(payload));
    return of({
      id: 1,
      status: "Draft",
      instanceId: "asdfghjkl65a8dsf6ar1cv",
      statement: "3 AND 4",
      criteria: [
          {
              id: 3,
              initialId: -1,
              type: "HasLoggedInToLithiumCriterion",
              name: "Has Logged In To Lithium Community",
              description: "Contacts who have logged in to Lithium Community at least 1 time after 2019-01-01",
              activityRestriction: {
                  type: "NumericValueCondition",
                  operator: "greater",
                  value: 1
              },
              timeRestriction: {
                  type: "DateValueCondition",
                  operator: "after",
                  value: 1546300800
              }
          },
          {
            id: 4,
            initialId: -2,
            type: "HasLithiumRoleCriterion",
            name: "Has Lithium Community Role",
            description: "Contacts having 2 chosen Lithium roles",
            condition: {
              type: "NumericSetCondition",
              roleIds: [53,54]
            }
          }
      ]
    }).pipe(delay(2000));*/
    //return this.http.put('/api/feeders/' + id, payload, httpOptions);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.put('/api/feeders/' + id, payload, httpOptions);
  }
  /*
  // POST /api/feeders/:fid/criteria
  addCriterion(fid: string): Observable<Criterion> {
    return this.http.post(`/api/feeders/${fid}/criteria`, { }, { }).pipe(
      map((item: any) => this.criterionAdapter.adapt(item))
    );
  }

  // GET /api/feeders/:fid/criteria/:cid
  getCriterion(fid: string, cid: number): Observable<Criterion> {
    return this.http.get(`/api/feeders/${fid}/criteria/${cid}`).pipe(
      map((item: any) => this.criterionAdapter.adapt(item))
    );
  }

  // PUT /api/feeders/:fid/criteria/:cid
  updateCriterion(fid: string, cid:  number): Observable<Criterion> {
    return this.http.put(`/api/feeders/${fid}/criteria/${cid}`, { }, { }).pipe(
      map((item: any) => this.criterionAdapter.adapt(item))
    );
  }

  // DELETE /api/feeders/:fid/criteria/:cid
  deleteCriterion(fid: string, sid: number, cid:  number): void {
    this.http.delete(`/api/feeders/${fid}/criteria/${cid}`);
  }
*/
  queue(fid: number): Observable<QueueInfo> {
    return this.http.post(`/api/feeders/queue/${fid}`, {}).pipe(
        map((item: any) => new QueueInfo(item.feederId, item.queuedAt))
    );
  }

  getCount(fid: number): Observable<FeederCount> {
    return this.http.get(`/api/feeders/${fid}/count`).pipe(
      map((item: any) => new FeederCount(item.id, item.count, item.startAt, item.endAt))
    );
  }

}
