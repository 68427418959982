import { 
	Component, 
	ElementRef, 
	EventEmitter, 
	HostListener, 
	OnInit, 
	Output, 
	Renderer2, 
	Type, 
	ViewChild 
} from '@angular/core';

import { DndDropEvent } from 'ngx-drag-drop';
import { Criterion } from '../filter.model';
import { ContextMenuComponent } from '../../../shared/context-menu';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { CriterionService } from './criterion.service';
import { FilterService } from '../filter.service';
import { CanvasService } from '../../canvas.service';

export interface CriterionEvent {
	index: number,
	event: MouseEvent | DragEvent
    data?: any
}

@Component({
  selector: 'vmc-criterion',
  templateUrl: './criterion.component.html',
  styleUrls: ['./criterion.component.scss']
})
export class CriterionComponent implements OnInit {
  
  index: number;

  identationLevel: number = 0;

  identationPx = 16;

  initialId: number;

  name: string;
  description: string;
  infoLink: string = '';
  dialogComponentType: Type<any>;

  visible: boolean = false;
  selected: boolean = false;
  disabled: boolean = false;

  statement: number;

  iconUrl: string;

  @ViewChild('target')
  target: ElementRef;

  @ViewChild('summary')
  summary: ElementRef;  

  @Output()
  _select: EventEmitter<CriterionEvent> = new EventEmitter<CriterionEvent>();

  @Output()
  _drop: EventEmitter<CriterionEvent> = new EventEmitter<CriterionEvent>();

  @Output()
  _context: EventEmitter<CriterionEvent> = new EventEmitter<CriterionEvent>();

  constructor(private config: Criterion, 
  		private dialogService: DialogService, 
  		private renderer: Renderer2,
    	private criterionService: CriterionService,
    	private canvasService: CanvasService,
    	private filterService: FilterService,
    	public contextMenu: ContextMenuComponent) { 
  	//super(new FilterElementConfig(config.data['index'], config.id, config.identationLevel));
  }

  ngOnInit() {
    this.index = this.config.data['index'];
    this.statement = this.config.id;
    this.identationLevel = this.config.identationLevel;    
    this.initialId = this.config.initialId;//this.config.id;
    this.iconUrl = this.config.imageUrl;
    this.infoLink = this.config.infoLink;
    
    const data = this.criterionService.getData(this.initialId);

    if (!data) {
    	this.description = this.config.description;
      this.config.data['value'].description = this.config.description;
      this.criterionService.saveData(this.initialId, this.config.data['value']);
      /*
  		this.criterionService.saveData(this.initialId, {
  			id: this.config.data['value'], 
  			name: '', 
  			description: this.description
  		});
      */
    }

  	this.name = this.config.name;
  	this.dialogComponentType = this.config.dialogComponentType;

  	this.filterService.deselect()
  		.subscribe(event => this.deselect(event.event));

  	const element: HTMLElement = this.target.nativeElement;
    const summary: HTMLElement = element.querySelector('.summary');
  	this.renderer.setStyle(summary, 'left', (this.identationLevel*this.identationPx).toString() + 'px');	
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    console.log('mousedown on criterion')
  	if (!this.disabled) {
      console.log('emit criterion select event ' + this.index)
  		this._select.emit({ index: this.index, event: event });
  	}
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: MouseEvent) {
  	if (!this.disabled) {
  		this._select.emit({ index: this.index, event: event });
  		this._context.emit({ index: this.index, event: event });
  	}
  }

  @HostListener('dblclick', ['$event'])
  onDoubleClick(event: MouseEvent) {    
  	if (!this.disabled && this.dialogComponentType) {
  		const targetRef = new ElementRef(event.currentTarget);
      console.log('double click id = ' + this.initialId)
  		const dialogRef = this.dialogService.open(this.dialogComponentType, { data: { id: this.initialId }}, targetRef);

    	dialogRef.afterClosed.subscribe(() => {
    		const data = this.criterionService.getData(this.initialId);
        console.log('DESCRIPTION = ' + data['description'])
        this.description = data['description'];
        if (data['invalid']) {
          this.renderer.addClass(this.target.nativeElement, 'err');
        } else {
          this.renderer.removeClass(this.target.nativeElement, 'err');
        }

    		this.config.description = this.description;
  			this.config.data['value'] = data;
    		this.updateCriterion();
    	});
    }
  }

  onDragOver(event: DragEvent) {
    if(!this.visible && !this.disabled) {
      this.visible = true;
    }
  }

  onDragLeave(event: DragEvent) {
    if (this.visible && !this.disabled) {
      this.visible = false;
    }
  }

  onDrop(event: DndDropEvent) {
  	if (!this.disabled) {
    	this._drop.emit({ index: this.index, event: event.event, data: event.data });
    	this.visible = false;
    	event.event.stopPropagation();
	  }
  }

  enable() {
  	// #E8F2FB
  	// #f4f6f7;
  	this.disabled = false;
  }

  disable() {
  	this.disabled = true;
  }

  select() {
    console.log('selecting... adding outline')
  	this.renderer.addClass(this.target.nativeElement, 'outline');
  	this.selected = true;
  }

  deselect(event: MouseEvent) {
  	const element = this.target.nativeElement;
    if (this.selected && !element.contains(event.target) && !event.shiftKey) {
    	this.renderer.removeClass(element, 'outline');
      this.selected = false;
    }
  }

  private updateCriterion() {
    this.canvasService.updateCriterion(this.initialId, this.config);
  	//console.log(this.feederService.getFeeder())
    /*
    this.feederService.updateCriterion(this.id).subscribe(criterion => {
      const data = this.criterionService.getData(this.id)
      this.description = data['description'];
    }); 
    */
  }

  addIdentation() {
  	this.identationLevel++;
  	const element: HTMLElement = this.target.nativeElement;
    const summary: HTMLElement = element.querySelector('.summary');
  	this.renderer.setStyle(summary, 'left', (this.identationLevel*this.identationPx).toString() + 'px');    	
  }

  removeIdentation() {
  	this.identationLevel--;
  	const element: HTMLElement = this.target.nativeElement;
    const summary: HTMLElement = element.querySelector('.summary');
  	this.renderer.setStyle(summary, 'left', (this.identationLevel*this.identationPx).toString() + 'px');    	
  }
}
