  import { Component, OnInit } from '@angular/core';
  import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
  import { DialogConfig } from '../../../../shared/dialog/dialog-config';
  import { CriterionService } from '../criterion.service';
  import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-contact-information-permissions',
  templateUrl: './ec-dk-contact-information-permissions.component.html',
  styleUrls: ['./ec-dk-contact-information-permissions.component.scss']
})
export class EcDkContactInformationPermissionsComponent implements OnInit {
  
    permissions: ListItem<number, string>[] = [new ListItem(1, 'E-conomic nyt permissions'), new ListItem(2, 'Bogholder og revisornyt permissions')];
  
    form: FormGroup;
  
    placeholder: string = "Select contact permission!";
  
    data: any;
  
    private readonly criterionId: number;
  
    constructor(
      private criterionService: CriterionService,
      private dataService: DataService,
      private formBuilder: FormBuilder,
      private dialogConfig: DialogConfig) {
  
      this.criterionId = this.dialogConfig.data.id;
    }
  
    get permission() {
      return this.form.get('permission');
    }
  
    ngOnInit() {
      this.data = this.criterionService.getData(this.criterionId);
  
      this.form =  this.formBuilder.group({
        'permission': this.formBuilder.control(this.data.permission, [Validators.required]),
      });
  
      const value = this.form.value;
      value.invalid = this.form.invalid;
      value.description = this.buildDescription();
      this.criterionService.saveData(this.criterionId, this.form.value);
  
      this.form.valueChanges.subscribe(value => {
        value.description = this.buildDescription();
        value.invalid = this.form.invalid;
        this.criterionService.saveData(this.criterionId, value);

      });
    }
  
    buildDescription() {
      let description: string;
      if (this.form.invalid) { // is invalid
        description = 'Configuration has validation errors. Double click to configure.';
      } else if (this.permissions.length == 0) { // is valid (means configured)
        description = this.data.description;
      } else if (this.permission.value) { // is valid 
        const name = this.permissions.find(permission => permission.id == this.permission.value).name;
        description = `Contacts with ... \'${name}\'.`
      } else {
        description = `Double click to configure...`;
      }
  
      return description;
    }
  }
  