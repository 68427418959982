<div class="dialog">
  <div class="header">
  	<div>Users who were registered to SE Ranking...</div>
  </div>
  <div class="content">

    <form [formGroup]="form" #ngForm="ngForm">
      <ng-select
      	class="custom"
      	style="position: absolute; width: 170px;"
      	[items]="periodTypes"
      	bindLabel="name"
      	bindValue="id"
      	placeholder="Select an operator"
      	[formControl]="periodType">
      </ng-select>

      <label *ngIf="periodType.value != 'withinLast'"
        style="position: absolute; width: 120px; height: 24px; left: 180px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="date" maxlength="5096" [formControl]="startDate"
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <!--<input *ngIf="periodType.value != 'withinLast'" [formControl]="startDate" style="position: absolute; width: 100px; height: 24px; left: 180px;">-->
      <!-- visible only if 'within'  -->
      <div *ngIf="periodType.value == 'within'" style="position: absolute; width: 40px; height: 24px; top: 5px; left: 300px; text-align: center;">and</div>

      <label *ngIf="periodType.value == 'within'"
        style="position: absolute; width: 120px; height: 24px; left: 340px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="date" maxlength="5096" [formControl]="endDate"
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

     <!--<input *ngIf="periodType.value == 'within'" [formControl]="endDate" style="position: absolute; width: 100px; height: 24px; left: 320px;">-->

      <!-- visible only if 'withinLast' -->
     <label *ngIf="periodType.value == 'withinLast'"
        style="position: absolute; width: 40px; height: 24px; left: 180px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="text" maxlength="5096"
      		  vmcOnlyNumber [formControl]="offset"
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <!--<input *ngIf="periodType.value == 'withinLast'" appOnlyNumber [formControl]="relativePeriodNo" style="position: absolute; width: 40px; height: 24px; left: 180px;">-->
      <ng-select *ngIf="periodType.value == 'withinLast'"
      	class="custom"
      	style="position: absolute; width: 100px; height: 24px; left: 230px;"
      	[items]="relativePeriodTypes"
      	bindLabel="name"
      	bindValue="id"
      	placeholder="{{ placeholder }}"
      	[formControl]="relativePeriodType">
      </ng-select>

    </form>

  </div>
</div>
