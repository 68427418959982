import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { environment } from '../../environments/environment';

import {
    IActivity,
    IActivityType,
    IConfiguration,
    IEloquaField,
    IFieldMapping,
    ISaveConfiguration,
    ISuperOfficeField,
    ICdo
} from './action.interface';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) { }

    getActivityTypes(): Observable<IActivityType> {
        return this.http.get<IActivityType>('/api/actions/activityTypes').pipe(
            catchError(this.handleError)
        );
    }
    createActivity(activity: IActivity): Observable<IActivity> {
        return this.http.post<IActivity>('/api/actions/activities', activity).pipe(
            catchError(this.handleError)
        );
    }
    getCdos(): Observable<ICdo> {
        return this.http.get<ICdo>('/api/actions/cdos')
            .pipe(catchError(this.handleError));
    }
    getConfiguration(instanceId: string, campaignId: string): Observable<IConfiguration> {
        return this.http.get<IConfiguration>(`/api/actions/configurations/instance/${instanceId}/${campaignId}`)
            .pipe(catchError(this.handleError));
    }
    createConfiguration(configuration: IConfiguration): Observable<IConfiguration> {
        return this.http.post<IConfiguration>('/api/actions/configurations', configuration, this.httpOptions)
            .pipe(catchError(this.handleError));
    }
    updateConfiguration(configuration: IConfiguration): Observable<IConfiguration> {
        return this.http.put<IConfiguration>(`/api/actions/configurations/${configuration.id}`, configuration, this.httpOptions)
            .pipe(catchError(this.handleError));
    }
    getEloquaFields(): Observable<IEloquaField> {
        return this.http.get<IEloquaField>('/api/actions/eloquaFields')
            .pipe(catchError(this.handleError));
    }
    createFieldMapping(fieldMapping: IFieldMapping): Observable<IFieldMapping> {
        return this.http.post<IFieldMapping>('/api/actions/fieldMappings', fieldMapping)
            .pipe(catchError(this.handleError));
    }
    deleteFieldMappingByConfigurationId(id: number): Observable<IFieldMapping> {
        return this.http.delete<IFieldMapping>(`/api/actions/fieldMappings/configuration/${id}`)
            .pipe(catchError(this.handleError));
    }
    saveConfiguration(configurationToSave: ISaveConfiguration): Observable<ISaveConfiguration> {
        return this.http.post<ISaveConfiguration>(`/api/actions/save/${configurationToSave.instanceId}`, configurationToSave, this.httpOptions)
            .pipe(catchError(this.handleError));
    }

    getSuperOfficeFields(): Observable<ISuperOfficeField> {
        return this.http.get<ISuperOfficeField>('/api/actions/superOfficeFields')
            .pipe(catchError(this.handleError));
    }

    // getActivityTypes(): Observable<IActivityType> {
    //     return this.http.get<IActivityType>(`${environment.baseUrl}/api/actions/activityTypes`)
    //         .pipe(catchError(this.handleError));
    // }

    // createActivity(activity: IActivity): Observable<IActivity> {
    //     return this.http.post<IActivity>(`${environment.baseUrl}/api/actions/activities`, activity)
    //         .pipe(catchError(this.handleError));
    // }

    // getCdos(): Observable<ICdo> {
    //     return this.http.get<ICdo>(`${environment.baseUrl}/api/actions/cdos`)
    //         .pipe(catchError(this.handleError));
    // }

    // getConfiguration(instanceId: string, campaignId: string): Observable<IConfiguration> {
    //     return this.http.get<IConfiguration>(`${environment.baseUrl}/api/actions/configurations/instance/${instanceId}/${campaignId}`)
    //         .pipe(catchError(this.handleError));
    // }

    // createConfiguration(configuration: IConfiguration): Observable<IConfiguration> {
    //     return this.http.post<IConfiguration>(`${environment.baseUrl}/api/actions/configurations`, configuration, this.httpOptions)
    //         .pipe(catchError(this.handleError));
    // }

    // updateConfiguration(configuration: IConfiguration): Observable<IConfiguration> {
    //     return this.http.put<IConfiguration>(`${environment.baseUrl}/api/actions/configurations/${configuration.id}`, configuration, this.httpOptions)
    //         .pipe(catchError(this.handleError));
    // }

    // getEloquaFields(): Observable<IEloquaField> {
    //     return this.http.get<IEloquaField>(`${environment.baseUrl}/api/actions/eloquaFields`)
    //         .pipe(catchError(this.handleError));
    // }

    // createFieldMapping(fieldMapping: IFieldMapping): Observable<IFieldMapping> {
    //     return this.http.post<IFieldMapping>(`${environment.baseUrl}/api/actions/fieldMappings`, fieldMapping)
    //         .pipe(catchError(this.handleError));
    // }

    // deleteFieldMappingByConfigurationId(id: number): Observable<IFieldMapping> {
    //     return this.http.delete<IFieldMapping>(`${environment.baseUrl}/api/actions/fieldMappings/configuration/${id}`)
    //         .pipe(catchError(this.handleError));
    // }

    // saveConfiguration(configurationToSave: ISaveConfiguration): Observable<ISaveConfiguration> {
    //     return this.http.post<ISaveConfiguration>(`${environment.baseUrl}/api/actions/save/${configurationToSave.instanceId}`, configurationToSave, this.httpOptions)
    //         .pipe(catchError(this.handleError));
    // }

    // getSuperOfficeFields(): Observable<ISuperOfficeField> {
    //     return this.http.get<ISuperOfficeField>(`${environment.baseUrl}/api/actions/superOfficeFields`)
    //         .pipe(catchError(this.handleError));
    // }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
