import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-previous-addons',
  templateUrl: './ec-dk-previous-addons.component.html',
  styleUrls: ['./ec-dk-previous-addons.component.scss']
})
export class EcDkPreviousAddonsComponent implements OnInit {

  addons: ListItem<number, string>[] = [];

  periodTypes = [
  	{ id: 'on', name: 'on' },
  	{ id: 'before', name: 'before' },
  	{ id: 'after', name: 'after' },
  	{ id: 'within', name: 'within' },
  	{ id: 'withinLast', name: 'within the last' }
  ];

  relativePeriodTypes = [
    { id: 'days', name: 'Days' },
    { id: 'weeks', name: 'Weeks' },
    { id: 'months', name: 'Months' }
  ];

  form: FormGroup;

  addonPlaceholder: string = "Loading...";
  periodTypePlaceholder: string = "Select Addon End Date";

  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  get addon() {
  	return this.form.get('addon');
  }

  get periodType() { 
  	return this.form.get('periodType'); 
  }

  get relativePeriodType() {
  	return this.form.get('relativePeriodType');
  }

  get startDate() {
    return this.form.get('startDate');
  }

  get endDate() {
    return this.form.get('endDate');
  }  

  get offset() {
  	return this.form.get('offset');
  }

  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

    this.dataService.getAddons().subscribe(addons => {
    	this.addons = addons;
    	this.addonPlaceholder = 'Select the addon...';
    });

  	this.form =  this.formBuilder.group({
      'addon': this.formBuilder.control(this.data.addon, [Validators.required]),
  		'periodType': this.formBuilder.control(this.data.periodType, [Validators.required]),
  		'relativePeriodType': this.formBuilder.control(this.data.relativePeriodType, [Validators.required]),  		
      'startDate': this.formBuilder.control(this.data.startDate, [Validators.required]),
      'endDate': this.formBuilder.control(this.data.endDate, [Validators.required]),
      'offset': this.formBuilder.control(this.data.offset, [Validators.required])
  	});

    if (['on', 'before', 'after'].includes(this.data.periodType)) {
      this.startDate.enable({ emitEvent: false });
      this.relativePeriodType.disable({ emitEvent: false });
      this.offset.disable({ emitEvent: false });
      this.endDate.disable({ emitEvent: false });    
    } else if (this.data.periodType == 'within') {
      this.startDate.enable({ emitEvent: false });  
      this.endDate.enable({ emitEvent: false });
      this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });  
    } else if (this.data.periodType == 'withinLast') {
      this.offset.enable({ emitEvent: false });
      this.relativePeriodType.enable({ emitEvent: false });
      this.startDate.disable({ emitEvent: false });
      this.endDate.disable({ emitEvent: false });    
    } else {
      this.startDate.enable({ emitEvent: false });
      this.relativePeriodType.disable({ emitEvent: false });
      this.offset.disable({ emitEvent: false });
      this.endDate.disable({ emitEvent: false });      
    }     
    
    let formValue = this.form.value;
    formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, formValue);

  	this.form.valueChanges.subscribe(value => {
      if (['on', 'before', 'after'].includes(value.periodType)) {
        this.startDate.enable({ emitEvent: false });
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });
        this.endDate.disable({ emitEvent: false });
      }

      if (value.periodType == 'within') {
        this.startDate.enable({ emitEvent: false });	
        this.endDate.enable({ emitEvent: false });
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });		
      }

      if (value.periodType == 'withinLast') {
        this.offset.enable({ emitEvent: false });
        this.relativePeriodType.enable({ emitEvent: false });
        this.startDate.disable({ emitEvent: false });
        this.endDate.disable({ emitEvent: false });
      }

      value.invalid = this.form.invalid;
      value.offset = value.offset && value.offset.toString().replace(/^0+(?=.)/, '');
      value.description = this.buildDescription();
  		this.criterionService.saveData(this.criterionId, value);
  	});
  }

buildDescription() {
  let timeRestriction: string;
  let description: string;

  if (this.form.invalid) {
    description = 'Configuration has validation errors. Double click to configure.';
  } else if (this.addons.length == 0) { 
    description = this.data.description;        
  } else if (this.addon.value) {
    const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == this.periodType.value);
    
    if (periodTypeIndex > -1 && this.periodType.value == 'within' && this.startDate.value && this.endDate.value) {
      timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${this.startDate.value} and ${this.endDate.value}`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'within' && this.startDate.value && this.endDate.value) {
      timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${this.startDate.value} and ${this.endDate.value}`;
    } else if (periodTypeIndex > -1 && this.periodType.value == 'withinLast' && this.offset.value && this.relativePeriodType.value) {
      timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${this.offset.value} ${this.relativePeriodType.value}`;
    } else if (periodTypeIndex > -1 && ['on', 'before', 'after'].includes(this.periodType.value) && this.startDate.value) {
      timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${this.startDate.value}`;
    }

    if (timeRestriction && this.addon.value) {
      const name = this.addons.find(addon => addon.id == this.addon.value).name;
      description = `Contacts having the addon \'${name}\' ${timeRestriction}.`
    } else {
      description = "Double click to configure...";
    }	
  } else {
    description = "Double click to configure...";
  }
  return description;
}
}



