import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IActivityType } from './action.interface';
import { ActionService } from './action.service';

@Injectable({
    providedIn: 'root'
})
export class ActivityTypeResolver implements Resolve<IActivityType> {
    constructor(private actionService: ActionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<IActivityType> | Promise<IActivityType> | IActivityType {
        return this.actionService.getActivityTypes();
    }
}
