import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { ListItem } from '../../../data.service';

@Component({
  selector: 'app-has-lithium-country',
  templateUrl: './has-lithium-country.component.html',
  styleUrls: ['./has-lithium-country.component.scss']
})
export class HasLithiumCountryComponent implements OnInit {

  countries: ListItem<string, string>[] = [
    {'id': 'dk', 'name': 'Denmark'},
    {'id': 'fi', 'name': 'Finland'},
    {'id': 'lv', 'name': 'Latvia'},
    {'id': 'no', 'name': 'Norway'},
    {'id': 'sw', 'name': 'Sweden'},
    {'id': 'nl', 'name': 'The Netherlands'},
    {'id': 'in', 'name': 'Visma International'},
    {'id': 'ar', 'name': 'Visma Latin America'},
    {'id': 'dv', 'name': 'Visma Developers & Partners'}
  ];

  form: FormGroup;
  placeholder: string = "Select a country";

  data: any;
  private readonly criterionId: number;

  get textValue() {
    return this.form.get('textValue');
  }

  constructor(
    private criterionService: CriterionService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

  	this.form =  this.formBuilder.group({
  		'textValue': this.formBuilder.control(this.data.textValue, [Validators.required])
    });

    const value = this.form.value;
    value.invalid = this.form.invalid;
    value.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, this.form.value);

  	this.form.valueChanges.subscribe(value => {
  		value.description = this.buildDescription();
  		value.invalid = this.form.invalid;
  		this.criterionService.saveData(this.criterionId, value);
  	});
  }

  buildDescription() {
  	let description: string;
	  if (this.form.invalid) { // is invalid
		  description = 'Configuration has validation errors. Double click to configure.';
	  } else if (this.textValue.value) { // is valid and ranks have already been loaded
  		const name = this.countries.find(country => country.id == this.textValue.value).name;
  		description = `Contacts having Community country that is equal to \'${name}\'.`
  	} else {
  		description = `Double click to configure...`;
  	}
  	return description;
  }
}
