import { 
	Component,
	ComponentRef,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild
} from '@angular/core';

import { Operator, OperatorConfig } from '../filter.model';

@Component({
  selector: 'vmc-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss']
})
export class OperatorComponent implements OnInit {
  
  index: number;

  identationLevel: number = 0;

  identationPx = 16;
  // default operator value
  value: string;

  statement: string;
  
  private num: number;

  private modulus: number;

  private operators: Operator[];

  public left: number;

  @Output()
  _click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild('target')
  target: ElementRef 

  constructor(private renderer: Renderer2, private config: OperatorConfig) { 
  	//super(new FilterElementConfig(config.index, config.value, config.identationLevel));
  }

  ngOnInit() {
    console.log('operator = ' + JSON.stringify(this.config))
    this.left = 0; //this.config.data['left'];
    this.identationLevel = this.config.identationLevel;
    this.operators = this.config.operators;
  	this.modulus = this.operators.length;
    this.statement = this.config.value;
    console.log('OPERATOR STATEMENT = ' + this.statement);
  	this.num = this.operators.findIndex(operator => operator.name == this.statement);
    this.num = this.num == -1 ? 0 : this.num;
    this.statement = this.operators[this.num].name;

  	const element: HTMLElement = this.target.nativeElement;
    const outline: HTMLElement = element.querySelector('.outline-wrapper');
  	this.renderer.setStyle(outline, 'left', (this.identationLevel*this.identationPx).toString() + 'px'); 
  }

  onClick(event: MouseEvent) {
	  const idx = ++this.num % this.modulus;
	  this.statement = this.operators[idx].name;
    console.log('idx = ' + idx + 'operator value = ' + this.statement);
	  this._click.emit(event);
	  event.stopPropagation();
  }

  addIdentation() {
  	this.identationLevel++;
  	const element: HTMLElement = this.target.nativeElement;
    const outline: HTMLElement = element.querySelector('.outline-wrapper');
  	this.renderer.setStyle(outline, 'left', (this.identationLevel*this.identationPx).toString() + 'px'); 
  }

  removeIdentation() {
  	this.identationLevel--;
  	const element: HTMLElement = this.target.nativeElement;
    const outline: HTMLElement = element.querySelector('.outline-wrapper');
  	this.renderer.setStyle(outline, 'left', (this.identationLevel*this.identationPx).toString() + 'px'); 
  }  
}
