import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-agreementtype',
  templateUrl: './ec-dk-agreementtype.component.html',
  styleUrls: ['./ec-dk-agreementtype.component.scss']
})
export class EcDkAgreementtypeComponent implements OnInit {

  agrtypes: ListItem<number, string>[] = [new ListItem(10, 'Company'), new ListItem(11, 'AO'), 
  new ListItem(12, 'Group Of Companies')];

  form: FormGroup;

  placeholder: string = "Select agreement type!";
  checkboxText: string = "not";

  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  get negated() {
    return this.form.get('negated');
  }

  get agrtype() {
  	return this.form.get('agrtype');
  }


  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

  	this.form =  this.formBuilder.group({
  		'agrtype': this.formBuilder.control(this.data.agrtype, [Validators.required]),
  		'negated': this.formBuilder.control(this.data.negated)
  	});

    const value = this.form.value;
    value.invalid = this.form.invalid;
    value.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, this.form.value);

  	this.form.valueChanges.subscribe(value => {
  		value.description = this.buildDescription();
          value.invalid = this.form.invalid;
          console.log('==================================== = ' );
          console.log('AGREEMENT_TYPE = ' + value['agrtype']);
  		this.criterionService.saveData(this.criterionId, value);

  	});
  }

  onCheckBoxClick(event: any) {
    this.negated.setValue(event.checked);
  }

  buildDescription() {
  	let description: string;
	  if (this.form.invalid) { // is invalid
		  description = 'Configuration has validation errors. Double click to configure.';
    } else if (this.agrtypes.length == 0) { // is valid (means configured), but agrtypes have not been loaded yet
      description = this.data.description;
	  } else if (this.agrtype.value && !this.negated.value) { // is valid and agrtypes have already been loaded
  		const name = this.agrtypes.find(agrtype => agrtype.id == this.agrtype.value).name;
      description = `New contacts with the agreement type ... \'${name}\'.`
    } else if (this.agrtype.value && this.negated.value) { // is valid and agrtypes have already been loaded
  		const name = this.agrtypes.find(agrtype => agrtype.id == this.agrtype.value).name;
  		description = `New contacts with the agreement type not  ... \'${name}\'.`
  	} else {
  		description = `Double click to configure...`;
  	}

  	return description;
  }
}