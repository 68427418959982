import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'vmc-has-lithium-rank',
  templateUrl: './has-lithium-rank.component.html',
  styleUrls: ['./has-lithium-rank.component.scss']
})
export class HasLithiumRankComponent implements OnInit {

  ranks: ListItem<number, string>[] = [];
  
  form: FormGroup;

  placeholder: string = "Loading...";
  checkboxText: string = "not";

  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder, 
  	private dialogConfig: DialogConfig) { 

  	this.criterionId = this.dialogConfig.data.id;
  }

  get rank() { 
  	return this.form.get('rank'); 
  }

  get negated() {
  	return this.form.get('negated');
  }

  ngOnInit() { 
    this.data = this.criterionService.getData(this.criterionId);

    this.dataService.getRanks().subscribe(ranks => {
    	this.ranks = ranks;
    	this.placeholder = 'Select a rank...';
    });

    
  	this.form =  this.formBuilder.group({
  		'rank': this.formBuilder.control(this.data.rank, [Validators.required]),
  		'negated': this.formBuilder.control(this.data.negated)
  	});

    const value = this.form.value;
    value.invalid = this.form.invalid;
    value.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, this.form.value);

  	this.form.valueChanges.subscribe(value => {
  		value.description = this.buildDescription();
  		value.invalid = this.form.invalid;
  		this.criterionService.saveData(this.criterionId, value);
  		
  		//this.criterionService.saveData(this.criterionId, { description: this.buildDescription(), rankId: value });
  		//const name = this.ranks.find(rank => rank.id == value).name;
  		//this.submit({ id: value, name: name });
  	});
  }

  onCheckBoxClick(event: any) {
  	this.negated.setValue(event.checked);
  }

  buildDescription() {
  	let description: string;
	  if (this.form.invalid) { // is invalid
		  description = 'Configuration has validation errors. Double click to configure.';
    } else if (this.ranks.length == 0) { // is valid (means configured), but ranks have not been loaded yet
      description = this.data.description;
	  } else if (this.rank.value && !this.negated.value) { // is valid and ranks have already been loaded
  		const name = this.ranks.find(rank => rank.id == this.rank.value).name;
  		description = `Contacts having Community rank that is equal to \'${name}\'.`
  	} else if (this.rank.value  && this.negated.value) { // is valid and ranks have already been loaded
  		const name = this.ranks.find(rank => rank.id == this.rank.value).name;
  		description = `Contacts having Community rank that is not equal to \'${name}\'.`
  	} else {
  		description = `Double click to configure...`;
  	}

  	return description;
  }  
}
