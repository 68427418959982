import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DecisionService } from './decision.service';
import { DialogService } from '../shared/dialog/dialog.service';
import { SaveDialogComponent } from '../shared/save-dialog/save-dialog.component';

@Component({
  selector: 'vmc-decision',
  templateUrl: './decision.component.html',
  styleUrls: ['./decision.component.scss']
})
export class DecisionComponent implements OnInit {

  config: any;

  filterConfig: any;

  panelConfig = {
    enable_lithium_rank_criterion: true,
    enable_lithium_role_criterion: true,
    enable_lithium_norole_criterion: true,
    enable_lithium_country_criterion: true,
    enable_lithium_login_criterion: true,
    enable_lithium_not_login_criterion: true,
    enable_gave_lithium_kudos_criterion: true,
    enable_received_lithium_kudos_criterion: true,
    enable_published_lithium_content_criterion: false,
    enable_responded_to_lithium_survey_criterion: false,
    enable_searched_lithium_criterion: false,
    enable_ec_dk_active_company_users_criterion: true,
    enable_ec_dk_exclude_active_company_users_criterion: true,
    enable_ec_dk_active_ao_users_criterion: true,
    enable_ec_dk_exclude_active_ao_users_criterion: true,
    enable_ec_dk_active_trial_users_criterion: true,
    enable_ec_dk_active_addons_criterion: true,
    enable_ec_dk_not_addons_criterion: true,
    enable_ec_dk_previous_addons_criterion: true,
    enable_ec_dk_exclude_addons_criterion: true,
    enable_ec_dk_industry_codes_company_criterion: true,
    enable_ec_dk_industry_codes_goc_criterion: true,
    enable_ec_dk_active_group_of_company_users_criterion: true,
    enable_ec_dk_exclude_active_group_of_company_users_criterion: true,
    enable_ec_dk_signup_date_criterion: true,
    enable_ec_dk_new_signup_date_criterion: true,
    enable_ec_dk_new_trial_users_criterion: true,
    enable_ec_dk_trial_users_criterion: true,
    enable_ec_dk_new_users_role_criterion: true,
    enable_ec_dk_users_role_criterion: true,
    enable_ec_dk_new_user_agreementtype_criterion: true,
    enable_ec_dk_agreementtype_criterion: true,
    enable_ec_dk_number_of_employees_criterion: true,
    enable_ec_dk_segment_company_criterion: true,
    enable_ec_dk_segment_goc_criterion: true,
    enable_ec_dk_contact_information_permissions_criterion: true,
    enable_ec_dk_subscription_criterion: true,
    enable_ec_dk_exclude_subscription_criterion: true,
    enable_ec_dk_not_subscription_criterion: true,
    enable_ec_dk_user_logins_criterion: true,
    enable_ec_dk_users_pr_agreement_criterion: true,
    enable_ec_dk_content_service_table_criterion: true,
    enable_ec_dk_aos_with_externals_criterion: true,
    enable_ec_dk_mobile_app_used_criterion: true,
    enable_ec_dk_trial_user_signup_source_criterion: true,
    enable_ec_dk_new_trial_user_signup_source_criterion: true,
    enable_ec_dk_unless_in_app_click_criterion:true,
	  enable_ec_dk_unless_in_app_display_criterion:true
  }

  saveButtonDisabled = false;

  invalid: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private decisionService: DecisionService, private dialogService: DialogService) { }

  ngOnInit() {
    this.config = this.activatedRoute.snapshot.data['decision'];
    this.filterConfig = {
      statement: this.config.statement,
      criteria: this.config.criteria
    };
  }

  onSave(event: MouseEvent) {
    if (this.invalid) {
      this.dialogService.open(SaveDialogComponent, {
        data: {
          header: 'Save Visma Marketing Cloud Decision',
          content: 'Can not save filter. Filter has incomplete criteria.'
        }
      });
    } else if (this.config.criteria.length == 0) {
      this.dialogService.open(SaveDialogComponent, {
        data: {
          header: 'Save Visma Marketing Cloud Decision',
          content: 'Can not save filter. Filter can not be empty.'
        }
      });
    } else {
      this.saveButtonDisabled = true;
      this.decisionService.updateDecision(this.config.id, this.config)
        .subscribe(config => {
          this.config = config;
          this.filterConfig = {
            statement: this.config.statement,
            criteria: this.config.criteria
          }
          this.saveButtonDisabled = false;
          console.log('config');
          console.log(JSON.stringify(this.config));
        }, (error) => this.saveButtonDisabled = false);
    }
  }

  transform(event: any) {
    const criteria = event.criteria.map(e => this.adapt(e));
    this.config.criteria = criteria;
    this.config.statement = event.statement;

    console.log(JSON.stringify(criteria));
    const invalidElement = event.criteria.find(criterion =>
      criterion.data.value['invalid'] === true);

    if (invalidElement)
      this.invalid = true;
    else
      this.invalid = false;
  }

  adapt(item: any) {
    const data = item.data.value;
    switch (item.type) {
      case "HasLoggedInToLithiumCriterion":
        return this.adaptCriterion(item);
      case "HasNotLoggedInToLithiumCriterion":
        return this.adaptCriterionWithTimeFilterOnly(item);
      case "HasNoLithiumRoleCriterion":
        return this.adaptEmptyCriterion(item);
      case "HasLithiumRoleCriterion":
        return this.adaptHasLithiumRoleCriterion(item);
      case "HasLithiumRankCriterion":
        return this.adaptHasLithiumRankCriterion(item);
      case "HasLithiumCountryCriterion":
        return this.adaptTextValueConditionCriterion(item);
      case "GaveLithiumKudosCriterion":
        return this.adaptCriterion(item);
      case "ReceivedLithiumKudosCriterion":
        return this.adaptCriterion(item);
      case "EcDkActiveCompanyUsersCriterion":
        return this.adaptEcDkActiveCompanyUsersCriterion(item);
      case "EcDkExcludeActiveCompanyUsersCriterion":
        return this.adaptEcDkExcludeActiveCompanyUsersCriterion(item);
      case "EcDkActiveAOUsersCriterion":
        return this.adaptEcDkActiveAOUsersCriterion(item);
      case "EcDkExcludeActiveAOUsersCriterion":
        return this.adaptEcDkExcludeActiveAOUsersCriterion(item);
      case "EcDkActiveTrialUsersCriterion":
        return this.adaptEcDkActiveTrialUsersCriterion(item);
      case "EcDkActiveAddonsCriterion":
        return this.adaptEcDkActiveAddonsCriterion(item);
      case "EcDkNotAddonsCriterion":
        return this.adaptEcDkNotAddonsCriterion(item);
      case "EcDkPreviousAddonsCriterion":
        return this.adaptEcDkPreviousAddonsCriterion(item);
      case "EcDkExcludeAddonsCriterion":
        return this.adaptEcDkExcludeAddonsCriterion(item);
      case "EcDkActiveGroupofCompanyUsersCriterion":
        return this.adaptEcDkActiveGroupofCompanyUsersCriterion(item)
      case "EcDkExcludeActiveGroupofCompanyUsersCriterion":
        return this.adaptEcDkExcludeActiveGroupofCompanyUsersCriterion(item)
      case "EcDkIndustryCodesCompanyCriterion":
        return this.adaptEcDkIndustryCodesCompanyCriterion(item);
      case "EcDkIndustryCodesGoCCriterion":
        return this.adaptEcDkIndustryCodesGoCCriterion(item);
      case "EcDkSignupDateCriterion":
        return this.adaptEcDkSignupDateCriterion(item);
      case "EcDkNewSignupDateCriterion":
        return this.adaptEcDkNewSignupDateCriterion(item);
      case "EcDkNewTrialUsersCriterion":
        return this.adaptEcDkNewTrialUsersCriterion(item);
      case "EcDkTrialUsersCriterion":
        return this.adaptEcDkTrialUsersCriterion(item);
      case "EcDkNewUsersRoleCriterion":
        return this.adaptEcDkNewUsersRoleCriterion(item);
      case "EcDkUsersRoleCriterion":
        return this.adaptEcDkUsersRoleCriterion(item);
      case "EcDkNewUserAgreementtypeCriterion":
        return this.adaptEcDkNewUserAgreementtypeCriterion(item);
      case "EcDkAgreementtypeCriterion":
        return this.adaptEcDkAgreementtypeCriterion(item);
      case "EcDkNumberOfEmployeesCriterion":
        return this.adaptEcDkNumberOfEmployeesCriterion(item);
      case "EcDkSegmentCompanyCriterion":
        return this.adaptEcDkSegmentCompanyCriterion(item);
        case "EcDkSegmentGoCCriterion":
        return this.adaptEcDkSegmentGoCCriterion(item);
      case "EcDkContactInformationPermissionsCriterion":
        return this.adaptEcDkContactInformationPermissionsCriterion(item);
      case "EcDkSubscriptionCriterion":
        return this.adaptEcDkSubscriptionCriterion(item);
      case "EcDkExcludeSubscriptionCriterion":
        return this.adaptEcDkExcludeSubscriptionCriterion(item);
      case "EcDkNotSubscriptionCriterion":
        return this.adaptEcDkNotSubscriptionCriterion(item);
      case "EcDkUserLoginsCriterion":
        return this.adaptEcDkUserLoginsCriterion(item);
      case "EcDkUsersPrAgreementCriterion":
          return this.adaptEcDkUsersPrAgreementCriterion(item);
      case "EcDkContentServiceTableCriterion":
          return this.adaptEcDkContentServiceTableCriterion(item);
      case "EcDkAOsWithExternalsCriterion":
          return this.adaptEcDkAOsWithExternalsCriterion(item);
      case "EcDkMobileAppUsedCriterion":
          return this.adaptEcDkMobileAppUsedCriterion(item);
      case "EcDkTrialUserSignupSourceCriterion":
          return this.adaptEcDkTrialUserSignupSourceCriterion(item);
      case "EcDkNewTrialUserSignupSourceCriterion":
          return this.adaptEcDkNewTrialUserSignupSourceCriterion(item);
      case "EcDkUnlessInAppClickCriterion":
          return this.adaptEcDkUnlessInAppClickCriterion(item);
      case "EcDkUnlessInAppDisplayCriterion":
          return this.adaptEcDkUnlessInAppDisplayCriterion(item);
      default:
        break;
    }
  }

  adaptEmptyCriterion(item: any) {
    let criterion = {}
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    return criterion;
  }
  
  adaptCriterion(item: any) {
    const data = item.data.value;

    let activityRestriction = { };
    if (data.operator == 'equal' || data.operator == 'greater') {
      activityRestriction['type'] = 'NumericValueCondition';
      activityRestriction['operator'] = data.operator;
      activityRestriction['value'] = data.startCount;
    } else if (data.operator == 'between') {
      activityRestriction['type'] = 'NumericRangeCondition';
      activityRestriction['operator'] = data.operator;
      activityRestriction['start'] = data.startCount;
      activityRestriction['end'] = data.endCount;
    } else {

    }

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['activityRestriction'] = activityRestriction;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptCriterionWithTimeFilterOnly(item: any) {
    const data = item.data.value;

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptHasLithiumRoleCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericSetCondition',
      roleIds: data.roleIds
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptTextValueConditionCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.textValue
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptHasLithiumRankCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: data.negated ? 'notEqual': 'equal',
      value: data.rank
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptCompareFieldCriterion(item: any) {
    const data = item.data.value;
    const opeators = ['equal', 'contains', 'startsWith', 'endsWith', 'blank'];
    const negatedOperators = new Map<string, string>([
      ['equal', 'notEqual'],
      ['contains', 'doesNotContain'],
      ['startsWith', 'doesNotStartWith'],
      ['endsWith', 'doesNotEndWith'],
      ['blank', 'notBlank']
    ]);

    let condition = {
      type: 'TextValueCondition',
      operator: data.negated ? negatedOperators.get(data.operator): data.operator,
      value: data.freeText
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['field'] = data.field;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptDateFieldCriterion(item: any) {
    const data = item.data.value;

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (['withinLast', 'withinNext', 'ago'].includes(data.periodType)) {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptHasLinkedCompanyWithSalesStageCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'TextValueCondition',
      operator: data.negated ? 'notEqual': 'equal',
      value: data.stage
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptSignedUpForSpecificEventsCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericSetCondition',
      eventIds: data.eventIds
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptAttendedSpecificEventsCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericSetCondition',
      eventIds: data.eventIds
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptCanceledSpecificEventsCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericSetCondition',
      eventIds: data.eventIds
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptUserAddedCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextSetCondition',
      //operator: 'equal',
      value: data.applicationType
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptUserRemovedCriterion(item: any) {
    return this.adaptUserAddedCriterion(item);
  }

  adaptTextSetCondition(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'TextSetCondition',
      value: data.textValues
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptHasLicenseCriterion(item: any) {
    const data = item.data.value;

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }

    const product = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.applicationType
    };

    const source = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.contactsSource
    }

    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['product'] = product;
    criterion['source'] = source;

    if (data.version) {
      const version = {
        type: 'TextSetCondition',
        versions: data.version
      };

      criterion['version'] = version;
    }

    if (data.partner) {
      const partner = {
        type: 'NumericSetCondition',
        partners: data.partner
      };
      criterion['partner'] = partner;
    }

    return criterion;
  }

  adaptHasNotLicenseCriterion(item: any) {
    return this.adaptHasLicenseCriterion(item);
  }

  adaptLicenseStartedCriterion(item: any) {
    const data = item.data.value;

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }

    const product = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.applicationType
    };

    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['product'] = product;

    if (data.version) {
      const version = {
        type: 'TextSetCondition',
        versions: data.version
      }
      criterion['version'] = version;
    }

    if (data.partner) {
      const partner = {
        type: 'NumericSetCondition',
        partners: data.partner
      }
      criterion['partner'] = partner;
    }

    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptLicenseRenewedCriterion(item: any) {
    return this.adaptLicenseStartedCriterion(item);
  }

  adaptLicenseCancelledCriterion(item: any) {
    return this.adaptLicenseStartedCriterion(item);
  }

  adaptLicenseExpiredCriterion(item: any) {
    return this.adaptLicenseStartedCriterion(item);
  }

  adaptLicenseStoppedChurnCriterion(item: any) {
    return this.adaptLicenseStartedCriterion(item);
  }

  adaptLicenseUnderTerminationCriterion(item: any) {
    const data = item.data.value;

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinNext') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }

    const product = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.applicationType
    };

    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['product'] = product;

    if (data.version) {
      const version = {
        type: 'TextSetCondition',
        versions: data.version
      }
      criterion['version'] = version;
    }

    if (data.partner) {
      const partner = {
        type: 'NumericSetCondition',
        partners: data.partner
      }
      criterion['partner'] = partner;
    }

    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptHasVbLicenseCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.r12
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['r12'] = condition;

    return criterion;
  }

  adaptHasCustomerValueCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.r12
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;

    if (data.r12) {
      const r12 = {
        type: 'TextSetCondition',
        r12s: data.r12
      };

      criterion['r12'] = r12;
    }

    const cmrr = { };
    if (data.operator == 'equal' || data.operator == 'greater' || data.operator == 'less') {
      cmrr['type'] = 'NumericValueCondition';
      cmrr['operator'] =  data.operator;
      cmrr['value'] = data.startCmrr;
    }

    if (data.operator == 'between') {
      cmrr['type'] = 'NumericRangeCondition';
      cmrr['operator'] = data.operator;
      cmrr['start'] = data.startCmrr;
      cmrr['end'] = data.endCmrr;
    }

    criterion['cmrr'] = cmrr;

    return criterion;
  }

  adaptCompletedCourseCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.course
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['course'] = condition;

    const completion = { };
    if (data.operator == 'equal' || data.operator == 'greater' || data.operator == 'less') {
      completion['type'] = 'NumericValueCondition';
      completion['operator'] =  data.operator;
      completion['value'] = data.startPercentage;
    }

    if (data.operator == 'between') {
      completion['type'] = 'NumericRangeCondition';
      completion['operator'] = data.operator;
      completion['start'] = data.startPercentage;
      completion['end'] = data.endPercentage;
    }

    criterion['completion'] = completion;

    return criterion;
  }

adaptEnrolledInCourseCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.course
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

adaptEnrolledInCourseCategoryCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.courseGroup
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkActiveCompanyUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.rank
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkExcludeActiveCompanyUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkActiveAOUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkExcludeActiveAOUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkActiveTrialUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkActiveAddonsCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'NumericValueCondition',
        operator: 'equal',
        value: data.addon
      };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }
  adaptEcDkNotAddonsCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.addon
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkPreviousAddonsCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'NumericValueCondition',
        operator: 'equal',
        value: data.addon
      };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkExcludeAddonsCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.addon
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }
  adaptEcDkActiveGroupofCompanyUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkExcludeActiveGroupofCompanyUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkIndustryCodesCompanyCriterion(item: any) {

    const data = item.data.value;
  //  const operators = ['equal', 'contains', 'startsWith', 'endsWith', 'between'];
    const negatedOperators = new Map<string, string>([
      ['equal', 'notEqual'],
      ['contains', 'doesNotContain'],
      ['startsWith', 'doesNotStartWith'],
      ['endsWith', 'doesNotEndWith'],
      ['between', 'notBetween']
    ]);

    let activityRestriction = {  
      operator: data.negated ? negatedOperators.get(data.operator): data.operator
    };

    if (!['between', 'notBetween'].includes(data.operator)) {
      activityRestriction['type'] = 'NumericValueCondition';
      activityRestriction['value'] = data.startCount;
    } else if (['between', 'notBetween'].includes(data.operator)) {
      activityRestriction['type'] = 'NumericRangeCondition';
      activityRestriction['start'] = data.startCount;
      activityRestriction['end'] = data.endCount;
    } 
 
    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['activityRestriction'] = activityRestriction;
    return criterion;
  }

  adaptEcDkIndustryCodesGoCCriterion(item: any) {

    const data = item.data.value;
  //  const operators = ['equal', 'contains', 'startsWith', 'endsWith', 'between'];
    const negatedOperators = new Map<string, string>([
      ['equal', 'notEqual'],
      ['contains', 'doesNotContain'],
      ['startsWith', 'doesNotStartWith'],
      ['endsWith', 'doesNotEndWith'],
      ['between', 'notBetween']
    ]);

    let activityRestriction = {  
      operator: data.negated ? negatedOperators.get(data.operator): data.operator
    };

    if (!['between', 'notBetween'].includes(data.operator)) {
      activityRestriction['type'] = 'NumericValueCondition';
      activityRestriction['value'] = data.startCount;
    } else if (['between', 'notBetween'].includes(data.operator)) {
      activityRestriction['type'] = 'NumericRangeCondition';
      activityRestriction['start'] = data.startCount;
      activityRestriction['end'] = data.endCount;
    } 
 
    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['activityRestriction'] = activityRestriction;
    return criterion;
  }

  adaptEcDkSignupDateCriterion(item: any) {
    const data = item.data.value;

    const negatedOperators = new Map<string, string>([
      ['on', 'notOn'],
      ['before', 'after'],
      ['after', 'before'],
      ['within', 'notWithin'],
      ['withinLast', 'notWithinLast'],
      ['blank', 'notBlank'],
      ['today', 'notToday'],
      ['yesterday', 'notYesterday']

    ]);

    let timeRestriction = { };
    if (['on', 'before', 'after', 'notOn', 'notBefore', 'notAfter'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (['within', 'notWithin'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (['withinLast', 'notWithinLast'].includes(data.periodType)) {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    } else if (['today', 'yesterday', 'blank', 'notToday', 'notYesterday', 'notBlank'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = '';
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkNewSignupDateCriterion(item: any) {
    const data = item.data.value;

    let timeRestriction = { };
    if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    } else if (['today', 'blank'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = '';
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkNewTrialUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkTrialUsersCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkNewUsersRoleCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkUsersRoleCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.role
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkNewUserAgreementtypeCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: data.negated ? 'notEqual': 'equal',
      value: data.agrtype
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkAgreementtypeCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: data.negated ? 'notEqual': 'equal',
      value: data.agrtype
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkNumberOfEmployeesCriterion(item: any) {

    const data = item.data.value;
    const negatedOperators = new Map<string, string>([
      ['between', 'notBetween'],
      ['blank', 'notBlank']
    ]);

    let activityRestriction = {  
      operator: data.negated ? negatedOperators.get(data.operator): data.operator
    };

    if (['between', 'notBetween'].includes(data.operator)) {
      activityRestriction['type'] = 'NumericRangeCondition';
      activityRestriction['start'] = data.startCount;
      activityRestriction['end'] = data.endCount;
    } else if (['blank', 'notBlank'].includes(data.operator)) {
      activityRestriction['type'] = 'NumericValueCondition';
      activityRestriction['value'] = '';
    } 
 
    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['activityRestriction'] = activityRestriction;
    return criterion;
  }

  adaptEcDkSegmentCompanyCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'NumericValueCondition',
      operator: data.negated ? 'notEqual': 'equal',
      value: data.segment
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkSegmentGoCCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'NumericValueCondition',
      operator: data.negated ? 'notEqual': 'equal',
      value: data.segment
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkContactInformationPermissionsCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'NumericValueCondition',
        value: data.permission
      };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkSubscriptionCriterion(item: any) {
    const data = item.data.value;
    const opeators = ['equal', 'contains', 'startsWith'];

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'TextValueCondition',
        operator:data.operator,
      };

      if (data.operator != 'equal') {
        condition['value'] = data.textValue;
      }
      else {
        condition['value'] = data.subscription
      }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkExcludeSubscriptionCriterion(item: any) {
    const data = item.data.value;
    const opeators = ['equal', 'contains', 'startsWith'];

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'TextValueCondition',
        operator:data.operator,
      };

      if (data.operator != 'equal') {
        condition['value'] = data.textValue;
      }
      else {
        condition['value'] = data.subscription
      }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkNotSubscriptionCriterion(item: any) {
    const data = item.data.value;
    const opeators = ['equal', 'contains', 'startsWith'];

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'TextValueCondition',
        operator:data.operator,
      };

      if (data.operator != 'equal') {
        condition['value'] = data.textValue;
      }
      else {
        condition['value'] = data.subscription
      }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkUserLoginsCriterion(item: any) {
    const data = item.data.value;

    let activityRestriction = { };
    if (data.operator == 'equal' || data.operator == 'greater' || data.operator == 'less') {
      activityRestriction['type'] = 'NumericValueCondition';
      activityRestriction['operator'] = data.operator;
      activityRestriction['value'] = data.startCount;
    } 

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['activityRestriction'] = activityRestriction;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkUsersPrAgreementCriterion(item: any) {
    const data = item.data.value;
    let activityRestriction = { };
    
    if (data.operator == 'equal' || data.operator == 'greater' || data.operator == 'less') {
      activityRestriction['type'] = 'NumericValueCondition';
      activityRestriction['operator'] = data.operator;
      activityRestriction['value'] = data.startCount;
    } else if (data.operator == 'between') {
      activityRestriction['type'] = 'NumericRangeCondition';
      activityRestriction['operator'] = data.operator;
      activityRestriction['start'] = data.startCount;
      activityRestriction['end'] = data.endCount;
    } 

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.textValue
    };

    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['activityRestriction'] = activityRestriction;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkContentServiceTableCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'TextValueCondition',
        operator: 'equal',
        value: data.cstable
      };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkAOsWithExternalsCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'NumericValueCondition',
      operator:'equal',
      value: data.external
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkMobileAppUsedCriterion(item: any) {
    const data = item.data.value;
    
    let timeRestriction = { };
    if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    } else if (data.periodType == 'blank') {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = '';
    } 

    let criterion = { };
    console.log("ITEM ID = " + item.id)
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkTrialUserSignupSourceCriterion(item: any) {
    const data = item.data.value;
    const opeators = ['equal', 'contains', 'startsWith'];

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'TextValueCondition',
        operator:data.operator,
      };

      if (data.operator != 'equal') {
        condition['value'] = data.textValue;
      }
      else {
        condition['value'] = data.signupSource
      }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkNewTrialUserSignupSourceCriterion(item: any) {
    const data = item.data.value;
    const opeators = ['equal', 'contains', 'startsWith'];

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'TextValueCondition',
        operator:data.operator,
      };

      if (data.operator != 'equal') {
        condition['value'] = data.textValue;
      }
      else {
        condition['value'] = data.signupSource
      }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptEcDkUnlessInAppClickCriterion(item: any) {
    const data = item.data.value;
    const opeators = ['equal', 'contains', 'startsWith'];

    if(data.invalid)
      this.invalid = data.invalid;

      let condition = {
        type: 'TextValueCondition',
        operator:data.operator,
      };

      if (data.operator != 'equal') {
        condition['value'] = data.textValue;
      }
      else {
        condition['value'] = data.click
      }

      let timeRestriction = { };
      if (['on', 'before', 'after'].includes(data.periodType)) {
        timeRestriction['type'] = 'DateValueCondition';
        timeRestriction['operator'] = data.periodType;
        timeRestriction['value'] = this.unixSeconds(data.startDate);
      } else if (data.periodType == 'within') {
        timeRestriction['type'] = 'DateRangeCondition';
        timeRestriction['operator'] = data.periodType;
        timeRestriction['start'] = this.unixSeconds(data.startDate);
        timeRestriction['end'] = this.unixSeconds(data.endDate);
      } else if (data.periodType == 'withinLast') {
        timeRestriction['type'] = 'RelativeDateCondition';
        timeRestriction['operator'] = data.periodType;
        timeRestriction['offset'] = data.offset;
        timeRestriction['period'] = data.relativePeriodType;
      }
  
      let criterion = { };
      if (item.id > -1) {
        criterion['id'] = item.id;
      }
      criterion['initialId'] = item.initialId;
      criterion['type'] = item.type;
      criterion['name'] = item.name;
      criterion['description'] = item.description;
      criterion['condition'] = condition;
      criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptEcDkUnlessInAppDisplayCriterion(item: any) {
    const data = item.data.value;
    const opeators = ['equal', 'contains', 'startsWith'];

    if(data.invalid)
      this.invalid = data.invalid;
      let condition = {
        type: 'TextValueCondition',
        operator:data.operator,
      };

      if (data.operator != 'equal') {
        condition['value'] = data.textValue;
      }
      else {
        condition['value'] = data.display
      }

      let timeRestriction = { };
      if (['on', 'before', 'after'].includes(data.periodType)) {
        timeRestriction['type'] = 'DateValueCondition';
        timeRestriction['operator'] = data.periodType;
        timeRestriction['value'] = this.unixSeconds(data.startDate);
      } else if (data.periodType == 'within') {
        timeRestriction['type'] = 'DateRangeCondition';
        timeRestriction['operator'] = data.periodType;
        timeRestriction['start'] = this.unixSeconds(data.startDate);
        timeRestriction['end'] = this.unixSeconds(data.endDate);
      } else if (data.periodType == 'withinLast') {
        timeRestriction['type'] = 'RelativeDateCondition';
        timeRestriction['operator'] = data.periodType;
        timeRestriction['offset'] = data.offset;
        timeRestriction['period'] = data.relativePeriodType;
      }
  
      let criterion = { };
      if (item.id > -1) {
        criterion['id'] = item.id;
      }
      criterion['initialId'] = item.initialId;
      criterion['type'] = item.type;
      criterion['name'] = item.name;
      criterion['description'] = item.description;
      criterion['condition'] = condition;
      criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptAcceptedTosCriterion(item: any) {
    const data = item.data.value;

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptReceivedBadgeCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.badge
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptReceivedCertificateCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.certificate
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptAccountViewRelativeVersionCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'NumericValueCondition',
      operator: 'equal',
      value: data.relativeVersion
    };

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;

    return criterion;
  }

  adaptAccountViewActivatedFeatureCriterion(item: any) {
    const data = item.data.value;

    if(data.invalid)
      this.invalid = data.invalid;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.feature
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptSystemFirstActivityCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.applicationType
    };

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  adaptSystemActivityCriterion(item: any) {
    const data = item.data.value;

    let condition = {
      type: 'TextValueCondition',
      operator: 'equal',
      value: data.applicationType
    };

    let activityRestriction = { };
    if (data.operator == 'equal' || data.operator == 'greater') {
      activityRestriction['type'] = 'NumericValueCondition';
      activityRestriction['operator'] = data.operator;
      activityRestriction['value'] = data.startCount;
    } else if (data.operator == 'between') {
      activityRestriction['type'] = 'NumericRangeCondition';
      activityRestriction['operator'] = data.operator;
      activityRestriction['start'] = data.startCount;
      activityRestriction['end'] = data.endCount;
    } else {

    }

    let timeRestriction = { };
    if (['on', 'before', 'after'].includes(data.periodType)) {
      timeRestriction['type'] = 'DateValueCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['value'] = this.unixSeconds(data.startDate);
    } else if (data.periodType == 'within') {
      timeRestriction['type'] = 'DateRangeCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['start'] = this.unixSeconds(data.startDate);
      timeRestriction['end'] = this.unixSeconds(data.endDate);
    } else if (data.periodType == 'withinLast') {
      timeRestriction['type'] = 'RelativeDateCondition';
      timeRestriction['operator'] = data.periodType;
      timeRestriction['offset'] = data.offset;
      timeRestriction['period'] = data.relativePeriodType;
    }

    let criterion = { };
    if (item.id > -1) {
      criterion['id'] = item.id;
    }
    criterion['initialId'] = item.initialId;
    criterion['type'] = item.type;
    criterion['name'] = item.name;
    criterion['description'] = item.description;
    criterion['condition'] = condition;
    if (Object.keys(activityRestriction).length !== 0) {
      criterion['activityRestriction'] = activityRestriction;
    }
    criterion['timeRestriction'] = timeRestriction;

    return criterion;
  }

  unixSeconds(date: string) {
    return Date.parse(date)/1000;
  }

}
