<div class="dialog">
  <div class="header">
  	<div>Contacts without the addon...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">

      <ng-select class="custom" style="position: absolute; width: 300px;"
      [items]="addons" 
      bindLabel="name" 
      bindValue="id" 
      placeholder="{{ addonPlaceholder }}"
      [formControl]="addon" required>
    </ng-select>

    </form>
  </div>
</div>