import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Decision } from './decision.model';
import { DecisionService } from './decision.service';

import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DecisionResolver implements Resolve<Decision> {
  
  constructor(private decisionService: DecisionService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Decision> | Promise<Decision> | Decision {
  	const id = route.params['id'];
  	return this.decisionService.getDecision(id);
  }
}