import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface FilterEvent {
	index?: number,
	event: MouseEvent
}

@Injectable({
  providedIn: 'root'
})

export class FilterService {

  private $deselect: Subject<FilterEvent>;

  private $contextmenu: Subject<FilterEvent>;

  constructor() { 
  	this.$deselect = new Subject<FilterEvent>();
  	this.$contextmenu = new Subject<FilterEvent>();
  }

  public onMouseDown(event: FilterEvent) {
  	this.$deselect.next(event);
  }

  public onContextMenu(event: FilterEvent) {
  	this.$contextmenu.next(event);
  }

  public deselect(): Observable<FilterEvent> {
    return this.$deselect.asObservable()
  }

  updateCriterion() {
    
  }
}