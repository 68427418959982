import { Component } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DialogConfig } from '../dialog/dialog-config';

@Component({
  selector: 'vmc-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss']
})
export class SaveDialogComponent {

  
  constructor(public dialogConfig: DialogConfig, private dialogRef: DialogRef) { }

  onClose() {
  	this.dialogRef.close();
  }

}
