import { 
	ChangeDetectorRef,
	Component,
  ElementRef,
	HostListener,
	Inject,
	OnInit,
	OnDestroy
} from '@angular/core';

import { ContextMenuItemDirective } from '../context-menu-item.directive';

@Component({
  selector: 'vmc-context-menu-content',
  templateUrl: './context-menu-content.component.html',
  styleUrls: ['./context-menu-content.component.scss']
})
export class ContextMenuContentComponent implements OnInit, OnDestroy {

  public menuItems: ContextMenuItemDirective[];
  public subject: any;

  constructor(
  	private cdRef: ChangeDetectorRef
  	/*
  	@Inject('menuItems') 
  	private menuItems: ContextMenuItemDirective[]*/) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  	this.cdRef.detach();
  }

  onSelect(menuItem: ContextMenuItemDirective, event: MouseEvent) {
  	event.preventDefault();
  	menuItem.triggerExecute(this.subject);
  }


}
