import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'vmc-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss']
})
export class SearchboxComponent implements OnInit, OnChanges {

  @Input() 
  items: any;

  @Input()
  placeholder: string;
  
  @Input()
  ignore: string[] = [];

  @Output() 
  found: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  ngOnChanges(event: SimpleChanges) {
    
  }

  onKeyUp(event: any) {
  	const searchTerm = event.target.value.toLowerCase();

    if (this.ignore.includes(searchTerm)) {
      this.found.emit([]);
    } else {
    	const foundItems = this.transform(this.items, searchTerm);
    	this.found.emit(foundItems);
    }
  }

  transform(items: any, term: string) {
    if (!term || !items) {
    	return items;
    }
    return this.filter(items, term);
  }

  filter(items: Array<{ [key: string]: any }>, termStr: string): Array<{ [key: string]: any }> {
  	const terms = termStr.split(';');

  	return items.filter((item: any) => {
      for (let term of terms) {
        if (this.match(item, term))
          continue;
        else
          return false;
      }
      return true;
    });
  }

  match(item: any, toCompare: string): boolean {
  	for (let property in item) {

  		if (item[property] === null || item[property] === undefined) {
  			continue;
  		}

  		if (item[property] === Object(item[property])) {
  			if (!this.match(item[property], toCompare)) {
  				continue;
  			}
  			return true;
  		}

  		if (item[property].toString().toLowerCase().includes(toCompare)) {
  			return true;
  		}
	  }

	  return false;
  }

}
