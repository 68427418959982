import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';


@Component({
  selector: 'app-ec-dk-number-of-employees',
  templateUrl: './ec-dk-number-of-employees.component.html',
  styleUrls: ['./ec-dk-number-of-employees.component.scss']
})
export class EcDkNumberOfEmployeesComponent implements OnInit {

  startCounts: ListItem<number, string>[] = [new ListItem(0, '0'), new ListItem(1, '1'),
  new ListItem(2, '2'), new ListItem(5, '5'), new ListItem(10, '10'),
  new ListItem(20, '20'), new ListItem(50, '50'), new ListItem(100, '100'),
  new ListItem(200, '200'), new ListItem(500, '500'), new ListItem(1000, '1000')];

  endCounts: ListItem<number, string>[] = [new ListItem(0, '0'), new ListItem(1, '1'),
  new ListItem(4, '4'), new ListItem(9, '9'), new ListItem(19, '19'),
  new ListItem(49, '49'), new ListItem(99, '99'), new ListItem(199, '199'),
  new ListItem(499, '499'), new ListItem(999, '999'), new ListItem(999999, '999999')];

  operators = [
    {id: "between", name: "between"},
    {id: "blank", name: "is blank"}
  ];

  negatedOperators = new Map<string, string>([
    ['between', 'notBetween'],
    ['blank', 'notBlank']
  ])

	placeholder = "Select an operator";
  checkboxText: string = "not";
  data:any;

  form: FormGroup;
  private readonly criterionId: number;

  constructor(
    private formBuilder: FormBuilder, 
    private dialogConfig: DialogConfig, 
    private criterionService: CriterionService) {
  	this.criterionId = this.dialogConfig.data.id;
  }

  get operator() { 
  	return this.form.get('operator'); 
  }

  get negated() {
	return this.form.get('negated');
}

  get startCount() {
    return this.form.get('startCount');
  }

  get endCount() {
    return this.form.get('endCount');
  }

 ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

  	this.form =  this.formBuilder.group({
		'operator': this.formBuilder.control(this.data.operator, [Validators.required]),
		'negated': this.formBuilder.control(this.data.negated),
    'startCount': this.formBuilder.control(this.data.startCount, [Validators.required]),
    'endCount': this.formBuilder.control(this.data.endCount, [Validators.required])

     });
     
    if (this.data.operator == 'between') {
      this.startCount.enable({ emitEvent: false });
  		this.endCount.enable({ emitEvent: false });
  	} else if (this.data.operator == 'blank') {
      this.startCount.reset(null, { emitEvent: false });
      this.endCount.reset(null, { emitEvent: false })
      this.startCount.disable({ emitEvent: false });
  		this.endCount.disable({ emitEvent: false }); 
  	}

   	let formValue = this.form.value;
  	formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription(formValue);
    this.criterionService.saveData(this.criterionId, formValue);

    this.form.valueChanges.subscribe(value => {

      if (value.operator == 'between') {
        this.startCount.enable({ emitEvent: false });
    		this.endCount.enable({ emitEvent: false });
      }
      
      if (value.operator == 'blank') {
        this.startCount.reset(null, { emitEvent: false });
        this.endCount.reset(null, { emitEvent: false });
        this.startCount.disable({ emitEvent: false });
        this.endCount.disable({ emitEvent: false });
      }
  		
    	value.invalid = this.form.invalid;
      //value.startCount = value.startCount && value.startCount.toString().replace(/^0+(?=.)/, '');
      //value.endCount = value.endCount && value.endCount.toString().replace(/^0+(?=.)/, '');
      value.description = this.buildDescription(value);
    	this.criterionService.saveData(this.criterionId, value);
    	});
  }

  onCheckBoxClick(event: any) {
	this.negated.setValue(event.checked);
}

  buildDescription(value: any) {
  	let activityRestriction: string;
  	let description: string;

  	if (this.form.invalid) {
  		description = 'Configuration has validation errors. Double click to configure.';
  	} else {
      if (this.operator.value == 'between' && !this.negated.value) {
        const name1 = this.startCounts.find(startCount => startCount.id == this.startCount.value).name;
        const name2 = this.endCounts.find(endCount => endCount.id == this.endCount.value).name;
        console.log('==================================== = ' );
          console.log('name1 = ' + name1);
          console.log('name2 = ' + name2);
        if (parseInt(name1) <= parseInt(name2)){
          activityRestriction = `between "${name1}" and "${name2}"`;
          description = `Number of employees  ${activityRestriction}`;
        }
        else {
          description = 'Configuration has validation errors. NumberOfEmployeesFrom is greater than NumberOfEmployeesTo.';
        }
      } else if (this.operator.value == 'between' && this.negated.value) {
        const name1 = this.startCounts.find(startCount => startCount.id == this.startCount.value).name;
        const name2 = this.endCounts.find(endCount => endCount.id == this.endCount.value).name;
        if (parseInt(name1) <= parseInt(name2)){
          activityRestriction = `not between "${name1}" and "${name2}"`;
          description = `Number of employees  ${activityRestriction}`;
        }
        else {
          description = 'Configuration has validation errors. NumberOfEmployeesFrom is greater than NumberOfEmployeesTo.';
        }
      } else if (this.operator.value == 'blank' && !this.negated.value) {
        activityRestriction = ` is blank`;
        description = `Number of employees  ${activityRestriction}`;
      } else if (this.operator.value == 'blank' && this.negated.value) {
        activityRestriction = `notBlank`;
        description = `Number of employees  ${activityRestriction}`;
		  }
  	}

  	return description;
  }
}

