<div class="dialog">
  <div class="header">
  	<div>Contacts in content service table...</div>
  </div>

  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">
      <ng-select
      class="custom" 
      style="position: absolute; left: 50px; width: 325px;"
      [items]="cstables" 
      bindLabel="name" 
      bindValue="id" 
      placeholder="{{ Placeholder }}"
      [formControl]="cstable" required>
    </ng-select>

    </form>
  </div>
</div>