import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ActionModule } from './action/action.module';
import { FeederModule } from './feeder/feeder.module' ;
import { DecisionModule } from './decision/decision.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InitService } from './core/init.service';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

export function init_app(initService: InitService) {
    return () => initService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    ActionModule,
    DecisionModule,
    FeederModule,
    FormsModule,
    ReactiveFormsModule
  ],/*
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: init_app,
    deps: [InitService],
    multi: true
  }],*/
  bootstrap: [AppComponent]
})
export class AppModule { }
