import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, Input, HostListener, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

enum CheckStatus {
    CHECKED = 1,
    UNCHECKED = 'unchecked'
}

interface CheckEvent {
  id: string
	checked: boolean
}

@Component({
  selector: 'vmc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnChanges {

  @Input('text') 
  text: string;

  @Input('id')
  id: string;
  
  checked: boolean;

  @Output() 
  check: EventEmitter<CheckEvent> = new EventEmitter<CheckEvent>();

  @ViewChild('checkbox')
  checkboxRef: ElementRef;

  @Input('initialValue')
  private _checked: boolean = false;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  	if (this._checked) {
  		this.renderer.addClass(this.checkboxRef.nativeElement, 'sel');
  	}
  }

  ngOnChanges(changes: SimpleChanges) {
    let change = changes['_checked'];
    if (change.currentValue) {
      this.renderer.addClass(this.checkboxRef.nativeElement, 'sel');
    } else {
      this.renderer.removeClass(this.checkboxRef.nativeElement, 'sel');
    }
  }

  onClick() {
  	this._checked = !this._checked;

  	if (this._checked) {
  		this.renderer.addClass(this.checkboxRef.nativeElement, 'sel');
  	} else {
  		this.renderer.removeClass(this.checkboxRef.nativeElement, 'sel');
  	}

  	this.check.emit({ id: this.id, checked:  this._checked });
  }

}
