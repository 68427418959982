import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { CanvasService } from './canvas.service';
import { CanvasConfig } from './canvas.interface';

@Component({
  selector: 'vmc-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit, OnChanges {

  //@Input() config: any;

  @Input() filterConfig: any;
  @Input() panelConfig: CanvasConfig;

  @Output() cnvchange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private canvasService: CanvasService) { }

  ngOnInit() {
  	console.log(JSON.stringify(this.filterConfig));
  	this.canvasService.storeCriteria(this.filterConfig.criteria);
  	this.canvasService.setPanelConfig(this.panelConfig);
  	this.canvasService.setStatement(this.filterConfig.statement);

  	this.canvasService.change.subscribe(change => this.cnvchange.emit(change));
  }

  ngOnChanges(changes: SimpleChanges) {
  	console.log('on changes')
  	const filterConfig: SimpleChange = changes.filterConfig;
  	if (filterConfig) {
  		this.filterConfig = filterConfig.currentValue;
      console.log("CHANGED")
      console.log(JSON.stringify(this.filterConfig.criteria));
	  	this.canvasService.storeCriteria(this.filterConfig.criteria);
      console.log(JSON.stringify(this.filterConfig.statement))
	  	this.canvasService.setStatement(this.filterConfig.statement);  		
  	}
  }
}