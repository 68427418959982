import { 
  ComponentRef,
  ElementRef,
  Injectable, 
  Injector,
  Type
} from '@angular/core';

import { 
  ConnectedPositionStrategy,
  OriginConnectionPosition,
  OverlayConnectionPosition,
  Overlay,
  OverlayConfig, 
  OverlayRef,
  PositionStrategy,
  ScrollStrategyOptions
} from '@angular/cdk/overlay';

import { ComponentPortal } from '@angular/cdk/portal';

import { DialogRef } from './dialog-ref';
import { DialogInjector } from './dialog-injector';
import { DialogModule } from './dialog.module';
import { DialogConfig } from './dialog-config';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: DialogModule
})
export class DialogService {

  private overlayRef: OverlayRef;
  private dialogComponentRef: ComponentRef<DialogComponent>; 

  constructor(private overlay: Overlay,
    private injector: Injector,
    private scrollStrategy: ScrollStrategyOptions) { }

  // if targetRef is empty, then dialog is attached to the ApplicationRef
  public open(componentType: Type<any>, config: DialogConfig, targetRef?: ElementRef): DialogRef {
    const map = new WeakMap<any, any>();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);

    if (!targetRef) {
      const dialogRef = this.appendDialogComponentToBody(componentType, config);
      this.dialogComponentRef.instance.childComponentType = componentType;
      return dialogRef;
    }

    const sub = dialogRef.afterClosed.subscribe();

    const originPos: OriginConnectionPosition = { 
      originX: 'center', 
      originY: 'bottom'
    };

    const overlayPos: OverlayConnectionPosition = { 
      overlayX: 'center', 
      overlayY: 'top'
    };

    const fallbackOriginPos: OriginConnectionPosition = { 
      originX: 'center', 
      originY: 'top'
    };

    const fallbackOverlayPos: OverlayConnectionPosition = { 
      overlayX: 'center', 
      overlayY: 'bottom'
    };    

    const positionStrategy: ConnectedPositionStrategy = this.overlay.position()
      .connectedTo(targetRef, originPos, overlayPos).withOffsetY(10)
      .withFallbackPosition(fallbackOriginPos, fallbackOverlayPos, 0, -10);

    const overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'transparent-backdrop',
      positionStrategy,
      panelClass: "app-dialog"
    };

    const dialogInjector = new DialogInjector(this.injector, map);
    const overlayRef = this.overlay.create(overlayConfig);

    overlayRef.backdropClick().subscribe(() => {
      dialogRef.close();
      overlayRef.dispose();
    });

    const componentRef = overlayRef.attach(new ComponentPortal(DialogComponent, null, dialogInjector));

    this.dialogComponentRef = componentRef;

    positionStrategy.onPositionChange.subscribe((event) => {
      if (event.connectionPair.originY === 'top') {
        console.log('TOP')
        this.dialogComponentRef.instance.addPointerClass('perfectTop');
      } else {
        console.log('BOTTOM')
        this.dialogComponentRef.instance.addPointerClass('perfectBottom');
      }
    });

    this.dialogComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponent();
    });

    this.dialogComponentRef.instance.childComponentType = componentType;
    
    return dialogRef;
  }


  private appendDialogComponentToBody(componentType: Type<any>, config: DialogConfig) {
    const map = new WeakMap<any, any>();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);

    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy,
      panelClass: "app-dialog"
    };  

    const dialogInjector = new DialogInjector(this.injector, map);
    const overlayRef = this.overlay.create(overlayConfig);

    const sub = dialogRef.afterClosed.subscribe(() => {
      this.removeDialogComponent();
      overlayRef.dispose();
      sub.unsubscribe();
    });    

    /*
    overlayRef.backdropClick().subscribe(() => {
      dialogRef.close();
      overlayRef.dispose();
    });
    */

    const componentRef = overlayRef.attach(new ComponentPortal(DialogComponent, null, dialogInjector));

    this.dialogComponentRef = componentRef;    

    this.dialogComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponent();
    });

    this.dialogComponentRef.instance.childComponentType = componentType;

    return dialogRef;
  }

  private removeDialogComponent() {
    this.dialogComponentRef.destroy();
  }
}
