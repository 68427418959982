import { 
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ComponentRef,
	ElementRef,
	Input,
	OnDestroy,
	QueryList,
	ViewEncapsulation
} from '@angular/core';

import { Subscription } from 'rxjs';
import { ContextMenuItemDirective } from './context-menu-item.directive';
import { ContextMenuService, ContextMenuClickEvent, ContextMenuContext } from './context-menu.service';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'vmc-context-menu',
  template: '',
  styles: [`
  	.cdk-overlay-container {
  		position: fixed;
 	 	z-index: 1000;
  		pointer-events: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.app-contextmenu.cdk-overlay-pane {
		position: absolute;
		pointer-events: auto;
	  	box-sizing: border-box;
	}
  `]
})
export class ContextMenuComponent implements OnDestroy {

  @Input()
  disabled = false;

  //private subscription: Subscription;

  @ContentChildren(ContextMenuItemDirective)
  private menuItems: QueryList<ContextMenuItemDirective>;

  public get visibleMenuItems(): ContextMenuItemDirective[] {
  	return this.menuItems.filter(item => item.visible);
  }
  
  constructor(private contextMenuService: ContextMenuService,
  		private cdRef: ChangeDetectorRef) {
  	console.log('contrxt-menu created')
  	this.contextMenuService.register(this);

  	/*
  	this.subscription = this.contextMenuService.getMessage()
  		.subscribe(event => this.onMenuEvent(event))
  	*/
  }

  ngOnDestroy() {
  	this.contextMenuService.unregister(this);
  	console.log('context-menu destroyed');
  	this.cdRef.detach();
  	//this.subscription.unsubscribe();
  }
  /*
  onMenuEvent(ctxEvent: ContextMenuClickEvent) {
  	// open context menu only when it is not disabled and context menu componnet instances match
  	if (!this.disabled && ctxEvent.contextMenu == this) {
  		const visibleMenuItems = this.menuItems.filter(item => item.visible);

	  	const context: ContextMenuContext = {
	  		target: new ElementRef(ctxEvent.event.target),
	  		menuItems: visibleMenuItems
	  	};

	  	this.contextMenuService.openContextMenu(context);
    }
  }
  */

}
