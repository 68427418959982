import { NgModule, ModuleWithProviders, Type, InjectionToken } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CanvasComponent } from './canvas.component';
import { PanelComponent } from './panel/panel.component';
import { FilterComponent } from './filter/filter.component';
import { OperatorComponent } from './filter/operator/operator.component';
import { CriterionComponent } from './filter/criterion/criterion.component';

import { CanvasConfig, CANVAS_CONFIG} from './canvas.interface';
import { ParenthesesComponent } from './filter/parentheses/parentheses.component';
import { HasLithiumRankComponent } from './filter/criterion/has-lithium-rank/has-lithium-rank.component';
import { HasLithiumRoleComponent } from './filter/criterion/has-lithium-role/has-lithium-role.component';
import { HasLoggedInToLithiumComponent } from './filter/criterion/has-logged-in-to-lithium/has-logged-in-to-lithium.component';
import { HasNotLoggedInToLithiumComponent } from './filter/criterion/has-not-logged-in-to-lithium/has-not-logged-in-to-lithium.component';
import { GaveLithiumKudosComponent } from './filter/criterion/gave-lithium-kudos/gave-lithium-kudos.component';
import { ReceivedLithiumKudosComponent } from './filter/criterion/received-lithium-kudos/received-lithium-kudos.component';
import { PublishedLithiumContentComponent } from './filter/criterion/published-lithium-content/published-lithium-content.component';
import { RespondedToLithiumSurveyComponent } from './filter/criterion/responded-to-lithium-survey/responded-to-lithium-survey.component';
import { SearchedLithiumComponent } from './filter/criterion/searched-lithium/searched-lithium.component';
import { EcDkActiveCompanyUsersComponent } from './filter/criterion/ec-dk-active-company-users/ec-dk-active-company-users.component';
import { EcDkExcludeActiveCompanyUsersComponent } from './filter/criterion/ec-dk-exclude-active-company-users/ec-dk-exclude-active-company-users.component';
import { EcDkActiveAOUsersComponent } from './filter/criterion/ec-dk-active-ao-users/ec-dk-active-ao-users.component';
import { EcDkActiveTrialUsersComponent } from './filter/criterion/ec-dk-active-trial-users/ec-dk-active-trial-users.component';
import { EcDkActiveAddonsComponent } from './filter/criterion/ec-dk-active-addons/ec-dk-active-addons.component';
import { EcDkNotAddonsComponent } from './filter/criterion/ec-dk-not-addons/ec-dk-not-addons.component';
import { EcDkPreviousAddonsComponent } from './filter/criterion/ec-dk-previous-addons/ec-dk-previous-addons.component';
import { EcDkExcludeAddonsComponent } from './filter/criterion/ec-dk-exclude-addons/ec-dk-exclude-addons.component';
import { EcDkIndustryCodesCompanyComponent } from './filter/criterion/ec-dk-industry-codes-company/ec-dk-industry-codes-company.component';
import { HasLithiumCountryComponent } from './filter/criterion/has-lithium-country/has-lithium-country.component';
import { EcDkExcludeActiveAOUsersComponent } from './filter/criterion/ec-dk-exclude-active-ao-users/ec-dk-exclude-active-ao-users.component';
import { EcDkActiveGroupofCompanyUsersComponent } from './filter/criterion/ec-dk-active-group-of-company-users/ec-dk-active-group-of-company-users.component';
import { EcDkExcludeActiveGroupofCompanyUsersComponent } from './filter/criterion/ec-dk-exclude-active-group-of-company-users/ec-dk-exclude-active-group-of-company-users.component';
import { EcDkSignupDateComponent } from './filter/criterion/ec-dk-signup-date/ec-dk-signup-date.component';
import { EcDkNewSignupDateComponent } from './filter/criterion/ec-dk-new-signup-date/ec-dk-new-signup-date.component';
import { EcDkNewTrialUsersComponent } from './filter/criterion/ec-dk-new-trial-users/ec-dk-new-trial-users.component';
import { EcDkTrialUsersComponent } from './filter/criterion/ec-dk-trial-users/ec-dk-trial-users.component';
import { EcDkNewUsersRoleComponent } from './filter/criterion/ec-dk-new-users-role/ec-dk-new-users-role.component';
import { EcDkUsersRoleComponent } from './filter/criterion/ec-dk-users-role/ec-dk-users-role.component';
import { EcDkNewUserAgreementtypeComponent } from './filter/criterion/ec-dk-new-user-agreementtype/ec-dk-new-user-agreementtype.component';
import { EcDkAgreementtypeComponent } from './filter/criterion/ec-dk-agreementtype/ec-dk-agreementtype.component';
import { EcDkNumberOfEmployeesComponent } from './filter/criterion/ec-dk-number-of-employees/ec-dk-number-of-employees.component';
import { EcDkSegmentCompanyComponent } from './filter/criterion/ec-dk-segment-company/ec-dk-segment-company.component';
import { EcDkContactInformationPermissionsComponent } from './filter/criterion/ec-dk-contact-information-permissions/ec-dk-contact-information-permissions.component';
import { EcDkSubscriptionComponent } from './filter/criterion/ec-dk-subscription/ec-dk-subscription.component';
import { EcDkExcludeSubscriptionComponent } from './filter/criterion/ec-dk-exclude-subscription/ec-dk-exclude-subscription.component';
import { EcDkNotSubscriptionComponent } from './filter/criterion/ec-dk-not-subscription/ec-dk-not-subscription.component';
import { EcDkUserLoginsComponent } from './filter/criterion/ec-dk-user-logins/ec-dk-user-logins.component';
import { EcDkUsersPrAgreementComponent } from './filter/criterion/ec-dk-users-pr-agreement/ec-dk-users-pr-agreement.component';
import { EcDkAOsWithExternalsComponent } from './filter/criterion/ec-dk-aos-with-externals/ec-dk-aos-with-externals.component';
import { EcDkMobileAppUsedComponent } from './filter/criterion/ec-dk-mobile-app-used/ec-dk-mobile-app-used.component';
import { EcDkContentServiceTableComponent } from './filter/criterion/ec-dk-content-service-table/ec-dk-content-service-table.component';
import { EcDkTrialUserSignupSourceComponent } from './filter/criterion/ec-dk-trial-user-signup-source/ec-dk-trial-user-signup-source.component';
import { EcDkNewTrialUserSignupSourceComponent } from './filter/criterion/ec-dk-new-trial-user-signup-source/ec-dk-new-trial-user-signup-source.component';
import { SeRankingUserRegisteredComponent } from './filter/criterion/seranking-user-registered/seranking-user-registered.component';
import { EcDkUnlessInAppClickComponent } from './filter/criterion/ec-dk-unless-in-app-click/ec-dk-unless-in-app-click.component';
import { EcDkUnlessInAppDisplayComponent } from './filter/criterion/ec-dk-unless-in-app-display/ec-dk-unless-in-app-display.component';
import { EcDkIndustryCodesGoCComponent }  from './filter/criterion/ec-dk-industry-codes-goc/ec-dk-industry-codes-goc.component';
import { EcDkSegmentGoCComponent } from './filter/criterion/ec-dk-segment-goc/ec-dk-segment-goc.component';

@NgModule({
	declarations: [
		CanvasComponent,
		PanelComponent,
		FilterComponent,
		OperatorComponent,
		CriterionComponent,
		ParenthesesComponent,
		HasLithiumRankComponent,
		HasLithiumRoleComponent,
		HasLoggedInToLithiumComponent,
		HasNotLoggedInToLithiumComponent,
		GaveLithiumKudosComponent,
		ReceivedLithiumKudosComponent,
		PublishedLithiumContentComponent,
		RespondedToLithiumSurveyComponent,
		SearchedLithiumComponent,
		EcDkActiveCompanyUsersComponent,
		EcDkExcludeActiveCompanyUsersComponent,
		EcDkActiveAOUsersComponent,
		EcDkActiveTrialUsersComponent,
		EcDkActiveAddonsComponent,
		EcDkNotAddonsComponent,
		EcDkPreviousAddonsComponent,
		EcDkExcludeAddonsComponent,
		EcDkIndustryCodesCompanyComponent,
		HasLithiumCountryComponent,
		EcDkExcludeActiveAOUsersComponent,
		EcDkActiveGroupofCompanyUsersComponent,
		EcDkExcludeActiveGroupofCompanyUsersComponent,
		EcDkSignupDateComponent,
		EcDkNewSignupDateComponent,
		EcDkNewTrialUsersComponent,
		EcDkTrialUsersComponent,
		EcDkNewUsersRoleComponent,
		EcDkUsersRoleComponent,
		EcDkNewUserAgreementtypeComponent,
		EcDkAgreementtypeComponent,
		EcDkNumberOfEmployeesComponent,
		EcDkSegmentCompanyComponent,
		EcDkContactInformationPermissionsComponent,
		EcDkSubscriptionComponent,
		EcDkExcludeSubscriptionComponent,
		EcDkNotSubscriptionComponent,
		EcDkUserLoginsComponent,
		EcDkUsersPrAgreementComponent,
		EcDkAOsWithExternalsComponent,
		EcDkMobileAppUsedComponent,
		EcDkContentServiceTableComponent,
		EcDkTrialUserSignupSourceComponent,
		EcDkNewTrialUserSignupSourceComponent,
		SeRankingUserRegisteredComponent,
		EcDkUnlessInAppClickComponent,
		EcDkUnlessInAppDisplayComponent,
		EcDkIndustryCodesGoCComponent,
		EcDkSegmentGoCComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule
	],
	exports: [
		CanvasComponent
	],
	entryComponents: [
		CriterionComponent,
		OperatorComponent,
		ParenthesesComponent,
		HasLithiumRankComponent,
		HasLithiumRoleComponent,
		HasLoggedInToLithiumComponent,
		HasNotLoggedInToLithiumComponent,
		GaveLithiumKudosComponent,
		ReceivedLithiumKudosComponent,
		PublishedLithiumContentComponent,
		RespondedToLithiumSurveyComponent,
		SearchedLithiumComponent,
		EcDkActiveCompanyUsersComponent,
		EcDkExcludeActiveCompanyUsersComponent,
		EcDkActiveAOUsersComponent,
		EcDkActiveTrialUsersComponent,
		EcDkActiveAddonsComponent,
		EcDkNotAddonsComponent,
		EcDkPreviousAddonsComponent,
		EcDkExcludeAddonsComponent,
		EcDkIndustryCodesCompanyComponent,
		HasLithiumCountryComponent,
		EcDkExcludeActiveAOUsersComponent,
		EcDkActiveGroupofCompanyUsersComponent,
		EcDkExcludeActiveGroupofCompanyUsersComponent,
		EcDkSignupDateComponent,
		EcDkNewSignupDateComponent,
		EcDkNewTrialUsersComponent,
		EcDkTrialUsersComponent,
		EcDkNewUsersRoleComponent,
		EcDkUsersRoleComponent,
		EcDkNewUserAgreementtypeComponent,
		EcDkAgreementtypeComponent,
		EcDkNumberOfEmployeesComponent,
		EcDkSegmentCompanyComponent,
		EcDkContactInformationPermissionsComponent,
		EcDkSubscriptionComponent,
		EcDkExcludeSubscriptionComponent,
		EcDkNotSubscriptionComponent,
		EcDkUserLoginsComponent,
		EcDkUsersPrAgreementComponent,
		EcDkAOsWithExternalsComponent,
		EcDkMobileAppUsedComponent,
		EcDkContentServiceTableComponent,
		EcDkTrialUserSignupSourceComponent,
		EcDkNewTrialUserSignupSourceComponent,
		SeRankingUserRegisteredComponent,
		EcDkUnlessInAppClickComponent,
		EcDkUnlessInAppDisplayComponent,
		EcDkIndustryCodesGoCComponent,
		EcDkSegmentGoCComponent
	]
})
export class CanvasModule { }
