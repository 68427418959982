
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-content-service-table',
  templateUrl: './ec-dk-content-service-table.component.html',
  styleUrls: ['./ec-dk-content-service-table.component.scss']
})
export class EcDkContentServiceTableComponent implements OnInit {

  cstables: ListItem<string, string>[] = [];

  form: FormGroup;

  Placeholder: string = "Loading...";
  
  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  get cstable() {
  	return this.form.get('cstable');
  }

  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

    console.log('------------DATA--------------------------------')
    console.log(JSON.stringify(this.data))
    console.log('------------DATA--------------------------------')  

    this.dataService.getTables()
    this.dataService.cstables$.subscribe(cstables => {
    	this.cstables = cstables;
    	this.Placeholder = 'Select the table...';
    });

  	this.form =  this.formBuilder.group({
      'cstable': this.formBuilder.control(this.data.cstable, [Validators.required]),
    });

    let formValue = this.form.value;
    formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription(formValue);
    this.criterionService.saveData(this.criterionId, formValue);

  	this.form.valueChanges.subscribe(value => {

      value.invalid = this.form.invalid;
      value.description = this.buildDescription(value);
  		this.criterionService.saveData(this.criterionId, value);
  	});
  }


buildDescription(value: any) {

    let description: string;
  
    if (this.form.invalid) {
      description = 'Configuration has validation errors. Double click to configure.';
    } else if (this.cstables.length == 0) {
        description = this.data.description; 
     } else if (value.cstable) {
        const name = this.cstables.find(cstable => cstable.id == value.cstable).name;
        description = `Contacts in the content service table \'${name}\'.`
      } else {
          description = "Double click to configure...";	
    }
  
    return description;
}
}
