import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'vmc-add-security-label',
  templateUrl: './add-security-label.component.html',
  styleUrls: ['./add-security-label.component.scss']
})
export class AddSecurityLabelComponent implements OnInit {

  securityLables = [
  	{ id: 1, name: 'Visma Software AS (NO-SW)' },
  	{ id: 2, name: 'Visma Software AB (SW-AB)' }
  ];

  form: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  get securityLabel() {
  	return this.form.get('securityLabel');
  }

  ngOnInit() {
  	this.form =  this.formBuilder.group({
   		'securityLabel': this.formBuilder.control(undefined, [Validators.required])
  	});
  }
}
