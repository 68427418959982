<div class="dialog">
	<div class="header">
		<div>Number of employees...</div>
	</div>
	<div class="content">
	  
	  <form [formGroup]="form" #ngForm="ngForm">
  
		<vmc-checkbox [text]="checkboxText" 
		  [initialValue]="negated.value" (check)="onCheckBoxClick($event)"
		style="position: absolute; left: 0px; top: 4px; height: 16px;"></vmc-checkbox>
		  
		<ng-select 
			class="custom" 
			style="left: 50px; width: 170px;"
			[items]="operators" 
			bindLabel="name" 
			bindValue="id" 
			placeholder="{{ placeholder }}" 
			[formControl]="operator">
		</ng-select>
		
		<label *ngIf="operator.value != 'blank'">
		 <ng-select class="custom" style="position: absolute; right: 5px; width: 85px; height: 24px; left: 240px; top: 0px;"
			[items]="startCounts" bindLabel="name" bindValue="id"
			[formControl]="startCount" required>
			</ng-select>   
		</label>
  
		<!-- visible only if 'between' -->
		<div *ngIf="operator.value == 'between'" style="position: absolute; left: 340px; top: 5px; font-weight: normal">and</div>
  
		<label *ngIf="operator.value == 'between'">
			<ng-select class="custom" style="position: absolute; right: 5px; width: 95px; height: 24px; left: 370px; top: 0px"
				[items]="endCounts" bindLabel="name" bindValue="id"
				[formControl]="endCount" required>
			</ng-select>
		</label> 
	  
	  </form>
	  </div>
  </div>