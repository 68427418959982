import { 
	Directive,
	EventEmitter,
	Input,
	Output,
	TemplateRef
} from '@angular/core';

@Directive({
  selector: '[appContextMenuItem]'
})
export class ContextMenuItemDirective {

  @Input()
  public visible: boolean = true;

  @Output() 
  public execute: EventEmitter<any>;

  constructor(public template: TemplateRef<any>) { 
  	this.execute = new EventEmitter();
  }

  triggerExecute(subject: any) {
  	this.execute.emit(subject);
  }

}
