import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-new-signup-date',
  templateUrl: './ec-dk-new-signup-date.component.html',
  styleUrls: ['./ec-dk-new-signup-date.component.scss']
})
export class EcDkNewSignupDateComponent implements OnInit {

  periodTypes = [
    { id: 'withinLast', name: 'within the last' },
    { id: 'today', name: 'today' },
    { id: 'blank', name: 'is blank'}
  ];

  relativePeriodTypes = [
    { id: 'minutes', name: 'Minutes' },
    { id: 'hours', name: 'Hours' },
    { id: 'days', name: 'Days' },
    { id: 'weeks', name: 'Weeks' },
    { id: 'months', name: 'Months' }
  ];

  form: FormGroup;

  periodTypePlaceholder: string = "Select SignupDate";

  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dialogConfig: DialogConfig) {

    this.criterionId = this.dialogConfig.data.id;
  }

  get periodType() { 
    return this.form.get('periodType'); 
  }

  get relativePeriodType() {
    return this.form.get('relativePeriodType');
  }
/*
  get startDate() {
    return this.form.get('startDate');
  }
*/
  get offset() {
    return this.form.get('offset');
  }

  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

    console.log('------------DATA--------------------------------')
    console.log(JSON.stringify(this.data))
    console.log('------------DATA--------------------------------')  

    this.form =  this.formBuilder.group({
      'periodType': this.formBuilder.control(this.data.periodType, [Validators.required]),
      'relativePeriodType': this.formBuilder.control(this.data.relativePeriodType, [Validators.required]),  		
    //  'startDate': this.formBuilder.control(this.data.startDate, [Validators.required]),
      'offset': this.formBuilder.control(this.data.offset, [Validators.required]),
    });
/*
    if (['blank', 'today'].includes(this.data.periodType)) {
      this.startDate.reset();
      this.startDate.disable();
    }
*/
    if (['blank', 'today'].includes(this.data.periodType)) {
  //    this.startDate.reset(null, { emitEvent: false });
  //    this.startDate.disable({ emitEvent: false });
      this.relativePeriodType.disable({ emitEvent: false });
      this.offset.disable({ emitEvent: false });
    } else if (this.data.periodType == 'withinLast') {
      this.offset.enable({ emitEvent: false });
      this.relativePeriodType.enable({ emitEvent: false });
    //  this.startDate.disable({ emitEvent: false });
    }  
    
    let formValue = this.form.value;
    formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, formValue);

    this.form.valueChanges.subscribe(value => {

      if (['blank', 'today'].includes(value.periodType)) {
   //     this.startDate.reset(null, { emitEvent: false });
   //     this.startDate.disable({ emitEvent: false });
        this.relativePeriodType.disable({ emitEvent: false });
        this.offset.disable({ emitEvent: false });
      }

      if (value.periodType == 'withinLast') {
        this.offset.enable({ emitEvent: false });
        this.relativePeriodType.enable({ emitEvent: false });
   //     this.startDate.disable({ emitEvent: false });
      }

      value.invalid = this.form.invalid;
      value.offset = value.offset && value.offset.toString().replace(/^0+(?=.)/, '');
      value.description = this.buildDescription();
      this.criterionService.saveData(this.criterionId, value);
    });
  }

buildDescription() {
  let timeRestriction: string;
  let description: string;

  if (this.form.invalid) {
       description = 'Configuration has validation errors. Double click to configure.';
  } else {
    const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == this.periodType.value);
    if (periodTypeIndex > -1 && this.periodType.value == 'withinLast' && this.offset.value && this.relativePeriodType.value) {
        timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${this.offset.value} ${this.relativePeriodType.value}`;
    } else if (periodTypeIndex > -1 && ['blank', 'today'].includes(this.periodType.value)) {
      timeRestriction = `${this.periodTypes[periodTypeIndex].name}`;
  }
     
    if (timeRestriction) {
      description = `Users with the Signup Date '${timeRestriction}'.`
    } else {
      description = "Double click to configure...";
    }	
  } 
  return description;
}
}




