<div class="dialog">
  <div class="header">
    <div>Users signup date...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">
  
      <vmc-checkbox [text]="checkboxText" 
        [initialValue]="negated.value" (check)="onCheckBoxClick($event)"
        style="position: absolute; left: 0px; top: 4px; height: 16px;">
      </vmc-checkbox>
  
      <ng-select 
          class="custom" 
          style="left: 50px; width: 170px; top: 5px;"
          [items]="periodTypes"
          bindLabel="name" 
          bindValue="id" 
          placeholder="{{ periodTypePlaceholder }}" 
          [formControl]="periodType">
        </ng-select>
  
      <label *ngIf="periodType.value != 'withinLast'"
        style="position: absolute; width: 210px; height: 24px; top: 35px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
        <span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
          <input type="date" maxlength="5096" [formControl]="startDate" 
            style="width: 100%; height: 100%; padding: 0; border: 0; color: #333333;">
        </span>
      </label>
  
      <!--<input *ngIf="periodType.value != 'within the last'" [formControl]="startDate" style="position: absolute; width: 100px; height: 24px; top: 35px; left: 180px;">-->
      <!-- visible only if 'within'  -->
      <div *ngIf="periodType.value == 'within'" style="position: absolute; width: 40px; height: 24px; top: 40px; left: 210px; text-align: center;">and</div>
  
      <label *ngIf="periodType.value == 'within'" 
        style="position: absolute; width: 210px; height: 24px; top: 35px; left: 250px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
        <span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
          <input type="date" maxlength="5096" [formControl]="endDate" 
            style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
        </span>
      </label>
        
      <!--<input *ngIf="periodType.value == 'within'" [formControl]="endDate" style="position: absolute; width: 100px; height: 24px; top: 35px; left: 320px;">-->
        
        
      <!-- visible only if 'within the last' -->
      <label *ngIf="periodType.value == 'withinLast'"
        style="position: absolute; width: 40px; height: 24px; left: 230px; top: 5px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
        <span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
          <input type="text" maxlength="5096" 
            vmcOnlyNumber [formControl]="offset" 
            style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
        </span>
      </label>
  
      <!--<input *ngIf="periodType.value == 'within the last'" vmcOnlyNumber [formControl]="offset" style="position: absolute; width: 40px; height: 24px; left: 180px; top: 35px;">-->
      <ng-select *ngIf="periodType.value == 'withinLast'"
        class="custom" 
        style="position: absolute; width: 100px; height: 24px; top: 5px; left: 280px;"
        [items]="relativePeriodTypes" 
        bindLabel="name" 
        bindValue="id"  
        [formControl]="relativePeriodType">
      </ng-select>       
    </form>
  </div>
</div>
