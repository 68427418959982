import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ParenthesesConfig } from '../filter.model';
import { FilterService } from '../filter.service';

export interface ParenthesesEvent {
	index: number,
	event: MouseEvent
}

@Component({
  selector: 'app-parentheses',
  templateUrl: './parentheses.component.html',
  styleUrls: ['./parentheses.component.scss']
})
export class ParenthesesComponent implements OnInit {

  @Output()
  public _select: EventEmitter<ParenthesesEvent> = new EventEmitter<ParenthesesEvent>();

  @ViewChild('target')
  target: ElementRef

  index: number;

  identationLevel: number = 0;

  identationPx = 16;

  statement: string;

  private selected: boolean = false;

  constructor(private renderer: Renderer2, private config: ParenthesesConfig, private filterService: FilterService) { }

  ngOnInit() {
    this.index = this.config.index;
    this.statement = this.config.value;
    this.identationLevel = this.config.identationLevel;
  	this.filterService.deselect().subscribe(event => {
  		if (this.selected) {
  			this.deselect(event.event);
  		}
  	});

  	const element: HTMLElement = this.target.nativeElement;
    const outline: HTMLElement = element.querySelector('.outline-wrapper');
  	this.renderer.setStyle(outline, 'left', (this.identationLevel*this.identationPx).toString() + 'px'); 
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
  	this._select.emit({ index: this.index, event: event});
  	event.stopPropagation();
  }

  select() {
  	const element: HTMLElement = this.target.nativeElement;
  	this.renderer.setStyle(element.childNodes[0], 'background', '#afd9f4'); 
  	this.selected = true;	
  }

  deselect(event: MouseEvent) {
  	const element = this.target.nativeElement;
    if (!element.contains(event.target)) {
    	this.renderer.removeStyle(element.childNodes[0], 'background');
        this.selected = false;
    }  	  	
  }

  addIdentation() {
  	this.identationLevel++;
  	const element: HTMLElement = this.target.nativeElement;
    const outline: HTMLElement = element.querySelector('.outline-wrapper');
  	this.renderer.setStyle(outline, 'left', (this.identationLevel*this.identationPx).toString() + 'px');     	
  }

  removeIdentation() {
  	this.identationLevel--;
  	const element: HTMLElement = this.target.nativeElement;
    const outline: HTMLElement = element.querySelector('.outline-wrapper');
  	this.renderer.setStyle(outline, 'left', (this.identationLevel*this.identationPx).toString() + 'px');     	
  }
}
