<div class="dialog">
  <div class="header">
  	<div>New trial users with signup source...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">

      <ng-select 
      class="custom" 
      style="left: 50px; width: 170px;"
      [items]="operators" 
      bindLabel="name" 
      bindValue="id" 
      placeholder="{{ placeholderOperator }}" 
      [formControl]="operator">
    </ng-select>

    <!-- visible only if contains or start with' -->
    <label *ngIf="operator.value != 'equal'"
        style="position: absolute; width: 150px; height: 24px; left: 230px;top: 0px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      	  <input type="text" maxlength="5096" 
      	    [formControl]="textValue" placeholder="Enter a value" 
      	    style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;"> 
      	</span>
      </label>
      
      <ng-select *ngIf="operator.value == 'equal'"
      class="custom" 
      style="position: absolute; left: 230px; width: 250px; top: 0px;"
      [items]="signupSources" 
      bindLabel="name" 
      bindValue="id" 
      placeholder="{{ Placeholder }}"
      [formControl]="signupSource" required>
    </ng-select>

    </form>
  </div>
</div>