import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'vmc-map-fields',
  templateUrl: './map-fields.component.html',
  styleUrls: ['./map-fields.component.scss']
})
export class MapFieldsComponent implements OnInit {

  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {

  }

}
