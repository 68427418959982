<div class="dialog">
  <div class="header">
      <div>Users role...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">
      <ng-select class="custom" style="left: 50px; width: 325px;"
          [items]="roles" bindLabel="name" bindValue="id" placeholder="{{ placeholder }}"
          [formControl]="role" required>
      </ng-select>
    </form>
  </div>
</div>
