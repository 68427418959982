import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecisionComponent } from './decision.component';
import { CanvasModule } from '../canvas/canvas.module';
import { DecisionRoutingModule } from './decision-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
  	DecisionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DecisionRoutingModule,
    CanvasModule
  ]
})
export class DecisionModule { }
