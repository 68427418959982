import { EventEmitter, Injectable, Inject, Optional, Output, Type } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { CanvasConfig, CANVAS_CONFIG } from './canvas.interface';
import { PanelItem } from './panel/panel.model';
import { Criterion } from './filter/filter.model';
import { HasLithiumRankComponent } from './filter/criterion/has-lithium-rank/has-lithium-rank.component';
import { HasLithiumRoleComponent } from './filter/criterion/has-lithium-role/has-lithium-role.component';
import { HasLithiumCountryComponent } from './filter/criterion/has-lithium-country/has-lithium-country.component';
import { HasLoggedInToLithiumComponent } from './filter/criterion/has-logged-in-to-lithium/has-logged-in-to-lithium.component';
import { HasNotLoggedInToLithiumComponent } from './filter/criterion/has-not-logged-in-to-lithium/has-not-logged-in-to-lithium.component';
import { GaveLithiumKudosComponent } from './filter/criterion/gave-lithium-kudos/gave-lithium-kudos.component';
import { ReceivedLithiumKudosComponent } from './filter/criterion/received-lithium-kudos/received-lithium-kudos.component';
import { PublishedLithiumContentComponent } from './filter/criterion/published-lithium-content/published-lithium-content.component';
import { RespondedToLithiumSurveyComponent } from './filter/criterion/responded-to-lithium-survey/responded-to-lithium-survey.component';
import { SearchedLithiumComponent } from './filter/criterion/searched-lithium/searched-lithium.component';

import { EcDkActiveCompanyUsersComponent } from './filter/criterion/ec-dk-active-company-users/ec-dk-active-company-users.component';
import { EcDkExcludeActiveCompanyUsersComponent } from './filter/criterion/ec-dk-exclude-active-company-users/ec-dk-exclude-active-company-users.component';
import { EcDkActiveAOUsersComponent } from './filter/criterion/ec-dk-active-ao-users/ec-dk-active-ao-users.component';
import { EcDkExcludeActiveAOUsersComponent } from './filter/criterion/ec-dk-exclude-active-ao-users/ec-dk-exclude-active-ao-users.component';
import { EcDkActiveTrialUsersComponent } from './filter/criterion/ec-dk-active-trial-users/ec-dk-active-trial-users.component';
import { EcDkActiveAddonsComponent } from './filter/criterion/ec-dk-active-addons/ec-dk-active-addons.component';
import { EcDkNotAddonsComponent } from './filter/criterion/ec-dk-not-addons/ec-dk-not-addons.component';
import { EcDkPreviousAddonsComponent } from './filter/criterion/ec-dk-previous-addons/ec-dk-previous-addons.component';
import { EcDkExcludeAddonsComponent } from './filter/criterion/ec-dk-exclude-addons/ec-dk-exclude-addons.component';
import { EcDkActiveGroupofCompanyUsersComponent } from './filter/criterion/ec-dk-active-group-of-company-users/ec-dk-active-group-of-company-users.component';
import { EcDkExcludeActiveGroupofCompanyUsersComponent } from './filter/criterion/ec-dk-exclude-active-group-of-company-users/ec-dk-exclude-active-group-of-company-users.component';
import { EcDkIndustryCodesCompanyComponent } from './filter/criterion/ec-dk-industry-codes-company/ec-dk-industry-codes-company.component';
import { EcDkIndustryCodesGoCComponent } from './filter/criterion/ec-dk-industry-codes-goc/ec-dk-industry-codes-goc.component';
import { EcDkSignupDateComponent } from './filter/criterion/ec-dk-signup-date/ec-dk-signup-date.component';
import { EcDkNewSignupDateComponent } from './filter/criterion/ec-dk-new-signup-date/ec-dk-new-signup-date.component';
import { EcDkNewTrialUsersComponent } from './filter/criterion/ec-dk-new-trial-users/ec-dk-new-trial-users.component';
import { EcDkTrialUsersComponent } from './filter/criterion/ec-dk-trial-users/ec-dk-trial-users.component';
import { EcDkNewUsersRoleComponent } from './filter/criterion/ec-dk-new-users-role/ec-dk-new-users-role.component';
import { EcDkUsersRoleComponent } from './filter/criterion/ec-dk-users-role/ec-dk-users-role.component';
import { EcDkNewUserAgreementtypeComponent } from './filter/criterion/ec-dk-new-user-agreementtype/ec-dk-new-user-agreementtype.component';
import { EcDkAgreementtypeComponent } from './filter/criterion/ec-dk-agreementtype/ec-dk-agreementtype.component';
import { EcDkNumberOfEmployeesComponent } from './filter/criterion/ec-dk-number-of-employees/ec-dk-number-of-employees.component';
import { EcDkSegmentCompanyComponent } from './filter/criterion/ec-dk-segment-company/ec-dk-segment-company.component';
import { EcDkSegmentGoCComponent } from './filter/criterion/ec-dk-segment-goc/ec-dk-segment-goc.component';
import { EcDkContactInformationPermissionsComponent } from './filter/criterion/ec-dk-contact-information-permissions/ec-dk-contact-information-permissions.component';
import { EcDkSubscriptionComponent } from './filter/criterion/ec-dk-subscription/ec-dk-subscription.component';
import { EcDkExcludeSubscriptionComponent } from './filter/criterion/ec-dk-exclude-subscription/ec-dk-exclude-subscription.component';
import { EcDkNotSubscriptionComponent } from './filter/criterion/ec-dk-not-subscription/ec-dk-not-subscription.component';
import { EcDkUserLoginsComponent } from './filter/criterion/ec-dk-user-logins/ec-dk-user-logins.component';
import { EcDkUsersPrAgreementComponent } from './filter/criterion/ec-dk-users-pr-agreement/ec-dk-users-pr-agreement.component';
import { EcDkContentServiceTableComponent } from './filter/criterion/ec-dk-content-service-table/ec-dk-content-service-table.component';
import { EcDkAOsWithExternalsComponent } from './filter/criterion/ec-dk-aos-with-externals/ec-dk-aos-with-externals.component';
import { EcDkMobileAppUsedComponent } from './filter/criterion/ec-dk-mobile-app-used/ec-dk-mobile-app-used.component';
import { EcDkTrialUserSignupSourceComponent } from './filter/criterion/ec-dk-trial-user-signup-source/ec-dk-trial-user-signup-source.component';
import { EcDkNewTrialUserSignupSourceComponent } from './filter/criterion/ec-dk-new-trial-user-signup-source/ec-dk-new-trial-user-signup-source.component';
import { EcDkUnlessInAppClickComponent } from './filter/criterion/ec-dk-unless-in-app-click/ec-dk-unless-in-app-click.component';
import { EcDkUnlessInAppDisplayComponent } from './filter/criterion/ec-dk-unless-in-app-display/ec-dk-unless-in-app-display.component';

import { SeRankingUserRegisteredComponent } from './filter/criterion/seranking-user-registered/seranking-user-registered.component';

interface CanvasChangeEvent {
  statement: string,
  criteria: Criterion[]
}

const icons: Map<string, string> = new Map<string, string>([
    ['Lithium', '/assets/community_16px.svg'],
    ['SuperOffice', '/assets/office_16px.svg'],
    ['EduAdmin', 'assets/callendar_16px.svg'],
    ['ODP', 'assets/add_user_16px.svg'],
    ['VLS','assets/license_16px.svg'],
    ['eConomicDK','assets/econ_16px.svg'],
    ['contactInfoDK','assets/contact_info_16px.svg'],
    ['industryDK','assets/virk_16px.svg'],
    ['contentServiceDK','assets/cont_serv_16px.svg'],
    ['firebaseDK','assets/firebase_16px.svg'],
    ['unlessDK','assets/unless_16px.svg'],

]);

const negatedOperators = new Map<string, string>([
    ['notEqual', 'equal'],
    ['doesNotContain', 'contains'],
    ['doesNotStartWith', 'startsWith'],
    ['doesNotEndWith', 'endsWith'],
    ['notBlank', 'blank']
]);


const infoLinkMap = new Map<string, string>([]);

function getMapValue(map, key) {
  return map.get(key) || '';
}

@Injectable({
  providedIn: 'root'
})
export class CanvasService {

  canvasConfig: CanvasConfig;

  criteria: Criterion[];

  panelItems: PanelItem[] = [];

  statement: string;

  stored: EventEmitter<boolean> = new EventEmitter<boolean>();

  change: EventEmitter<CanvasChangeEvent> = new EventEmitter<CanvasChangeEvent>();

  constructor(private authService: AuthService) {
  }

  setPanelConfig(config: CanvasConfig) {
    const defaultDescription = 'Double click to configure...';
    // retrieve access groups from JWT payload ()
    const jwtHeaderPayload = this.authService.getCookie('hpl');
    const jwtPayload = jwtHeaderPayload.substring(jwtHeaderPayload.indexOf('.', 0) + 1);
    const payload = JSON.parse(atob(jwtPayload));
    const groups = payload['groups'] as string[];

    //const groups = ['vit-d-vdl-corporate-developers@visma.com'];
    //const groups = ['eci-d-vdl-economicdk-standard@visma.com'];

    this.canvasConfig = config;

    let accessGroups = [
      'sws-d-vdl-swse-standard@visma.com',
      'swh-d-vdl-swnl-standard@visma.com',
      //'eci-d-vdl-economicdk-standard@visma.com',
      'vit-d-vdl-corporate-developers@visma.com',
      'swr-d-vdl-swnl-eloqua-cloudapps@visma.com',
      'eci-d-vdl-economicdk-eloquacloudapp@visma.com',
      'enn-d-vdl-enno-eloquacloudapp@visma.com',
      'vit-d-vdl-vitcno-marketingautomationteam@visma.com',
      'fsn-d-vdl-financialsolutionsno-eloquacloudapp@visma.com',
      'swf-d-vdl-swfi-eloquacloudapp@visma.com',
      'bld-d-vdl-endk-eloquacloudapp@visma.com'
    ];
    if (accessGroups.some(group => groups.includes(group))) {
      if (config.enable_lithium_rank_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'HasLithiumRankCriterion');
        this.panelItems.push(new PanelItem('HasLithiumRankCriterion', 'Has Community Rank', defaultDescription, HasLithiumRankComponent, icons.get('Lithium'), infoLink));
      }

      if (config.enable_lithium_role_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'HasLithiumRoleCriterion');
        this.panelItems.push(new PanelItem('HasLithiumRoleCriterion', 'Has Community Role', defaultDescription, HasLithiumRoleComponent, icons.get('Lithium'), infoLink));
      }

      if (config.enable_lithium_norole_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'HasNoLithiumRoleCriterion');
        const description = 'Contacts having no roles in the Community';
        this.panelItems.push(new PanelItem('HasNoLithiumRoleCriterion', 'Has No Community Role', description, null, icons.get('Lithium'), infoLink));
      }

      if (config.enable_lithium_country_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'HasLithiumCountryCriterion');
        this.panelItems.push(new PanelItem('HasLithiumCountryCriterion', 'Has Community Country', defaultDescription, HasLithiumCountryComponent, icons.get('Lithium'), infoLink))
      }

      if (config.enable_lithium_login_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'HasLoggedInToLithiumCriterion');
        this.panelItems.push(new PanelItem('HasLoggedInToLithiumCriterion', 'Logged into Community', defaultDescription, HasLoggedInToLithiumComponent, icons.get('Lithium'), infoLink));
      }

      if (config.enable_lithium_not_login_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'HasNotLoggedInToLithiumCriterion');
        this.panelItems.push(new PanelItem('HasNotLoggedInToLithiumCriterion', 'Not logged into Community', defaultDescription, HasNotLoggedInToLithiumComponent, icons.get('Lithium'), infoLink));
      }

      if (config.enable_gave_lithium_kudos_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'GaveLithiumKudosCriterion');
        this.panelItems.push(new PanelItem('GaveLithiumKudosCriterion', 'Gave Kudos', defaultDescription, GaveLithiumKudosComponent, icons.get('Lithium'), infoLink));
      }

      if (config.enable_received_lithium_kudos_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'ReceivedLithiumKudosCriterion')
        this.panelItems.push(new PanelItem('ReceivedLithiumKudosCriterion', 'Received Kudos', defaultDescription, ReceivedLithiumKudosComponent, icons.get('Lithium'), infoLink));
      }

      if (config.enable_published_lithium_content_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'PublishedLithiumContentCriterion');
        this.panelItems.push(new PanelItem('PublishedLithiumContentCriterion', 'Published Lithium Content', defaultDescription, PublishedLithiumContentComponent, icons.get('Lithium')));
      }

      if (config.enable_responded_to_lithium_survey_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'RespondedToLithiumSurveyCriterion');
        this.panelItems.push(new PanelItem('RespondedToLithiumSurveyCriterion', 'Responded To Lithium Survey', defaultDescription, RespondedToLithiumSurveyComponent,icons.get('Lithium'), infoLink));
      }

      if (config.enable_searched_lithium_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'SearchedLithiumCriterion');
        this.panelItems.push(new PanelItem('SearchedLithiumCriterion', 'Searched Lithium', defaultDescription, SearchedLithiumComponent,icons.get('Lithium'), infoLink));
      }

    }

    accessGroups = [
      'vit-d-vdl-corporate-developers@visma.com',
      'vit-d-vdl-vitcno-marketingautomationteam@visma.com',
      'vit-d-vdl-in-ic-standard@visma.com'
    ];
    if (accessGroups.some(group => groups.includes(group))) {
      if (config.enable_seranking_user_registered_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'SeRankingUserRegisteredCriterion');
        this.panelItems.push(new PanelItem('SeRankingUserRegisteredCriterion', 'SE Ranking User Registered', defaultDescription, SeRankingUserRegisteredComponent, icons.get('ODP'), infoLink));
      }	
    }

    accessGroups = [
      'eci-d-vdl-economicdk-standard@visma.com',
      'eci-d-vdl-economicdk-eloquacloudapp@visma.com',
      'vit-d-vdl-corporate-developers@visma.com',
      'vit-d-vdl-vitcno-marketingautomationteam@visma.com'
    ];
    if (accessGroups.some(group => groups.includes(group))) {
      if (config.enable_ec_dk_active_company_users_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkActiveCompanyUsersCriterion');
        this.panelItems.push(new PanelItem('EcDkActiveCompanyUsersCriterion', '(EcDK) Active Company Users', defaultDescription, EcDkActiveCompanyUsersComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_active_ao_users_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkActiveAOUsersCriterion');
        this.panelItems.push(new PanelItem('EcDkActiveAOUsersCriterion', '(EcDK) Active AO Users', defaultDescription, EcDkActiveAOUsersComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_active_trial_users_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkActiveTrialUsersCriterion');
        this.panelItems.push(new PanelItem('EcDkActiveTrialUsersCriterion', '(EcDK) Active Trial Users', defaultDescription, EcDkActiveTrialUsersComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_active_group_of_company_users_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkActiveGroupofCompanyUsersCriterion');
        this.panelItems.push(new PanelItem('EcDkActiveGroupofCompanyUsersCriterion', '(EcDK) Active Group of Company Users', defaultDescription, EcDkActiveGroupofCompanyUsersComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_active_addons_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkActiveAddonsCriterion');
        this.panelItems.push(new PanelItem('EcDkActiveAddonsCriterion', '(EcDK) Active Addons', defaultDescription, EcDkActiveAddonsComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_previous_addons_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkPreviousAddonsCriterion');
        this.panelItems.push(new PanelItem('EcDkPreviousAddonsCriterion', '(EcDK) Previous Addons', defaultDescription, EcDkPreviousAddonsComponent, icons.get('eConomicDK'), infoLink))
      }
   //   if (config.enable_ec_dk_exclude_addons_criterion) {
   //     const infoLink = getMapValue(infoLinkMap, 'EcDkExcludeAddonsCriterion');
   //     this.panelItems.push(new PanelItem('EcDkExcludeAddonsCriterion', '(EcDK) Exclude Addons', defaultDescription, EcDkExcludeAddonsComponent, icons.get('eConomicDK'), infoLink))
   //   }
      if (config.enable_ec_dk_subscription_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkSubscriptionCriterion');
        this.panelItems.push(new PanelItem('EcDkSubscriptionCriterion', '(EcDK) Subscription', defaultDescription, EcDkSubscriptionComponent, icons.get('eConomicDK'), infoLink))
      }
      //if (config.enable_ec_dk_exclude_subscription_criterion) {
      //  const infoLink = getMapValue(infoLinkMap, 'EcDkExcludeSubscriptionCriterion');
      //  this.panelItems.push(new PanelItem('EcDkExcludeSubscriptionCriterion', '(EcDK) Exclude Subscription', defaultDescription, EcDkExcludeSubscriptionComponent, icons.get('eConomicDK'), infoLink))
      //}
      if (config.enable_ec_dk_user_logins_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkUserLoginsCriterion');
        this.panelItems.push(new PanelItem('EcDkUserLoginsCriterion', '(EcDK) User Logins', defaultDescription, EcDkUserLoginsComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_users_pr_agreement_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkUsersPrAgreementCriterion');
        this.panelItems.push(new PanelItem('EcDkUsersPrAgreementCriterion', '(EcDK) Users pr Agreement', defaultDescription, EcDkUsersPrAgreementComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_industry_codes_company_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkIndustryCodesCompanyCriterion');
        this.panelItems.push(new PanelItem('EcDkIndustryCodesCompanyCriterion', '(EcDK) Industry Codes - BOs', defaultDescription, EcDkIndustryCodesCompanyComponent, icons.get('industryDK'), infoLink))
      }
      //if (config.enable_ec_dk_industry_codes_goc_criterion) {
      //  const infoLink = getMapValue(infoLinkMap, 'EcDkIndustryCodesGoCCriterion');
      //  this.panelItems.push(new PanelItem('EcDkIndustryCodesGoCCriterion', '(EcDK) Industry Codes - Active Group of Company Users', defaultDescription, EcDkIndustryCodesGoCComponent, icons.get('industryDK'), infoLink))
      //}
      if (config.enable_ec_dk_segment_company_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkSegmentCompanyCriterion');
        this.panelItems.push(new PanelItem('EcDkSegmentCompanyCriterion', '(EcDK) Segment - BOs', defaultDescription, EcDkSegmentCompanyComponent, icons.get('industryDK'), infoLink))
      }
      //if (config.enable_ec_dk_segment_goc_criterion) {
      //  const infoLink = getMapValue(infoLinkMap, 'EcDkSegmentGoCCriterion');
      //  this.panelItems.push(new PanelItem('EcDkSegmentGoCCriterion', '(EcDK) Segment - Active Group of Company Users', defaultDescription, EcDkSegmentGoCComponent, icons.get('industryDK'), infoLink))
      //}
      if (config.enable_ec_dk_number_of_employees_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkNumberOfEmployeesCriterion');
        this.panelItems.push(new PanelItem('EcDkNumberOfEmployeesCriterion', '(EcDK) Number Of Employees', defaultDescription, EcDkNumberOfEmployeesComponent, icons.get('industryDK'), infoLink))
      }
      if (config.enable_ec_dk_content_service_table_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkContentServiceTableCriterion');
        this.panelItems.push(new PanelItem('EcDkContentServiceTableCriterion', '(EcDK) Contacts in Content Service Tables', defaultDescription, EcDkContentServiceTableComponent, icons.get('contentServiceDK'), infoLink))
      }
      if (config.enable_ec_dk_unless_in_app_click_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkUnlessInAppClickCriterion');
        this.panelItems.push(new PanelItem('EcDkUnlessInAppClickCriterion', '(EcDK) Unless In App Click', defaultDescription, EcDkUnlessInAppClickComponent, icons.get('unlessDK'), infoLink))
      }
      if (config.enable_ec_dk_unless_in_app_display_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkUnlessInAppDisplayCriterion');
        this.panelItems.push(new PanelItem('EcDkUnlessInAppDisplayCriterion', '(EcDK) Unless In App Display', defaultDescription, EcDkUnlessInAppDisplayComponent, icons.get('unlessDK'), infoLink))
      }
      
      /*
      if (config.enable_ec_dk_exclude_active_company_users_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkExcludeActiveCompanyUsersCriterion');
        this.panelItems.push(new PanelItem('EcDkExcludeActiveCompanyUsersCriterion', '(EcDK) Exclude Active Company Users', defaultDescription, EcDkExcludeActiveCompanyUsersComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_exclude_active_ao_users_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkExcludeActiveAOUsersCriterion');
        this.panelItems.push(new PanelItem('EcDkExcludeActiveAOUsersCriterion', '(EcDK) Exclude Active AO Users', defaultDescription, EcDkExcludeActiveAOUsersComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_not_addons_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkNotAddonsCriterion');
        this.panelItems.push(new PanelItem('EcDkNotAddonsCriterion', '(EcDK) Not Addons', defaultDescription, EcDkNotAddonsComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_exclude_active_group_of_company_users_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkExcludeActiveGroupofCompanyUsersCriterion');
        this.panelItems.push(new PanelItem('EcDkExcludeActiveGroupofCompanyUsersCriterion', '(EcDK) Exclude Active Group of Company Users', defaultDescription, EcDkExcludeActiveGroupofCompanyUsersComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_signup_date_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkSignupDateCriterion');
        this.panelItems.push(new PanelItem('EcDkSignupDateCriterion', '(EcDK) Users Signup Date', defaultDescription, EcDkSignupDateComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_contact_information_permissions_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkContactInformationPermissionsCriterion');
        this.panelItems.push(new PanelItem('EcDkContactInformationPermissionsCriterion', '(EcDK) Permissions', defaultDescription, EcDkContactInformationPermissionsComponent, icons.get('contactInfoDK'), infoLink))
      }
      //if (config.enable_ec_dk_new_signup_date_criterion) {
      //  const infoLink = getMapValue(infoLinkMap, 'EcDkNewSignupDateCriterion');
      //  this.panelItems.push(new PanelItem('EcDkNewSignupDateCriterion', '(EcDK) New Users Signup Date', defaultDescription, EcDkNewSignupDateComponent, icons.get('eConomicDK'), infoLink))
      //}
      //if (config.enable_ec_dk_new_trial_users_criterion) {
      //  const infoLink = getMapValue(infoLinkMap, 'EcDkNewTrialUsersCriterion');
      //  this.panelItems.push(new PanelItem('EcDkNewTrialUsersCriterion', '(EcDK) New Trial Users', defaultDescription, EcDkNewTrialUsersComponent, icons.get('eConomicDK'), infoLink))
      //}
      if (config.enable_ec_dk_trial_users_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkTrialUsersCriterion');
        this.panelItems.push(new PanelItem('EcDkTrialUsersCriterion', '(EcDK) Trial Users', defaultDescription, EcDkTrialUsersComponent, icons.get('eConomicDK'), infoLink))
      }
      //if (config.enable_ec_dk_new_users_role_criterion) {
      //  const infoLink = getMapValue(infoLinkMap, 'EcDkNewUsersRoleCriterion');
      //  this.panelItems.push(new PanelItem('EcDkNewUsersRoleCriterion', '(EcDK) New Users Role', defaultDescription, EcDkNewUsersRoleComponent, icons.get('eConomicDK'), infoLink))
      //}
      if (config.enable_ec_dk_users_role_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkUsersRoleCriterion');
        this.panelItems.push(new PanelItem('EcDkUsersRoleCriterion', '(EcDK) Users Role', defaultDescription, EcDkUsersRoleComponent, icons.get('eConomicDK'), infoLink))
      }
      //if (config.enable_ec_dk_new_user_agreementtype_criterion) {
      //  const infoLink = getMapValue(infoLinkMap, 'EcDkNewUserAgreementtypeCriterion');
      //  this.panelItems.push(new PanelItem('EcDkNewUserAgreementtypeCriterion', '(EcDK) New User Agreement Type', defaultDescription, EcDkNewUserAgreementtypeComponent, icons.get('eConomicDK'), infoLink))
      //}
      if (config.enable_ec_dk_agreementtype_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkAgreementtypeCriterion');
        this.panelItems.push(new PanelItem('EcDkAgreementtypeCriterion', '(EcDK) Agreement Type', defaultDescription, EcDkAgreementtypeComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_not_subscription_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkNotSubscriptionCriterion');
        this.panelItems.push(new PanelItem('EcDkNotSubscriptionCriterion', '(EcDK) Not Subscription', defaultDescription, EcDkNotSubscriptionComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_aos_with_externals_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkAOsWithExternalsCriterion');
        this.panelItems.push(new PanelItem('EcDkAOsWithExternalsCriterion', '(EcDK) AOs With Externals', defaultDescription, EcDkAOsWithExternalsComponent, icons.get('eConomicDK'), infoLink))
      }
      if (config.enable_ec_dk_mobile_app_used_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkMobileAppUsedCriterion');
        this.panelItems.push(new PanelItem('EcDkMobileAppUsedCriterion', '(EcDK) Mobile App Used', defaultDescription, EcDkMobileAppUsedComponent, icons.get('firebaseDK'), infoLink))
      }
      if (config.enable_ec_dk_trial_user_signup_source_criterion) {
        const infoLink = getMapValue(infoLinkMap, 'EcDkTrialUserSignupSourceCriterion');
        this.panelItems.push(new PanelItem('EcDkTrialUserSignupSourceCriterion', '(EcDK) Trial User Signup Source', defaultDescription, EcDkTrialUserSignupSourceComponent, icons.get('eConomicDK'), infoLink))
      }
      //if (config.enable_ec_dk_new_trial_user_signup_source_criterion) {
      //  const infoLink = getMapValue(infoLinkMap, 'EcDkNewTrialUserSignupSourceCriterion');
      //  this.panelItems.push(new PanelItem('EcDkNewTrialUserSignupSourceCriterion', '(EcDK) New Trial User Signup Source', defaultDescription, EcDkNewTrialUserSignupSourceComponent, icons.get('eConomicDK'), infoLink))
     // }
      */
    }
  }

  getPanelItems() {
    return this.panelItems;
    /*
    return this.panelItems.filter(item =>
      item.accessGroups.some(group => accessGroups.includes(group)));*/
  }

  getPanelItem(index: number) {
    return this.panelItems[index];
  }

  getCriteria() {
  	return this.criteria;
  }


  getStatement() {
    return this.statement;
  }

  storeCriteria(criteria: any[]) {
    this.criteria = criteria.map(criterion => this.adapt(criterion));
    this.stored.emit(true);
  }

  setStatement(statement: string) {
    this.statement = statement;
    // statement is set on add and remove
    this.change.emit({
      statement: this.statement,
      criteria: this.criteria
    });
  }

  addCriterion(criterion: Criterion) {
    this.criteria.push(criterion);
    //this.change.emit(this.criteria);
  }

  removeCriterion(initialId: number) {
    const index = this.criteria
      .findIndex(criterion => criterion.initialId == initialId);

    this.criteria.splice(index, 1);
    //this.change.emit(this.criteria);
  }

  updateCriterion(initialId: number, criterion: Criterion) {
    if (initialId == criterion.initialId) {
      const index = this.criteria
        .findIndex(criterion => criterion.initialId == initialId);
      const id = this.criteria[index].id;
      this.criteria[index] = criterion;
      this.criteria[index].id = id;
    }
    this.change.emit({
      statement: this.statement,
      criteria: this.criteria
    });
    //this.change.emit(this.criteria);
  }

  adapt(item: any): Criterion {
  	let dialogComponentType: Type<any>;
    let imageUrl: string;
  	let data: any = { };
  	switch (item.type) {
  		case "HasLithiumRankCriterion":
			  dialogComponentType = HasLithiumRankComponent;
        imageUrl = icons.get('Lithium').replace('16', '32');
			  data['value'] = {
          rank: item.condition.value,
          negated: item.condition.operator.startsWith('not') ? true : false,
        }
  	break;
      case "HasLithiumRoleCriterion":
			  dialogComponentType = HasLithiumRoleComponent;
        imageUrl = icons.get('Lithium').replace('16', '32');
      	//data['value'] = item.roleId;
        data['value'] = {
          roleIds: item.condition.roleIds
        }
  	break;
      case "HasLoggedInToLithiumCriterion":
			  dialogComponentType = HasLoggedInToLithiumComponent;
        imageUrl = icons.get('Lithium').replace('16', '32');
        data['value'] = {
          operator: item.activityRestriction.operator,
          periodType: item.timeRestriction.operator,
          relativePeriodType: item.timeRestriction.period,
          startCount: item.activityRestriction.start == undefined ? item.activityRestriction.value : item.activityRestriction.start,
          endCount: item.activityRestriction.end,
          startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
          endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
          offset: item.timeRestriction.offset
        }
  	break;
      case "HasNotLoggedInToLithiumCriterion":
        dialogComponentType = HasNotLoggedInToLithiumComponent;
        imageUrl = icons.get('Lithium').replace('16', '32');
        data['value'] = {
          periodType: item.timeRestriction.operator,
          relativePeriodType: item.timeRestriction.period,
          offset: item.timeRestriction.offset,
          startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
          endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end
        }
        break;
      case "SeRankingUserRegisteredCriterion":
        dialogComponentType = SeRankingUserRegisteredComponent;
        imageUrl = icons.get('ODP').replace('16', '32');
        data['value'] = {
          periodType: item.timeRestriction.operator,
          relativePeriodType: item.timeRestriction.period,
          offset: item.timeRestriction.offset,
          startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
          endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end
        }
        break;
      case "GaveLithiumKudosCriterion":
        dialogComponentType = GaveLithiumKudosComponent;
        imageUrl = icons.get('Lithium').replace('16', '32');
        data['value'] = {
          operator: item.activityRestriction.operator,
          periodType: item.timeRestriction.operator,
          relativePeriodType: item.timeRestriction.period,
          startCount: item.activityRestriction.start == undefined ? item.activityRestriction.value : item.activityRestriction.start,
          endCount: item.activityRestriction.end,
          startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
          endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
          offset: item.timeRestriction.offset
        }
        break;
      case "ReceivedLithiumKudosCriterion":
        dialogComponentType = ReceivedLithiumKudosComponent;
        imageUrl = icons.get('Lithium').replace('16', '32');
        data['value'] = {
          operator: item.activityRestriction.operator,
          periodType: item.timeRestriction.operator,
          relativePeriodType: item.timeRestriction.period,
          startCount: item.activityRestriction.start == undefined ? item.activityRestriction.value : item.activityRestriction.start,
          endCount: item.activityRestriction.end,
          startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
          endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
          offset: item.timeRestriction.offset
        }
        break;
      case "EcDkActiveCompanyUsersCriterion":
          dialogComponentType = EcDkActiveCompanyUsersComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
            rank: item.condition.value,
          }
          break;
      case "EcDkExcludeActiveCompanyUsersCriterion":
            dialogComponentType = EcDkExcludeActiveCompanyUsersComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
              role: item.condition.value,
            }
          break;
      case "EcDkActiveAOUsersCriterion":
          dialogComponentType = EcDkActiveAOUsersComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          role: item.condition.value,
          }
        break;
      case "EcDkExcludeActiveAOUsersCriterion":
            dialogComponentType = EcDkExcludeActiveAOUsersComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
              role: item.condition.value,
            }
          break;
      case "EcDkActiveTrialUsersCriterion":
            dialogComponentType = EcDkActiveTrialUsersComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
              role: item.condition.value,
              periodType: item.timeRestriction.operator,
              relativePeriodType: item.timeRestriction.period,
              startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
              endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
              offset: item.timeRestriction.offset
            }
          break;
      case "EcDkActiveAddonsCriterion":
            dialogComponentType = EcDkActiveAddonsComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
              addon: item.condition.value,
              periodType: item.timeRestriction.operator,
              relativePeriodType: item.timeRestriction.period,
              startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
              endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
              offset: item.timeRestriction.offset
            }
            break;
      case "EcDkNotAddonsCriterion":
          dialogComponentType = EcDkNotAddonsComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          addon: item.condition.value,
          }
            break;
      case "EcDkPreviousAddonsCriterion":
            dialogComponentType = EcDkPreviousAddonsComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
              addon: item.condition.value,
              periodType: item.timeRestriction.operator,
              relativePeriodType: item.timeRestriction.period,
              startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
              endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
              offset: item.timeRestriction.offset
            }
            break;
      case "EcDkExcludeAddonsCriterion":
          dialogComponentType = EcDkExcludeAddonsComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          addon: item.condition.value,
          }
            break;
      case "EcDkActiveGroupofCompanyUsersCriterion":
          dialogComponentType = EcDkActiveGroupofCompanyUsersComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          role: item.condition.value,
          }
            break;
      case "EcDkExcludeActiveGroupofCompanyUsersCriterion":
          dialogComponentType = EcDkExcludeActiveGroupofCompanyUsersComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          role: item.condition.value,
          }
            break;
      case "EcDkIndustryCodesCompanyCriterion":
          dialogComponentType = EcDkIndustryCodesCompanyComponent;

          imageUrl = icons.get('industryDK').replace('16', '32');
          data['value'] = {

          operator: item.activityRestriction.operator.startsWith('not') || item.activityRestriction.operator.startsWith('doesNot') ? negatedOperators.get(item.activityRestriction.operator) : item.activityRestriction.operator,
          negated: item.activityRestriction.operator.startsWith('not') || item.activityRestriction.operator.startsWith('doesNot') ? true : false,
          startCount: item.activityRestriction.start == undefined ? item.activityRestriction.value : item.activityRestriction.start,
          endCount: item.activityRestriction.end,
        }
            break;

      case "EcDkIndustryCodesGoCCriterion":
          dialogComponentType = EcDkIndustryCodesGoCComponent;
    
          imageUrl = icons.get('industryDK').replace('16', '32');
          data['value'] = {
    
          operator: item.activityRestriction.operator.startsWith('not') || item.activityRestriction.operator.startsWith('doesNot') ? negatedOperators.get(item.activityRestriction.operator) : item.activityRestriction.operator,
          negated: item.activityRestriction.operator.startsWith('not') || item.activityRestriction.operator.startsWith('doesNot') ? true : false,
          startCount: item.activityRestriction.start == undefined ? item.activityRestriction.value : item.activityRestriction.start,
          endCount: item.activityRestriction.end,
        }
            break;

      case "EcDkSignupDateCriterion":
          dialogComponentType = EcDkSignupDateComponent;

          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
            periodType: item.timeRestriction.operator.startsWith('not') ? negatedOperators.get(item.timeRestriction.operator) : item.timeRestriction.operator,
            negated: item.timeRestriction.operator.startsWith('not') ? true : false,
            //periodType: item.timeRestriction.operator,
            relativePeriodType: item.timeRestriction.period,
            startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
            endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
            offset: item.timeRestriction.offset
              }
              break;

      case "EcDkNewSignupDateCriterion":
            dialogComponentType = EcDkNewSignupDateComponent;

            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
              periodType: item.timeRestriction.operator,
              relativePeriodType: item.timeRestriction.period,
              offset: item.timeRestriction.offset
                }
            break;
      case "EcDkNewTrialUsersCriterion":
              dialogComponentType = EcDkNewTrialUsersComponent;
              imageUrl = icons.get('eConomicDK').replace('16', '32');
              data['value'] = {
                role: item.condition.value,
                periodType: item.timeRestriction.operator,
                relativePeriodType: item.timeRestriction.period,
                startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
                endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
                offset: item.timeRestriction.offset
              }
            break;
      case "EcDkTrialUsersCriterion":
              dialogComponentType = EcDkTrialUsersComponent;
              imageUrl = icons.get('eConomicDK').replace('16', '32');
              data['value'] = {
                role: item.condition.value,
                periodType: item.timeRestriction.operator,
                relativePeriodType: item.timeRestriction.period,
                startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
                endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
                offset: item.timeRestriction.offset
              }
            break;
      case "EcDkNewUsersRoleCriterion":
            dialogComponentType = EcDkNewUsersRoleComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
            role: item.condition.value,
            }
            break;
      case "EcDkUsersRoleCriterion":
            dialogComponentType = EcDkUsersRoleComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
            role: item.condition.value,
            }
            break;
      case "EcDkNewUserAgreementtypeCriterion":
            dialogComponentType = EcDkNewUserAgreementtypeComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
            agrtype: item.condition.value,
            negated: item.condition.operator.startsWith('not') ? true : false,
            }
            break;
      case "EcDkAgreementtypeCriterion":
            dialogComponentType = EcDkAgreementtypeComponent;
            imageUrl = icons.get('eConomicDK').replace('16', '32');
            data['value'] = {
            agrtype: item.condition.value,
            negated: item.condition.operator.startsWith('not') ? true : false,
            }
            break;

      case "EcDkNumberOfEmployeesCriterion":
          dialogComponentType = EcDkNumberOfEmployeesComponent;
          imageUrl = icons.get('industryDK').replace('16', '32');
          data['value'] = {
          operator: item.activityRestriction.operator.startsWith('not') || item.activityRestriction.operator.startsWith('doesNot') ? negatedOperators.get(item.activityRestriction.operator) : item.activityRestriction.operator,
          negated: item.activityRestriction.operator.startsWith('not') || item.activityRestriction.operator.startsWith('doesNot') ? true : false,
          startCount: item.activityRestriction.start == undefined ? item.activityRestriction.value : item.activityRestriction.start,
          endCount: item.activityRestriction.end,
          }
          break;
      case "EcDkSegmentCompanyCriterion":
          dialogComponentType = EcDkSegmentCompanyComponent;
          imageUrl = icons.get('industryDK').replace('16', '32');
          data['value'] = {
          segment: item.condition.value,
          negated: item.condition.operator.startsWith('not') ? true : false
        }
          break;
      case "EcDkSegmentGoCCriterion":
        dialogComponentType = EcDkSegmentGoCComponent;
        imageUrl = icons.get('industryDK').replace('16', '32');
        data['value'] = {
        segment: item.condition.value,
        negated: item.condition.operator.startsWith('not') ? true : false
      }
          break;
      case "EcDkContactInformationPermissionsCriterion":
          dialogComponentType = EcDkContactInformationPermissionsComponent;
          imageUrl = icons.get('contactInfoDK').replace('16', '32');
          data['value'] = {
          permission: item.condition.value,
        }
          break;
      case "EcDkSubscriptionCriterion":
          dialogComponentType = EcDkSubscriptionComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          subscription: item.condition.value,
          operator: item.condition.operator,
          textValue: item.condition.value
        }
          break;

      case "EcDkExcludeSubscriptionCriterion":
          dialogComponentType = EcDkExcludeSubscriptionComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          subscription: item.condition.value,
          operator: item.condition.operator,
          textValue: item.condition.value
        }
          break;

      case "EcDkNotSubscriptionCriterion":
          dialogComponentType = EcDkNotSubscriptionComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
            subscription: item.condition.value,
            operator: item.condition.operator,
            textValue: item.condition.value
        }
        break;

        case "EcDkUserLoginsCriterion":
          dialogComponentType = EcDkUserLoginsComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
            operator: item.activityRestriction.operator,
            periodType: item.timeRestriction.operator,
            relativePeriodType: item.timeRestriction.period,
            startCount: item.activityRestriction.start == undefined ? item.activityRestriction.value : item.activityRestriction.start,
            startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
            endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
            offset: item.timeRestriction.offset
        }
        break;

        case "EcDkUsersPrAgreementCriterion":
          dialogComponentType = EcDkUsersPrAgreementComponent;
          imageUrl = icons.get('firebaseDK').replace('16', '32');
          data['value'] = {
            textValue: item.condition.value,
            operator: item.activityRestriction.operator,
            startCount: item.activityRestriction.start == undefined ? item.activityRestriction.value : item.activityRestriction.start,
            endCount: item.activityRestriction.end == undefined ? item.activityRestriction.value : item.activityRestriction.end,
        }
        break;

        case "EcDkMobileAppUsedCriterion":
          dialogComponentType = EcDkMobileAppUsedComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
            periodType: item.timeRestriction.operator,
            relativePeriodType: item.timeRestriction.period,
            offset: item.timeRestriction.offset
        }
        break;

      case "EcDkContentServiceTableCriterion":
          dialogComponentType = EcDkContentServiceTableComponent;
          imageUrl = icons.get('contentServiceDK').replace('16', '32');
          data['value'] = {
            cstable: item.condition.value
        }
          break;
      case "EcDkAOsWithExternalsCriterion":
          dialogComponentType = EcDkAOsWithExternalsComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          external: item.condition.value
        }
          break;
      case "EcDkTrialUserSignupSourceCriterion":
          dialogComponentType = EcDkTrialUserSignupSourceComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          signupSource: item.condition.value,
          operator: item.condition.operator,
          textValue: item.condition.value
        }
        break;
      case "EcDkNewTrialUserSignupSourceCriterion":
          dialogComponentType = EcDkNewTrialUserSignupSourceComponent;
          imageUrl = icons.get('eConomicDK').replace('16', '32');
          data['value'] = {
          signupSource: item.condition.value,
          operator: item.condition.operator,
          textValue: item.condition.value
        }
        break;
      
      case "EcDkUnlessInAppClickCriterion":
          dialogComponentType = EcDkUnlessInAppClickComponent;
          imageUrl = icons.get('unlessDK').replace('16', '32');
          data['value'] = {
          click: item.condition.value,
          operator: item.condition.operator,
          textValue: item.condition.value,
          periodType: item.timeRestriction.operator,
          relativePeriodType: item.timeRestriction.period,
          startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
          endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
          offset: item.timeRestriction.offset
        }
        break;

        case "EcDkUnlessInAppDisplayCriterion":
          dialogComponentType = EcDkUnlessInAppDisplayComponent;
          imageUrl = icons.get('unlessDK').replace('16', '32');
          data['value'] = {
          display: item.condition.value,
          operator: item.condition.operator,
          textValue: item.condition.value,
          periodType: item.timeRestriction.operator,
          relativePeriodType: item.timeRestriction.period,
          startDate: item.timeRestriction.start == undefined ? this.formattedDate(item.timeRestriction.value) : this.formattedDate(item.timeRestriction.start),
          endDate: item.timeRestriction.end !== undefined ? this.formattedDate(item.timeRestriction.end) : item.timeRestriction.end,
          offset: item.timeRestriction.offset
        }
        break;

      case "HasLithiumCountryCriterion":
        dialogComponentType = HasLithiumCountryComponent;
        imageUrl = icons.get('Lithium').replace('16', '32');
        data['value'] = {
          textValue: item.condition.value
        };
        break;
      case "HasNoLithiumRoleCriterion":
        dialogComponentType = null;
        imageUrl = icons.get('Lithium').replace('16', '32');
        data['value'] = {};
        break;
      default:
  			break;
    };

    const infoLink: string = getMapValue(infoLinkMap, item.type);
  	return new Criterion(item.id, item.initialId, item.type, item.name, item.description, imageUrl, infoLink, dialogComponentType, data);
  }
  /*
  adaptDateCondition(condition: any) {
    switch (condition.type) {
      case "DateValueCondition":
        // code...
        break;
      case "DateRangeCondition":
        break;
      case "RelativeDateCondition":
        break;
      default:
        // code...
        break;
    }
  }*/

  formattedDate(seconds: number) {
    const tmp = new Date(seconds*1000);
    const dateparts = [
      tmp.getFullYear(),
      ("0" + (tmp.getMonth() + 1)).slice(-2),
      ("0" + tmp.getDate()).slice(-2)
    ];
    return dateparts.join("-");
  }
}
