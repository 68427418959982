<div class="dialog">
  <div class="header">
      <div>AOs with externals...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">

      <ng-select class="custom" style="left: 50px; width: 325px;"
          [items]="externals" 
          bindLabel="name" 
          bindValue="id" 
          placeholder="{{ placeholder }}"
          [formControl]="external" required>
      </ng-select>

    </form>
  </div>
</div>
