<div appContextMenu [contextMenu]="contextMenu" [contextMenuSubject]="index" #target
     dndDropzone
     (dndDrop)="onDrop($event)"
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave($event)"
     class="sc-view basic-criterion-list-item-view">

  <div *ngIf="visible" [ngStyle]="{'left': left + 'px'}" class="criterion-insertion-point"></div>

  <div class="summary" #summary>
    <img [src]="iconUrl" style="position: relative; top: 5px; bottom: 5px; left: 5px; right: 5px;">
    <div style="position: absolute; height: 17px; left:42px; right: 5px; top: 7px; display: inline-block;">
      <span #criterion class="title ellipsis">{{ name }}</span>
      <a *ngIf="infoLink != ''" href="{{ infoLink }}" target="_blank" class="documentation-link">
        <img src="assets/info.svg" alt="info">
      </a>
    </div>
    <label title="{{ description }}" class="description ellipsis">{{ description }}</label>
  </div>
</div>