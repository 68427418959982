<div class="dialog">
  <div class="header">
      <div>Trial Users...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">

      <ng-select 
          class="custom" 
          style="position: absolute; width: 170px;"
          [items]="roles" 
          bindLabel="name" 
          bindValue="id" 
          placeholder="{{ placeholder }}"
          [formControl]="role" required>
      </ng-select>

      <ng-select 
      	class="custom" 
        style="position: absolute; top: 35px; width: 170px;"
      	[items]="periodTypes" 
      	bindLabel="name" 
      	bindValue="id" 
      	placeholder="{{ periodicplaceholder }}" 
      	[formControl]="periodType">
      </ng-select>

      <label *ngIf="periodType.value != 'withinLast'"
        style="position: absolute; width: 120px; height: 24px; top: 35px; left: 180px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="date" maxlength="5096" [formControl]="startDate" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <div *ngIf="periodType.value == 'within'" style="position: absolute; width: 40px; height: 24px; top: 37px; left: 300px; text-align: center;">and</div>

      <label *ngIf="periodType.value == 'within'" 
        style="position: absolute; width: 120px; height: 24px; top: 35px; left: 340px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="date" maxlength="5096" [formControl]="endDate" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <label *ngIf="periodType.value == 'withinLast'"
        style="position: absolute; width: 40px; height: 24px; left: 180px; top: 35px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="text" maxlength="5096" 
      		  vmcOnlyNumber [formControl]="offset" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

       <ng-select *ngIf="periodType.value == 'withinLast'"
       class="custom" 
       style="position: absolute; width: 100px; height: 24px; top: 35px; left: 230px;"
       [items]="relativePeriodTypes" 
       bindLabel="name" 
       bindValue="id"
       [formControl]="relativePeriodType">
     </ng-select>

    </form>
  </div>
</div>
