import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-not-addons',
  templateUrl: './ec-dk-not-addons.component.html',
  styleUrls: ['./ec-dk-not-addons.component.scss']
})
export class EcDkNotAddonsComponent implements OnInit {

  addons: ListItem<number, string>[] = [];

    form: FormGroup;

  addonPlaceholder: string = "Loading...";
  
  data: any;

  private readonly criterionId: number;

  constructor(
    private criterionService: CriterionService,
    private dataService: DataService,
  	private formBuilder: FormBuilder,
  	private dialogConfig: DialogConfig) {

  	this.criterionId = this.dialogConfig.data.id;
  }

  get addon() {
  	return this.form.get('addon');
  }

  ngOnInit() {
    this.data = this.criterionService.getData(this.criterionId);

    this.dataService.getAddons().subscribe(addons => {
    	this.addons = addons;
    	this.addonPlaceholder = 'Select the addon...';
    });

  	this.form =  this.formBuilder.group({
      'addon': this.formBuilder.control(this.data.addon, [Validators.required]),
  	});
   
    let formValue = this.form.value;
    formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription();
    this.criterionService.saveData(this.criterionId, formValue);

  	this.form.valueChanges.subscribe(value => {
      value.invalid = this.form.invalid;
      value.offset = value.offset && value.offset.toString().replace(/^0+(?=.)/, '');
      value.description = this.buildDescription();
  		this.criterionService.saveData(this.criterionId, value);
  	});
  }

 buildDescription() {
  let description: string;

  if (this.form.invalid) {
       description = 'Configuration has validation errors. Double click to configure.';
  } else if (this.addons.length == 0) { 
    description = this.data.description;        
  } else if (this.addon.value) { 
            const name = this.addons.find(addon => addon.id == this.addon.value).name;
            description = `Contacts without the addon \'${name}\'.`
         }  else {
    description = "Double click to configure...";
  }
  return description;
}
}



