<div class="centered">
  <img src="/assets/access_denied_60px.svg">
</div>
<div class="information">
  <p>
    Hold up there... You don't have access to this app:)
    Do you think you should have access? Then send an email to 
    <a class="email" href="mailto:marketing-automation@visma.com">marketing-automation@visma.com</a> with the following information:
  </p>
</div>
<div class="instructions-container">
  <ul class="instructions-list">
    <li>Why you should have access</li>
    <li>The Business Unit your are part of</li>
    <li>What's your favourite flavoured ice cream</li>
  </ul>
</div>