<div class="dialog">
  <div class="header">
      <div>New users agreementtype...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">

      <vmc-checkbox [text]="checkboxText" 
        [initialValue]="negated.value" (check)="onCheckBoxClick($event)"
        style="position: absolute; left: 0px; top: 4px; height: 16px;">
      </vmc-checkbox>
      
      <ng-select class="custom" style="left: 50px; width: 325px;"
          [items]="agrtypes" bindLabel="name" bindValue="id" placeholder="{{ placeholder }}"
          [formControl]="agrtype" required>
      </ng-select>
    </form>
  </div>
</div>
