import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ActionService } from './action.service';
import { IConfiguration } from './action.interface';

@Injectable({
    providedIn: 'root'
})
export class ActionResolver implements Resolve<any> {

    constructor(private actionService: ActionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<IConfiguration> | Promise<IConfiguration> | IConfiguration {
        const instanceId = route.params['id'];
        const campaignId = route.params['campaignId'];
        return this.actionService.getConfiguration(instanceId, campaignId);
    }
}