<div class="dialog">
  <div class="header">
  	<div>Contacts that have mobile app used...</div>
  </div>
  <div class="content">
    
    <form [formGroup]="form" #ngForm="ngForm">
      <ng-select 
      	class="custom" 
      	style="position: absolute; width: 170px;"
      	[items]="periodTypes" 
      	bindLabel="name" 
      	bindValue="id" 
      	placeholder="{{ placeholder }}" 
      	[formControl]="periodType">
      </ng-select>
      
      <!-- visible only if 'within the last' -->
      <label *ngIf="periodType.value == 'withinLast'"
        style="position: absolute; width: 40px; height: 24px; left: 180px; top: 0px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="text" maxlength="5096" 
      		  vmcOnlyNumber [formControl]="offset" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>

      <!--<input *ngIf="periodType.value == 'within the last'" vmcOnlyNumber [formControl]="offset" style="position: absolute; width: 40px; height: 24px; left: 180px; top: 35px;">-->
      <ng-select *ngIf="periodType.value == 'withinLast'"
      	class="custom" 
      	style="position: absolute; width: 100px; height: 24px; top: 0px; left: 230px;"
      	[items]="relativePeriodTypes" 
      	bindLabel="name" 
      	bindValue="id"
      	[formControl]="relativePeriodType">
      </ng-select>
      
    </form>
  </div>
</div>