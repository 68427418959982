// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://eloqua.dev.visma-marketing-cloud.com',
  authUrl: 'https://login.eloqua.com/auth/oauth2/authorize',
  clientId: 'a8fdefbd-78b9-44a2-a1ca-c8fb3a5236c0',
  clientSecret: '1V~1YaE7wWkih0enwYYo3VPDdrOXQCx20w-Tu0bop29XPI5auK7No2oW-Op2Xaw52X5-hfWy5AZe3YVWb2Nodjifp-w11iXbL~KM',
  callbackUrl: 'https://eloqua.dev.visma-marketing-cloud.com/callback'
};
