import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';

@Component({
  selector: 'vmc-seranking-user-registered',
  templateUrl: './seranking-user-registered.component.html',
  styleUrls: ['./seranking-user-registered.component.scss']
})
export class SeRankingUserRegisteredComponent implements OnInit {

  periodTypes = [
  	{ id: 'on', name: 'on' },
  	{ id: 'before', name: 'before' },
  	{ id: 'after', name: 'after' },
  	{ id: 'within', name: 'within' },
  	{ id: 'withinLast', name: 'within the last' }
  ];

  relativePeriodTypes = [
    { id: 'days', name: 'Days' },
    { id: 'weeks', name: 'Weeks' },
    { id: 'months', name: 'Months' }
  ]

  form: FormGroup;
  private readonly criterionId: number;

  constructor(private formBuilder: FormBuilder, private dialogConfig: DialogConfig, private criterionService: CriterionService) {
  	this.criterionId = this.dialogConfig.data.id;
  }

  get periodType() {
  	return this.form.get('periodType');
  }

  get relativePeriodType() {
  	return this.form.get('relativePeriodType');
  }

  get offset() {
  	return this.form.get('offset');
  }

  get startDate() {
  	return this.form.get('startDate');
  }

  get endDate() {
  	return this.form.get('endDate');
  }

  ngOnInit() {
  	const data = this.criterionService.getData(this.criterionId);

  	this.form =  this.formBuilder.group({
  		'periodType': this.formBuilder.control(data.periodType, [Validators.required]),
  		'relativePeriodType': this.formBuilder.control(data.relativePeriodType == undefined ? 'days' : data.relativePeriodType, [Validators.required]),
  		'offset': this.formBuilder.control(data.offset == undefined? 1 : data.offset, [Validators.required]),
  		'startDate': this.formBuilder.control(data.startDate, [Validators.required]),
  		'endDate': this.formBuilder.control(data.endDate, [Validators.required])
  	});

  	if (['on', 'before', 'after'].includes(data.periodType)) {
  		this.startDate.enable({ emitEvent: false });
	  	this.relativePeriodType.disable({ emitEvent: false });
	  	this.offset.disable({ emitEvent: false });
	  	this.endDate.disable({ emitEvent: false });
  	} else if (data.periodType == 'within') {
  		this.startDate.enable({ emitEvent: false });
  		this.endDate.enable({ emitEvent: false });
  		this.relativePeriodType.disable({ emitEvent: false });
  	  this.offset.disable({ emitEvent: false });
  	} else if (data.periodType == 'withinLast') {
  		this.offset.enable({ emitEvent: false });
  		this.relativePeriodType.enable({ emitEvent: false });
  		this.startDate.disable({ emitEvent: false });
  		this.endDate.disable({ emitEvent: false });
  	} else {
  		this.startDate.enable({ emitEvent: false });
	  	this.relativePeriodType.disable({ emitEvent: false });
	  	this.offset.disable({ emitEvent: false });
	  	this.endDate.disable({ emitEvent: false });
  	}

  	let formValue = this.form.value;
  	formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription(formValue);
    this.criterionService.saveData(this.criterionId, formValue);

  	this.form.valueChanges.subscribe(value => {

  		if (['on', 'before', 'after'].includes(value.periodType)) {
  			this.startDate.enable({ emitEvent: false });
		  	this.relativePeriodType.disable({ emitEvent: false });
		  	this.offset.disable({ emitEvent: false });
		  	this.endDate.disable({ emitEvent: false });
  		}

  		if (value.periodType == 'within') {
  			this.startDate.enable({ emitEvent: false });
  			this.endDate.enable({ emitEvent: false });
  			this.relativePeriodType.disable({ emitEvent: false });
		  	this.offset.disable({ emitEvent: false });
  		}

  		if (value.periodType == 'withinLast') {
  			this.offset.enable({ emitEvent: false });
  			this.relativePeriodType.enable({ emitEvent: false });
  			this.startDate.disable({ emitEvent: false });
  			this.endDate.disable({ emitEvent: false });
  		}

  		value.invalid = this.form.invalid;
      value.offset = value.offset && value.offset.toString().replace(/^0+(?=.)/, '');
  		value.description = this.buildDescription(value);
  		this.criterionService.saveData(this.criterionId, value);
  	});
  }

  buildDescription(value: any) {
  	let timeRestriction: string;
  	let description: string;

  	if (this.form.invalid) { // is invalid
  		description = 'Configuration has validation errors. Double click to configure.';
  	} else if (value.periodType == 'within' && value.startDate && value.endDate) {
  		const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == value.periodType);
  		timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate} and ${value.endDate}`;
  	} else if (value.periodType == 'within' && value.startDate && value.endDate) {
  		const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == value.periodType);
  		timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate} and ${value.endDate}`;
  	} else if (value.periodType == 'withinLast' && value.offset && value.relativePeriodType) {
  		const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == value.periodType);
  		timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.offset} ${value.relativePeriodType}`;
  	} else if (['on', 'before', 'after'].includes(value.periodType) && value.startDate) {
  		const periodTypeIndex = this.periodTypes.findIndex(periodType => periodType.id == value.periodType);
  		timeRestriction = `${this.periodTypes[periodTypeIndex].name} ${value.startDate}`;
  	}

  	if (this.form.valid && timeRestriction) {
  		description = `Users who were registered to SE Ranking ${timeRestriction}`;
  	}
  	console.log(description)
  	return description;
  }
}
