<vmc-header></vmc-header>
<!-- save button, field mappings button, calculation button -->
<div class="sc-view menubar-container" style="overflow: visible;">

  <vmc-button class="sc-view" [disable]="calculateButtonDisabled" (execute)="onCalculate($event)" style="left: 5px; top: 6px; height: 24px; width: 38px;">
    <ng-template #content>
    	<img src="/assets/refresh_18px.svg" style="position: absolute; top: 3px; left: 10px;">
    </ng-template>
  </vmc-button>  

  <div class="sc-view" style="left: 50px; right: 0px; top: 10px; bottom: 0px; width: 200px;">
    <p class="count">{{ calcText }}</p>
  </div>

  <!--
  <ng-select 
  	class="custom" 
  	style="position: absolute; width: 300px; left: 259px; top: 50%; margin-top: -12px"
  	[items]="bunits" 
  	bindLabel="name" 
  	bindValue="id" 
  	placeholder="Select a business unit">
  </ng-select> 	
  -->

  <!--
  <vmc-button class="sc-view" [disable]="saveButtonDisabled" (execute)="onSave($event)" style="right: 175px; width: 100px; top: 50%; height: 24px; margin-top: -12px">
    <ng-template #content>View Contacts</ng-template>
  </vmc-button>
  -->
  
  <!--
  <vmc-button class="sc-view" [disable]="saveButtonDisabled" (execute)="onSave($event)" style="right: 120px; width: 45px; top: 50%; height: 24px; margin-top: -12px">
    <ng-template #content>Save</ng-template>
  </vmc-button>
  -->

  <vmc-button class="sc-view" [disable]="saveButtonDisabled" (execute)="onSave($event)" style="right: 7px; width: 45px; top: 50%; height: 24px; margin-top: -12px">
    <ng-template #content>Save</ng-template>
  </vmc-button>

  <!--
  <vmc-button class="sc-view" [disable]="saveButtonDisabled" (execute)="onSave($event)" style="right: 14px; width: 100px; top: 50%; height: 24px; margin-top: -12px">
    <ng-template #content>Processing</ng-template>
  </vmc-button> 
  -->

</div>
<!--<vmc-processing></vmc-processing>-->
<vmc-canvas [panelConfig]="panelConfig" [filterConfig]="filterConfig" (cnvchange)="transform($event)"></vmc-canvas>