import { Type } from '@angular/core';

/*
export class Filter {
}
*/

export class Criterion {
	constructor(public id: number, public initialId: number, 
		public type: string, public name: string, public description: string, public imageUrl: string, 
		public infoLink: string, public dialogComponentType: Type<any>, public data?: any, public identationLevel?: number) { }
}

export class Operator {
	constructor(public name: string) { }
}

export class OperatorConfig {
	constructor(public index: number, public operators: Operator[], public identationLevel: number, public value?: string) { } 
}

export class ParenthesesConfig {
	constructor(public index: number, public value: string, public identationLevel: number) { }
}