import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  isLoggedIn = false;

  // store the URL so we can redirect after logging in

  constructor(private http: HttpClient) { }

  get redirectUrl() {
  	return localStorage.getItem('redirectUrl');
  }

  redirect(): void {
    let queryParams: Array<String> = [
      'response_type=code',
      `client_id=${environment.clientId}`,
      `redirect_uri=${environment.callbackUrl}`,
      'scope=full',
      `state=${this.redirectUrl}`
    ]
    let queryString = '?' + queryParams.join('&')
    window.location.href = environment.authUrl + queryString;
  }

  login(code: string): Observable<HttpResponse<Object>> {
  	const body = {
  		'code': code
  	};
  	return this.http.post<HttpResponse<Object>>('/auth/token', body, { observe: 'response' })
  }

  logout(): void {
    this.isLoggedIn = false;
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

}