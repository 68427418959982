import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionService } from './action.service';

import {
    IActivity,
    IActivityType,
    IConfiguration,
    IEloquaField,
    IFieldMapping,
    ISuperOfficeField,
    ConfigurationStepTitles,
    EloquaFieldType,
    ISaveConfiguration,
    ICdo,
    EloquaFieldName,
    SuperOfficeFieldName
} from './action.interface';

@Component({
    selector: 'vmc-action',
    templateUrl: './action.component.html',
    styleUrls: [
        '../../../node_modules/@clr/icons/clr-icons.min.css',
        '../../../node_modules/@clr/ui/clr-ui.min.css',
        './action.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class ActionComponent implements OnInit {

    activity: IActivity = {
        id: null,
        description: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        typeId: 1
    };
    activityTypes: IActivityType;
    action: IConfiguration;
    cdos: Array<ICdo>;
    eloquaFields: Array<IEloquaField>;
    errorMessage: string;
    fieldMappings: Array<IFieldMapping> = [];
    instanceId: string;
    superOfficeFields: Array<ISuperOfficeField>;

    contactFields: Array<IFieldMapping> = [];
    companyFields: Array<IFieldMapping> = [];
    cdoContactFields: Array<IFieldMapping> = [];
    cdoCompanyFields: Array<IFieldMapping> = [];

    contactEloquaFields: Array<IEloquaField> = [];
    companyEloquaFields: Array<IEloquaField> = [];

    cdoContactEloquaFields: Array<IEloquaField> = [];
    cdoCompanyEloquaFields: Array<IEloquaField> = [];
    filteredCdoContactEloquaFields: Array<IEloquaField> = [];
    filteredCdoCompanyEloquaFields: Array<IEloquaField> = [];

    contactSuperOfficeFields: Array<ISuperOfficeField> = [];
    companySuperOfficeFields: Array<ISuperOfficeField> = [];

    cdoContactSuperOfficeFields: Array<ISuperOfficeField> = [];
    cdoCompanySuperOfficeFields: Array<ISuperOfficeField> = [];

    showContactExtraOption = false;
    showCompanyExtraOption = false;
    showCdoExtraOption: boolean;
    showActivityExtraOption = this.activatedRoute.snapshot.data.action.activity ? true : false;
    isSubmitted = false;
    isValidActivity: boolean;
    isMemberOfProject: boolean;
    updateOnly: boolean;
    selectedCdo: ICdo;

    currentStep = 1;
    totalSteps = 2;
    title = ConfigurationStepTitles.entities;

    projectAlert: Object = {
        text: '',
        type: ''
    }

    times: Array<string>;

    constructor(private activatedRoute: ActivatedRoute, private actionService: ActionService) { }

    ngOnInit() {
        this.action = this.activatedRoute.snapshot.data.action;
        console.log('Action:', this.action, 'ContactFields:', this.contactFields);

        this.times = this.createTimeValuesStringsArray();

        switch (this.action.projectNumber) {
            case '0':
            case null:
            case undefined:
                this.projectAlert = {
                    text: 'CRM project number is not specified! New Project entity will be created in SuperOffice!',
                    type: 'warning'
                };
                break;
            case '-1':
                this.projectAlert = {
                    text: `CRM project number doesn\'t exist in SuperOffice. Contact entities won\'t be assign to Project entity!\n` +
                        'Tip: Change project number to existing one and save campaign to assign Contact entities to Project entity!',
                    type: 'danger'
                };
                break;
            default:
                this.projectAlert = {
                    text: `CRM project number is ${this.action.projectNumber}! Contact entities will be assign to Project entity!`,
                    type: 'success'
                };
                break;
        }

        this.showCdoExtraOption = this.action.cdoId && true;

        if (this.activatedRoute.snapshot.data.cdos) {
            this.cdos = this.activatedRoute.snapshot.data.cdos;
            this.selectedCdo = this.cdos[0];
        }
        this.activityTypes = this.activatedRoute.snapshot.data.activityTypes;

        if (this.action.activity) {
            this.activity = this.action.activity;
            let start = new Date(this.activity.startDate.substring(0, this.activity.startDate.length - 1));
            let end = new Date(this.activity.endDate.substring(0, this.activity.endDate.length - 1));

            let sdd = String(start.getDate()).padStart(2, '0');
            let smm = String(start.getMonth() + 1).padStart(2, '0');
            let syyyy = start.getFullYear();
            let smin = String(start.getMinutes()).padStart(2, '0');
            let shh = String(start.getHours()).padStart(2, '0');

            let edd = String(end.getDate()).padStart(2, '0');
            let emm = String(end.getMonth() + 1).padStart(2, '0');
            let eyyyy = end.getFullYear();
            let emin = String(end.getMinutes()).padStart(2, '0');
            let ehh = String(end.getHours()).padStart(2, '0');

            this.activity.startDate = smm + '/' + sdd + '/' + syyyy;
            this.activity.endDate = emm + '/' + edd + '/' + eyyyy;
            this.activity.startTime = shh + ':' + smin;
            this.activity.endTime = ehh + ':' + emin;

            this.setValidity();
        }
        if (this.activatedRoute.snapshot.data.eloquaFields) {
            this.eloquaFields = this.activatedRoute.snapshot.data.eloquaFields;

            this.contactEloquaFields = this.eloquaFields.filter(field => field.type === EloquaFieldType.contact).sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.companyEloquaFields = this.eloquaFields.filter(field => field.type === EloquaFieldType.contact).sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.cdoContactEloquaFields = this.eloquaFields.filter(field => field.type === EloquaFieldType.cdo).sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.cdoCompanyEloquaFields = this.eloquaFields.filter(field => field.type === EloquaFieldType.cdo).sort((a, b) => (a.name > b.name) ? 1 : -1);
        }
        if (this.activatedRoute.snapshot.data.superOfficeFields) {
            this.superOfficeFields = this.activatedRoute.snapshot.data.superOfficeFields;

            this.contactSuperOfficeFields = this.superOfficeFields.filter(field => field.type === EloquaFieldType.contact).sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.companySuperOfficeFields = this.superOfficeFields.filter(field => field.type === EloquaFieldType.company).sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.cdoContactSuperOfficeFields = this.superOfficeFields.filter(field => field.type === EloquaFieldType.cdoContact).sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.cdoCompanySuperOfficeFields = this.superOfficeFields.filter(field => field.type === EloquaFieldType.cdoCompany).sort((a, b) => (a.name > b.name) ? 1 : -1);
        }
        if (this.action.fieldMappings) {
            this.fieldMappings = this.action.fieldMappings;

            this.contactFields = this.fieldMappings.filter(field => field.superOfficeField.type === EloquaFieldType.contact);
            this.companyFields = this.fieldMappings.filter(field => field.superOfficeField.type === EloquaFieldType.company);
            this.cdoContactFields = this.fieldMappings.filter(field => field.superOfficeField.type === EloquaFieldType.cdoContact);
            this.cdoCompanyFields = this.fieldMappings.filter(field => field.superOfficeField.type === EloquaFieldType.cdoCompany);
        }
        console.log('CONTACT FIELDS:', this.contactFields);

        this.action.fieldMappings.forEach(mapping => {
            if (mapping.eloquaField.type === EloquaFieldType.contact) {
                this.showContactExtraOption = true;
            }
            if (mapping.eloquaField.type === EloquaFieldType.company) {
                this.showCompanyExtraOption = true;
            }
            if (mapping.eloquaField.type === EloquaFieldType.cdo) {
                this.showCdoExtraOption = true;
            }
        });

        if (this.action.isProject) {
            this.isMemberOfProject = true;
        } else {
            this.isMemberOfProject = false;
        }

        if (this.action.updateOnly) {
            this.updateOnly = true;
        } else {
            this.updateOnly = false;
        }


        this.totalSteps = this.action.activity ? 3 : 2;

        this.activatedRoute.paramMap.subscribe(params => {
            this.instanceId = params.get("id");
        });
    }

    toggleMapOption(entity: string): void {
        this.removeMappedFieldsFromArray(entity);
        console.log('TOGGLE1:', this.contactFields);

        switch (entity) {
            case EloquaFieldType.contact:
                this.showContactExtraOption = !this.showContactExtraOption;
                this.showContactExtraOption && !this.showCdoExtraOption && this.contactFields.push(...this.addInitialFields(EloquaFieldType.contact));
                this.showCdoExtraOption && this.removeMappedFieldsFromArray(EloquaFieldType.cdoContact);
                this.showContactExtraOption && this.showCdoExtraOption && this.cdoContactFields.push(...this.addInitialFields(EloquaFieldType.cdoContact, this.selectedCdo.id));
                if (!this.showContactExtraOption && this.showActivityExtraOption) {
                    this.showActivityExtraOption = false;
                    this.totalSteps -= 1;
                }
                break;
            case EloquaFieldType.company:
                this.showCompanyExtraOption = !this.showCompanyExtraOption;
                this.showCompanyExtraOption && !this.showCdoExtraOption && this.companyFields.push(...this.addInitialFields(EloquaFieldType.company));
                this.showCdoExtraOption && this.removeMappedFieldsFromArray(EloquaFieldType.cdoCompany);
                this.showCompanyExtraOption && this.showCdoExtraOption && this.cdoCompanyFields.push(...this.addInitialFields(EloquaFieldType.cdoCompany, this.selectedCdo.id));
                break;
            case EloquaFieldType.project:
                this.isMemberOfProject = !this.isMemberOfProject;
                break;
            case EloquaFieldType.activity:
                this.showActivityExtraOption = !this.showActivityExtraOption;
                this.showActivityExtraOption ? this.totalSteps += 1 : this.totalSteps -= 1;
                let today = new Date();
                let dd = String(today.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0');
                let yyyy = today.getFullYear();
                let min = String(today.getMinutes()).padStart(2, '0');
                let hh = String(today.getHours()).padStart(2, '0');

                this.activity.startDate = mm + '/' + dd + '/' + yyyy;
                this.activity.endDate = mm + '/' + dd + '/' + yyyy;
                this.activity.startTime = hh + ':' + min;
                this.activity.endTime = hh + ':' + min;

                break;
            default:
                break;
        }
        console.log('TOGGLE2:', this.contactFields);
    }

    toggleAction(type: 'source' | 'action', isActivated: boolean): void {
        console.log('CNTFIE1:', this.contactFields);
        switch (type) {
            case EloquaFieldType.source:
                if (isActivated) {
                    this.showCdoExtraOption = isActivated;
                    this.removeMappedFieldsFromArray(EloquaFieldType.cdoContact);
                    this.removeMappedFieldsFromArray(EloquaFieldType.cdoCompany);
                    this.removeMappedFieldsFromArray(EloquaFieldType.contact);
                    this.removeMappedFieldsFromArray(EloquaFieldType.company);
                    this.showContactExtraOption && this.cdoContactFields.push(...this.addInitialFields(EloquaFieldType.cdoContact, this.selectedCdo.id));
                    this.showCompanyExtraOption && this.cdoCompanyFields.push(...this.addInitialFields(EloquaFieldType.cdoCompany, this.selectedCdo.id));
                    this.filteredCdoContactEloquaFields = this.cdoContactEloquaFields.filter(field => field.cdoId === this.selectedCdo.id).sort((a, b) => (a.name > b.name) ? 1 : -1);
                    this.filteredCdoCompanyEloquaFields = this.cdoCompanyEloquaFields.filter(field => field.cdoId === this.selectedCdo.id).sort((a, b) => (a.name > b.name) ? 1 : -1);
                } else {
                    this.showCdoExtraOption = isActivated;
                    this.removeMappedFieldsFromArray(EloquaFieldType.contact);
                    this.removeMappedFieldsFromArray(EloquaFieldType.company);
                    this.removeMappedFieldsFromArray(EloquaFieldType.cdoContact);
                    this.removeMappedFieldsFromArray(EloquaFieldType.cdoCompany);
                    this.showContactExtraOption && this.contactFields.push(...this.addInitialFields(EloquaFieldType.contact));
                    this.showCompanyExtraOption && this.companyFields.push(...this.addInitialFields(EloquaFieldType.company));
                }
                break;
            case EloquaFieldType.action:
                this.updateOnly = isActivated;
                break;
            default:
                break;
        }
        console.log('CNTFIE2:', this.contactFields);
    }

    removeMappedFieldsFromArray(type: string): void {
        switch (type) {
            case EloquaFieldType.contact:
                this.contactFields = this.contactFields.filter(field => {
                    return field.eloquaField.type !== type;
                });
                break;
            case EloquaFieldType.company:
                this.companyFields = this.companyFields.filter(field => {
                    return field.eloquaField.type !== type;
                });
                break;
            case EloquaFieldType.cdoContact:
                this.cdoContactFields = this.cdoContactFields.filter(field => {
                    return field.eloquaField.type !== type;
                });
                break;
            case EloquaFieldType.cdoCompany:
                this.cdoCompanyFields = this.cdoCompanyFields.filter(field => {
                    return field.eloquaField.type !== type;
                });
                break;
            default:
                break;
        }
    }

    togglePrevStep(): void {
        this.currentStep -= 1;
        this.setTitle();
    }

    toggleNextStep(): void {
        this.currentStep += 1;
        this.setTitle();
    }

    setTitle(): void {
        if (this.currentStep === 1) {
            this.title = ConfigurationStepTitles.entities;
        } else if (this.currentStep === 2) {
            this.title = ConfigurationStepTitles.fields;
        } else if (this.currentStep === 3) {
            this.title = ConfigurationStepTitles.activity;
        }
    }

    addInitialFields(entity: string, cdoId?: number): Array<IFieldMapping> {
        let newType: EloquaFieldType;
        let fieldMappings: Array<IFieldMapping> = [];
        let eloquaEmailField: IEloquaField, superOfficeEmailField: ISuperOfficeField, eloquaFirstNameField: IEloquaField, superOfficeFirstNameField: ISuperOfficeField;

        switch (entity) {
            case EloquaFieldType.contact:
            case EloquaFieldType.company:
                newType = EloquaFieldType.contact;
                break;
            case EloquaFieldType.cdoContact:
            case EloquaFieldType.cdoCompany:
                newType = EloquaFieldType.cdo;
                break;
            default:
                break;
        }

        if (cdoId) {
            eloquaEmailField = this.eloquaFields.find(field => field.cdoId === cdoId && field.type === newType && field.name.includes('Email'));
            if (!eloquaEmailField) {
                eloquaEmailField = this.eloquaFields.find(field => field.cdoId === cdoId && field.type === newType);
            }
            eloquaFirstNameField = this.eloquaFields.find(field => field.cdoId === cdoId && field.type === newType);
        } else {
            eloquaEmailField = this.eloquaFields.find(field => field.type === newType && field.name.includes('Email'));
            eloquaFirstNameField = this.eloquaFields.find(field => field.type === newType && field.name === EloquaFieldName.firstName);
        }

        superOfficeEmailField = this.superOfficeFields.find(field => field.type === entity && field.name === SuperOfficeFieldName.email);
        superOfficeFirstNameField = this.superOfficeFields.find(field => field.type === entity &&
            (field.name === SuperOfficeFieldName.firstName || field.name === SuperOfficeFieldName.name));

        console.log('eloquaFirstNameField:', eloquaFirstNameField, 'eloquaEmailField:', eloquaEmailField);

        let emailMapping: IFieldMapping = {
            configurationId: this.action.id,
            sourceFieldId: eloquaEmailField.id,
            targetFieldId: superOfficeEmailField.id,
            eloquaField: {
                id: eloquaEmailField.id,
                cdoId: this.action.cdoId,
                name: eloquaEmailField.name,
                statement: eloquaEmailField.statement,
                type: entity
            },
            superOfficeField: {
                id: superOfficeEmailField.id,
                name: superOfficeEmailField.name,
                type: entity
            }
        }

        let firstNameMapping: IFieldMapping = {
            configurationId: this.action.id,
            sourceFieldId: eloquaFirstNameField.id,
            targetFieldId: superOfficeFirstNameField.id,
            eloquaField: {
                id: eloquaFirstNameField.id,
                cdoId: this.action.cdoId,
                name: eloquaFirstNameField.name,
                statement: eloquaFirstNameField.statement,
                type: entity
            },
            superOfficeField: {
                id: superOfficeFirstNameField.id,
                name: superOfficeFirstNameField.name,
                type: entity
            }
        }

        switch (entity) {
            case EloquaFieldType.contact:
            case EloquaFieldType.cdoContact:
                fieldMappings.push(emailMapping, firstNameMapping);
                break;
            case EloquaFieldType.company:
            case EloquaFieldType.cdoCompany:
                fieldMappings.push(emailMapping);
            default:
                break;
        }

        console.log('MAPPPINGS:', fieldMappings);

        return fieldMappings;
    }

    addFields(type: EloquaFieldType): void {
        let fieldMapping: IFieldMapping;
        this.eloquaFields.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.superOfficeFields.sort((a, b) => (a.name > b.name) ? 1 : -1);
        let newType: EloquaFieldType;

        switch (type) {
            case EloquaFieldType.contact:
            case EloquaFieldType.company:
                newType = EloquaFieldType.contact;
                break;
            case EloquaFieldType.cdoContact:
            case EloquaFieldType.cdoCompany:
                newType = EloquaFieldType.cdo;
                break;
            default:
                break;
        }

        console.log(type, newType);

        fieldMapping = {
            configurationId: this.action.id,
            sourceFieldId: this.eloquaFields.find(field => field.type === newType).id,
            targetFieldId: this.superOfficeFields.find(field => field.type === type).id,
            eloquaField: {
                id: this.eloquaFields.find(field => field.type === newType).id,
                cdoId: this.action.cdoId,
                name: this.eloquaFields.find(field => field.type === newType).name,
                statement: this.eloquaFields.find(field => field.type === newType).statement,
                type
            },
            superOfficeField: {
                id: this.superOfficeFields.find(field => field.type === type).id,
                name: this.superOfficeFields.find(field => field.type === type).name,
                type
            }
        }

        switch (type) {
            case EloquaFieldType.contact:
                this.contactFields.push(fieldMapping);
                break;
            case EloquaFieldType.company:
                this.companyFields.push(fieldMapping);
                break;
            case EloquaFieldType.cdoContact:
                this.cdoContactFields.push(fieldMapping);
                break;
            case EloquaFieldType.cdoCompany:
                this.cdoCompanyFields.push(fieldMapping);
                break;
            default:
                break;
        }
    }

    onSuperOfficeFieldChange(index: number, event: Event, type: EloquaFieldType) {
        let id = Number((<HTMLInputElement>event.target).value);

        switch (type) {
            case EloquaFieldType.contact:
                this.contactFields[index].targetFieldId = id;
                this.contactFields[index].superOfficeField = this.contactSuperOfficeFields.find(x => x.id === id);
                break;
            case EloquaFieldType.company:
                this.companyFields[index].targetFieldId = id;
                this.companyFields[index].superOfficeField = this.companySuperOfficeFields.find(x => x.id === id);
                break;
            case EloquaFieldType.cdoContact:
                this.cdoContactFields[index].targetFieldId = id;
                this.cdoContactFields[index].superOfficeField = this.cdoContactSuperOfficeFields.find(x => x.id === id);
                break;
            case EloquaFieldType.cdoCompany:
                this.cdoCompanyFields[index].targetFieldId = id;
                this.cdoCompanyFields[index].superOfficeField = this.cdoCompanySuperOfficeFields.find(x => x.id === id);
                break;
            default:
                break;
        }
    }

    onEloquaFieldChange(index: number, event: Event, type: EloquaFieldType): void {
        let id = Number((<HTMLInputElement>event.target).value);

        switch (type) {
            case EloquaFieldType.contact:
                this.contactFields[index].sourceFieldId = id;
                this.contactFields[index].eloquaField = this.contactEloquaFields.find(x => x.id === id);
                break;
            case EloquaFieldType.company:
                this.companyFields[index].sourceFieldId = id;
                this.companyFields[index].eloquaField = this.companyEloquaFields.find(x => x.id === id);
                break;
            case EloquaFieldType.cdoContact:
                this.cdoContactFields[index].sourceFieldId = id;
                this.cdoContactFields[index].eloquaField = this.filteredCdoContactEloquaFields.find(x => x.id === id);
                break;
            case EloquaFieldType.cdoCompany:
                this.cdoCompanyFields[index].sourceFieldId = id;
                this.cdoCompanyFields[index].eloquaField = this.filteredCdoCompanyEloquaFields.find(x => x.id === id);
                break;
            default:
                break;
        }
    }

    deleteFields(index: number, type: EloquaFieldType): void {
        switch (type) {
            case EloquaFieldType.contact:
                this.contactFields.splice(index, 1);
                break;
            case EloquaFieldType.company:
                this.companyFields.splice(index, 1);
                break;
            case EloquaFieldType.cdoContact:
                this.cdoContactFields.splice(index, 1);
                break;
            case EloquaFieldType.cdoCompany:
                this.cdoCompanyFields.splice(index, 1);
                break;
            default:
                break;
        }
    }

    setCdo(event: Event): void {
        let id = Number((<HTMLInputElement>event.target).value);
        console.log('cdo id:', id);
        this.cdos.filter(cdo => {
            if (cdo.id === id) {
                this.selectedCdo = cdo;
                this.removeMappedFieldsFromArray(EloquaFieldType.cdoContact);
                this.removeMappedFieldsFromArray(EloquaFieldType.cdoCompany);
                this.filteredCdoContactEloquaFields = this.cdoContactEloquaFields.filter(field => field.cdoId === this.selectedCdo.id).sort((a, b) => (a.name > b.name) ? 1 : -1);
                this.filteredCdoCompanyEloquaFields = this.cdoCompanyEloquaFields.filter(field => field.cdoId === this.selectedCdo.id).sort((a, b) => (a.name > b.name) ? 1 : -1);
                this.showContactExtraOption && this.cdoContactFields.push(...this.addInitialFields(EloquaFieldType.cdoContact, id));
                this.showCompanyExtraOption && this.cdoCompanyFields.push(...this.addInitialFields(EloquaFieldType.cdoCompany, id));
            }
        });
    }

    setValidity(): void {
        if (this.activity.description && this.isValidDate(this.activity.startDate) && this.isValidDate(this.activity.endDate) &&
            this.activity.startTime && this.activity.endTime) {
            this.isValidActivity = true;
        } else {
            this.isValidActivity = false;
        }
    }

    isValidDate(dateString: string): boolean {
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
            return false;
        }

        let parts = dateString.split("/");
        let day = parseInt(parts[1], 10);
        let month = parseInt(parts[0], 10);
        let year = parseInt(parts[2], 10);

        if (year < 1000 || year > 3000 || month == 0 || month > 12) {
            return false;
        }

        let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
            monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
    }

    createTimeValuesStringsArray(): Array<string> {
        const x = 15;
        let times = [];
        let tt = 0;

        for (var i = 0; tt < 24 * 60; i++) {
            var hh = Math.floor(tt / 60);
            var mm = (tt % 60);
            times[i] = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2);
            tt = tt + x;
        }

        return times;
    }

    submitConfiguration(): void {
        this.fieldMappings = this.contactFields.concat(this.companyFields, this.cdoContactFields, this.cdoCompanyFields);

        let configurationToSave: ISaveConfiguration = {
            activity: this.activity,
            cdoId: this.showCdoExtraOption ? this.selectedCdo.id : null,
            cdo: this.selectedCdo,
            fieldMappings: this.fieldMappings,
            instanceId: this.instanceId,
            isActivity: this.showActivityExtraOption,
            isCompany: this.showCompanyExtraOption,
            isContact: this.showContactExtraOption,
            isProject: this.isMemberOfProject,
            updateOnly: this.updateOnly
        }

        this.actionService.saveConfiguration(configurationToSave)
            .subscribe(error => this.errorMessage = error as any);

        this.isSubmitted = true;

        if (this.errorMessage) {
            this.title = ConfigurationStepTitles.submittedFail;
            this.currentStep = 4;
        } else {
            this.title = ConfigurationStepTitles.submittedSuccess;
            this.currentStep = 4;
        }
    }
}
