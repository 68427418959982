import { 
	Directive,
	HostListener,
	Input
} from '@angular/core';

import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuService } from './context-menu.service';


@Directive({
  selector: '[appContextMenu]'
})
export class ContextMenuDirective {

  @Input()
  contextMenu: ContextMenuComponent;

  @Input()
  contextMenuSubject: any;

  constructor(private contextMenuService: ContextMenuService) { }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: MouseEvent) {
  	this.contextMenuService.open({
  		contextMenu: this.contextMenu,
  		contextMenuSubject: this.contextMenuSubject,
  		event: event,
  	});
  	/*
  	this.contextMenuService.sendMessage({
  		contextMenu: this.contextMenu,
  		event: event
  	});
  	*/
  	event.preventDefault();
  	event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:contextmenu', ['$event'])
  onClick(event: MouseEvent) {
  	this.contextMenuService.close();
  	//event.preventDefault();
  }

}
