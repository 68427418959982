<div class="clr-row">
    <div class="clr-col-lg-10 clr-col-md-12 clr-col-12 config-card">
        <div class="card">
            <div class="card-header">
                {{ title }}
            </div>
            <div class="card-block">
                <form clrForm id="entities-form" *ngIf="currentStep === 1">
                    <clr-checkbox-container clrInline class="so-entities">
                        <label class="clr-control-label clr-col-12 clr-col-md-12">I want to add data on
                            SuperOffice:</label>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox name="options" value="contact"
                                (click)='toggleMapOption("contact")' [checked]="showContactExtraOption" />
                            <label>Contact</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox name="options" value="company"
                                (click)='toggleMapOption("company")' [checked]="showCompanyExtraOption" />
                            <label>Company</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper *ngIf="showContactExtraOption">
                            <input type="checkbox" clrCheckbox name="options" value="activity"
                                (click)='toggleMapOption("activity")' [checked]="showActivityExtraOption"/>
                            <label title="Select Contact first!">Activity</label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                    <clr-radio-container clrInline *ngIf="showContactExtraOption">
                        <label class="clr-control-label clr-col-12 clr-col-md-12">I want to assign Contact to Project on
                            SuperOffice:</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio value="true" name="project" (click)='toggleMapOption("project")'
                                [checked]="isMemberOfProject" />
                            <label>Yes</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio value="false" name="project" (click)='toggleMapOption("project")'
                                [checked]="!isMemberOfProject" />
                            <label>No</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                    <clr-alert [clrAlertSizeSmall]="true" [clrAlertType]="projectAlert.type" [clrAlertClosable]="false"
                        *ngIf="showContactExtraOption && isMemberOfProject">
                        <clr-alert-item>
                            <span class="alert-text">
                                {{ projectAlert.text }}
                            </span>
                        </clr-alert-item>
                    </clr-alert>
                </form>

                <form clrForm id="fields-form" *ngIf="currentStep === 2">
                    <div class="clr-row">
                        <div class="clr-col-3">
                            <clr-radio-container class="action-properties">
                                <label class="clr-control-label clr-col-12 clr-col-md-12">Choose an action:</label>
                                <clr-radio-wrapper>
                                    <input type="radio" clrRadio value="createOrUpdate" name="actions"
                                        (click)='toggleAction("action", false)' [checked]="!updateOnly" />
                                    <label>Create or Update</label>
                                </clr-radio-wrapper>
                                <clr-radio-wrapper>
                                    <input type="radio" clrRadio value="update" name="actions"
                                        (click)='toggleAction("action", true)' [checked]="updateOnly" />
                                    <label>Update only</label>
                                </clr-radio-wrapper>
                            </clr-radio-container>
                        </div>
                        <div class="clr-col-3">
                            <clr-radio-container class="action-properties">
                                <label class="clr-control-label clr-col-12 clr-col-md-12">Data source:</label>
                                <clr-radio-wrapper>
                                    <input type="radio" clrRadio value="contact" name="data"
                                        (click)='toggleAction("source", false)' [checked]="!showCdoExtraOption" />
                                    <label>Contact object</label>
                                </clr-radio-wrapper>
                                <clr-radio-wrapper>
                                    <input type="radio" clrRadio value="cdo" name="data"
                                        (click)='toggleAction("source", true)' [checked]="showCdoExtraOption" />
                                    <label>Custom data object</label>
                                </clr-radio-wrapper>
                            </clr-radio-container>
                        </div>
                        <div class="clr-col-6">
                            <div class="clr-form-control clr-row cdos" *ngIf="showCdoExtraOption">
                                <label class="clr-control-label clr-col-12 clr-col-md-12 cdo-label">Choose a
                                    cdo:</label>
                                <div class="clr-select-wrapper">
                                    <select id="select-basic" class="clr-select" (change)="setCdo($event)" [value]="selectedCdo.id">
                                        <option *ngFor="let cdo of cdos" [value]="cdo.id">
                                            {{cdo.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <clr-dropdown [clrCloseMenuOnItemClick]="false">
                        <button type="button" clrDropdownTrigger class="btn btn-sm btn-icon btn-outline-primary"
                            title="Add fields to map">
                            <clr-icon shape="plus"></clr-icon>Add
                        </button>
                        <clr-dropdown-menu *clrIfOpen>
                            <label class="dropdown-header">Add</label>
                            <button type="button" *ngIf="!showCdoExtraOption && showContactExtraOption" clrDropdownItem
                                (click)="addFields('contact')">Contact</button>
                            <button type="button" *ngIf="!showCdoExtraOption && showCompanyExtraOption" clrDropdownItem
                                (click)="addFields('company')">Company</button>
                            <button type="button" *ngIf="showCdoExtraOption && showContactExtraOption" clrDropdownItem
                                (click)="addFields('cdoContact')">CDO Contact</button>
                            <button type="button" *ngIf="showCdoExtraOption && showCompanyExtraOption" clrDropdownItem
                                (click)="addFields('cdoCompany')">CDO Company</button>
                        </clr-dropdown-menu>
                    </clr-dropdown>
                    <div class="clr-row" style="text-align: center;">
                        <div class="clr-col-3">
                            <b>Eloqua field</b>
                        </div>
                        <div class="clr-col-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                        </div>
                        <div class="clr-col-3">
                            <b>SuperOffice field</b>
                        </div>
                    </div>

                    <div class="clr-row" *ngFor="let field of contactFields; let i = index">
                        <div class="clr-col-4">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [value]="field.sourceFieldId"
                                    [disabled]="(field.eloquaField.name === 'FirstName' && field.superOfficeField.name === 'Firstname' && 2 > i) || (field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 2 > i)"
                                    (change)="onEloquaFieldChange(i, $event, 'contact')" name="eloquaFieldsSelect">
                                    <option *ngFor="let option of contactEloquaFields" [value]="option.id">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="clr-col-4">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [value]="field.targetFieldId"
                                    [disabled]="(field.superOfficeField.name === 'Firstname' && 2 > i) || (field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 2 > i)"
                                    (change)="onSuperOfficeFieldChange(i, $event, 'contact')"
                                    name="superOfficeFieldsSelect">
                                    <option *ngFor="let option of contactSuperOfficeFields" [value]="option.id">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="clr-col-4">
                            <span class="btn-group btn-outline-danger btn-icon">
                                <button class="btn btn-sm" (click)="deleteFields(i, 'contact')" title="Remove fields"
                                    [disabled]="(field.superOfficeField.name === 'Firstname' && 2 > i) || (field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 2 > i)">
                                    <clr-icon shape="trash"></clr-icon>
                                    <span class="clr-icon-title">Remove</span>
                                </button>
                            </span>
                            <span class="label">{{field.superOfficeField.type}}</span>
                        </div>
                        <br>
                    </div>

                    <div class="clr-row" *ngFor="let field of companyFields; let i = index">
                        <div class="clr-col-4">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [value]="field.sourceFieldId"
                                    [disabled]="field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 1 > i"
                                    (change)="onEloquaFieldChange(i, $event, 'company')" name="eloquaFieldsSelect">
                                    <option *ngFor="let option of companyEloquaFields" [value]="option.id">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="clr-col-4">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [value]="field.targetFieldId"
                                    [disabled]="field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 1 > i"
                                    (change)="onSuperOfficeFieldChange(i, $event, 'company')"
                                    name="superOfficeFieldsSelect">
                                    <option *ngFor="let option of companySuperOfficeFields" [value]="option.id">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="clr-col-4">
                            <span class="btn-group btn-outline-danger btn-icon">
                                <button class="btn btn-sm" (click)="deleteFields(i, 'company')" title="Remove fields"
                                    [disabled]="field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 1 > i">
                                    <clr-icon shape="trash"></clr-icon>
                                    <span class="clr-icon-title">Remove</span>
                                </button>
                            </span>
                            <span class="label">{{field.superOfficeField.type}}</span>
                        </div>
                        <br>
                    </div>

                    <div class="clr-row" *ngFor="let field of cdoContactFields; let i = index">
                        <div class="clr-col-4">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [value]="field.sourceFieldId"
                                    [disabled]="(field.eloquaField.name === 'FirstName' && field.superOfficeField.name === 'Firstname' && 2 > i) || (field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 2 > i)"
                                    (change)="onEloquaFieldChange(i, $event, 'cdoContact')" name="eloquaFieldsSelect">
                                    <option *ngFor="let option of filteredCdoContactEloquaFields" [value]="option.id">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="clr-col-4">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [value]="field.targetFieldId"
                                    [disabled]="(field.superOfficeField.name === 'Firstname' && 2 > i) || (field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 2 > i)"
                                    (change)="onSuperOfficeFieldChange(i, $event, 'cdoContact')"
                                    name="superOfficeFieldsSelect">
                                    <option *ngFor="let option of cdoContactSuperOfficeFields" [value]="option.id">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="clr-col-4">
                            <span class="btn-group btn-outline-danger btn-icon">
                                <button class="btn btn-sm" (click)="deleteFields(i, 'cdoContact')" title="Remove fields"
                                    [disabled]="(field.superOfficeField.name === 'Firstname' && 2 > i) || (field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 2 > i)">
                                    <clr-icon shape="trash"></clr-icon>
                                    <span class="clr-icon-title">Remove</span>
                                </button>
                            </span>
                            <span class="label">{{field.superOfficeField.type}}</span>
                        </div>
                        <br>
                    </div>

                    <div class="clr-row" *ngFor="let field of cdoCompanyFields; let i = index">
                        <div class="clr-col-4">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [value]="field.sourceFieldId"
                                    [disabled]="field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 1 > i"
                                    (change)="onEloquaFieldChange(i, $event, 'cdoCompany')" name="eloquaFieldsSelect">
                                    <option *ngFor="let option of filteredCdoCompanyEloquaFields" [value]="option.id">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="clr-col-4">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [value]="field.targetFieldId"
                                    [disabled]="field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 1 > i"
                                    (change)="onSuperOfficeFieldChange(i, $event, 'cdoCompany')"
                                    name="superOfficeFieldsSelect">
                                    <option *ngFor="let option of cdoCompanySuperOfficeFields" [value]="option.id">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="clr-col-4">
                            <span class="btn-group btn-outline-danger btn-icon">
                                <button class="btn btn-sm" (click)="deleteFields(i, 'cdoCompany')" title="Remove fields"
                                    [disabled]="field.eloquaField.name && field.eloquaField.name.includes('Email') && field.superOfficeField.name === 'Emails' && 1 > i">
                                    <clr-icon shape="trash"></clr-icon>
                                    <span class="clr-icon-title">Remove</span>
                                </button>
                            </span>
                            <span class="label">{{field.superOfficeField.type}}</span>
                        </div>
                        <br>
                    </div>
                </form>

                <form clrForm id="activity-form" *ngIf="showActivityExtraOption && currentStep === totalSteps">
                    <clr-select-container>
                        <label>Type</label>
                        <select clrSelect id="type" name="options" name="activity.typeId" [(ngModel)]="activity.typeId">
                            <option *ngFor="let activityType of activityTypes" [value]="activityType.id">
                                {{activityType.name}}</option>
                        </select>
                        <clr-control-error>This field is required!</clr-control-error>
                    </clr-select-container>
                    <clr-textarea-container>
                        <label>Description</label>
                        <textarea clrTextarea name="activity.description" [(ngModel)]="activity.description" (change)="setValidity()" required></textarea>
                    </clr-textarea-container>
                    <clr-date-container>
                        <label>Start Date</label>
                        <input clrDate name="activity.startDate" [(ngModel)]="activity.startDate" (change)="setValidity()" required />
                    </clr-date-container>
                    <clr-select-container>
                        <label>Start Time</label>
                        <select clrSelect id="start-time" name="activity.startTime" [(ngModel)]="activity.startTime" (change)="setValidity()" required>
                            <option *ngFor="let time of times" [value]="time">
                                {{time}}
                            </option>
                        </select>
                    </clr-select-container>
                    <clr-date-container>
                        <label>End Date</label>
                        <input clrDate name="activity.endDate" [(ngModel)]="activity.endDate" (change)="setValidity()" required />
                    </clr-date-container>
                    <clr-select-container>
                        <label>End Time</label>
                        <select clrSelect id="end-time" name="activity.endTime" [(ngModel)]="activity.endTime" (change)="setValidity()" required>
                            <option *ngFor="let time of times" [value]="time">
                                {{time}}
                            </option>
                        </select>
                    </clr-select-container>
                </form>

                <clr-alert [clrAlertType]="'success'" [clrAlertClosable]="false" *ngIf="isSubmitted === true">
                    <clr-alert-item>
                        <span class="alert-text">
                            Configuration was successfully saved!
                        </span>
                    </clr-alert-item>
                </clr-alert>

                <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false" *ngIf="errorMessage">
                    <clr-alert-item>
                        <span class="alert-text">
                            Failed to save configuration!
                        </span>
                    </clr-alert-item>
                </clr-alert>
            </div>
            <div class="card-footer" *ngIf="isSubmitted === false">
                <button class="btn btn-sm btn-link" (click)='togglePrevStep()'
                    [attr.disabled]="currentStep === 1 ? '' : null">Previous</button>
                <button class="btn btn-sm btn-link" (click)='toggleNextStep()' [attr.disabled]="currentStep === totalSteps || 
                        (!showContactExtraOption &&
                        !showCompanyExtraOption &&
                        !showActivityExtraOption) ? '' : null">Next</button>
                <button class="btn btn-sm btn-success-outline" (click)='submitConfiguration()'
                    [disabled]="!isValidActivity && showActivityExtraOption"
                    *ngIf="currentStep === totalSteps">Submit</button>
            </div>
        </div>
    </div>
</div>