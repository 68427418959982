<div class="sc-view contact-filter-edit-view">
  <div dndDropzone class="sc-view scroll-view" (dndDrop)="onDrop($event)" (mousedown)="onMouseDown($event)" (contextmenu)="onContextMenu($event)">
	<ng-container #container></ng-container> 
  </div>
  <div class="sc-view footer">
    <div class="sc-view advanced-operators">
      <vmc-button *ngIf="groupButtonVisible" [disable]="groupButtonDisabled" (execute)="onGroup($event)" style="width: 80px;">
	     <ng-template #content>Group</ng-template>
	  </vmc-button>
      <vmc-button *ngIf="!groupButtonVisible" [sel]="true" (execute)="onUngroup($event)" style="width: 80px;">
	     <ng-template #content>Ungroup</ng-template>
	  </vmc-button>	  
	</div>
  </div>
</div>

<vmc-context-menu>
	<ng-template appContextMenuItem (execute)="onDelete($event)">Delete</ng-template>
	<ng-template appContextMenuItem (execute)="onCalculate($event)">Recalculate</ng-template>
</vmc-context-menu>