import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeederComponent } from './feeder.component';
import { FeederResolver } from './feeder.resolver';
import { AuthGuard } from '../core/auth.guard';

const routes: Routes = [{
    path: 'feeders/:id/edit',
    component: FeederComponent,
    resolve: { feeder: FeederResolver },
   canActivate: [AuthGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeederRoutingModule { }
