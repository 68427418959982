import { Type } from '@angular/core';

export class Feeder {
	constructor(public id: number, public status: string, public instanceId: string, public statement: string, public criteria: Criterion[]) { }
}

export class Criterion {
	constructor(public id: number, public initialId: number, public name: string, public description: string, public dialogComponentType: Type<any>, public data?: any, public identationLevel?: number) { }
}

export class Operator {
	constructor(public name: string) { }
}

export class OperatorConfig {
	constructor(public index: number, public operators: Operator[], public identationLevel: number, public value?: string) { } 
}

export class GroupNodeConfig {
	constructor(public index: number, public value: string, public identationLevel: number) { }
}

export class FilterElementConfig {
	constructor(public index: number, public value: any, public identationLevel: number) { }
}

export class ListItem {
	constructor(public id: number, public name: string) { }
}

export class FeederCount {
	constructor(public id: number, public count: number, public startAt: string, public endAt: string) { }
}

export class QueueInfo {
	constructor(public feederId: number, public queuedAt: string) { }
}