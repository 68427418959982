import { StreamTokenizer } from './stream-tokenizer';

export class Lexer {

	private symbol: number;

	public static readonly EOL: number = -3;
	public static readonly EOF: number = -2;
	public static readonly INVALID: number = -1;
	public static readonly NONE: number = 0;
	public static readonly OPERATOR: number = 1;
	public static readonly LEFT: number = 2;
	public static readonly RIGHT: number = 3;
	public static readonly CRITERION: number = 4;

	private tokenizer: StreamTokenizer;

	constructor(private expression: string) {
		this.tokenizer = new StreamTokenizer(expression);

		this.tokenizer.resetSyntax();
	    this.tokenizer.parseNumbers();

	    this.tokenizer.wordChars('A', 'Z');
	    this.tokenizer.whitespaceChars('\u0000', ' ');
	    this.tokenizer.ordinaryChar('(');
	    this.tokenizer.ordinaryChar(')');
	}

	get svalue(): string {
		return this.tokenizer.sval; 
	}

	get nvalue(): number {
		return this.tokenizer.nval;
	}

	public nextSymbol(): number {
		const token = this.tokenizer.nextToken();
		switch (token) {
			case StreamTokenizer.TT_NUMBER:
				this.symbol = Lexer.CRITERION;
				break;
			case StreamTokenizer.TT_WORD:
				this.symbol = Lexer.OPERATOR;
				break;
			case '('.charCodeAt(0):
				this.tokenizer.sval = '(';
				this.symbol = Lexer.LEFT;
				break;
			case ')'.charCodeAt(0):
				this.tokenizer.sval = ')';
				this.symbol = Lexer.RIGHT;
				break;
			default:
				this.symbol = Lexer.INVALID;
				break;
		}

		return this.symbol;
	}
}