<div class="header">
  <vmc-searchbox
  	placeholder="Search For Filter Criteria" [items]="draggables" (found)="onFound($event)">
  </vmc-searchbox>
</div>
<div class="sc-view panel" >
  <div class="sc-outline" *ngFor="let draggable of foundDraggables"
     [dndDraggable]="draggable.content"
	   [dndEffectAllowed]="draggable.effectAllowed"
	   [dndDisableIf]="draggable.disable">
    <img [src]="draggable.content.iconUrl" style="position: relative; top: 5px; bottom: 5px; left: 5px; right: 5px;">
    <label>{{ draggable.content.name }}</label>
  </div>
</div> 