<div class="dialog">
  <div class="header">
      <div>Active company users...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">
      <!--
      <vmc-checkbox [text]="checkboxText" [initialValue]="negated.value" (check)="onCheckBoxClick($event)"
          style="position: absolute; left: 0px; top: 4px; height: 16px;"></vmc-checkbox>

      <label class="sc-view sc-checkbox" style="left: 0px;width: 50px; top: 4px; height: 16px;">
          <span class="button"></span>
          <span class="label">not</span>
      </label>
        -->
      <ng-select class="custom" style="left: 50px; width: 325px;"
          [items]="ranks" bindLabel="name" bindValue="id" placeholder="{{ placeholder }}"
          [formControl]="rank" required>
      </ng-select>
      <!--
      <div *ngIf="rank.errors" class="invalid-feedback">
        <div *ngIf="rank.errors.required">Rank is required</div>
      </div>
        -->
    </form>
  </div>
</div>
