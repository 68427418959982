import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IEloquaField } from './action.interface';
import { ActionService } from './action.service';

@Injectable({
    providedIn: 'root'
})
export class EloquaFieldResolver implements Resolve<IEloquaField> {
    constructor(private actionService: ActionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<IEloquaField> | Promise<IEloquaField> | IEloquaField {
        return this.actionService.getEloquaFields();
    }
}
