import { InjectionToken } from '@angular/core';

export interface CanvasConfig {
	enable_seranking_user_registered_criterion?: boolean,
	enable_lithium_role_criterion?: boolean,
	enable_lithium_rank_criterion?: boolean,
	enable_lithium_norole_criterion?: boolean,
	enable_lithium_country_criterion?: boolean,
	enable_lithium_login_criterion?: boolean,
	enable_lithium_not_login_criterion?: boolean,
	enable_gave_lithium_kudos_criterion?: boolean,
	enable_received_lithium_kudos_criterion?: boolean,
	enable_published_lithium_content_criterion?: boolean,
	enable_responded_to_lithium_survey_criterion?: boolean,
	enable_searched_lithium_criterion?: boolean,
	enable_ec_dk_active_company_users_criterion?:boolean,
	enable_ec_dk_exclude_active_company_users_criterion?:boolean,
	enable_ec_dk_active_ao_users_criterion?:boolean,
	enable_ec_dk_exclude_active_ao_users_criterion?:boolean,
	enable_ec_dk_active_trial_users_criterion?:boolean,
	enable_ec_dk_active_addons_criterion?:boolean,
	enable_ec_dk_not_addons_criterion?:boolean,
	enable_ec_dk_previous_addons_criterion?:boolean,
	enable_ec_dk_exclude_addons_criterion?:boolean,
	enable_ec_dk_active_group_of_company_users_criterion?:boolean,
	enable_ec_dk_exclude_active_group_of_company_users_criterion?:boolean,
	enable_ec_dk_industry_codes_company_criterion?:boolean,
	enable_ec_dk_industry_codes_goc_criterion?:boolean,
	enable_ec_dk_signup_date_criterion?:boolean,
	enable_ec_dk_new_signup_date_criterion?:boolean,
	enable_ec_dk_new_trial_users_criterion?:boolean,
	enable_ec_dk_trial_users_criterion?:boolean,
	enable_ec_dk_new_users_role_criterion?:boolean,
	enable_ec_dk_users_role_criterion?:boolean,
	enable_ec_dk_new_user_agreementtype_criterion?:boolean,
	enable_ec_dk_agreementtype_criterion?:boolean,
	enable_ec_dk_number_of_employees_criterion?:boolean,
	enable_ec_dk_segment_company_criterion?:boolean,
	enable_ec_dk_segment_goc_criterion?:boolean,
	enable_ec_dk_contact_information_permissions_criterion?:boolean,
	enable_ec_dk_subscription_criterion?:boolean,
	enable_ec_dk_exclude_subscription_criterion?:boolean,
	enable_ec_dk_not_subscription_criterion?:boolean,
	enable_ec_dk_user_logins_criterion?:boolean,
	enable_ec_dk_users_pr_agreement_criterion?:boolean,
	enable_ec_dk_content_service_table_criterion?:boolean,
	enable_ec_dk_aos_with_externals_criterion?:boolean,
	enable_ec_dk_mobile_app_used_criterion?:boolean,
	enable_ec_dk_trial_user_signup_source_criterion?:boolean,
	enable_ec_dk_new_trial_user_signup_source_criterion?:boolean,
	enable_ec_dk_unless_in_app_click_criterion?:boolean,
	enable_ec_dk_unless_in_app_display_criterion?:boolean
}

export const CANVAS_CONFIG = new InjectionToken<CanvasConfig>('CanvasConfig');
