import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface CriterionData {
	description: string;
}

@Injectable({
	providedIn: 'root'
})
export class CriterionService {

	private data: Map<number, any> = new Map<number, CriterionData>();

	constructor() { }

	getData(criterionId: number) {
		return this.data.get(criterionId);
	}

	saveData(criterionId: number, data: any) {
		this.data.set(criterionId, data);
	}
}