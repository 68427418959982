  import { Component, OnInit } from '@angular/core';
  import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
  import { DialogConfig } from '../../../../shared/dialog/dialog-config';
  import { CriterionService } from '../criterion.service';
  import { DataService, ListItem } from '../../../data.service';
  
  @Component({
    selector: 'app-ec-dk-aos-with-externals',
    templateUrl: './ec-dk-aos-with-externals.component.html',
    styleUrls: ['./ec-dk-aos-with-externals.component.scss']
  })

  export class EcDkAOsWithExternalsComponent implements OnInit {
  
    externals: ListItem<number, string>[] = [new ListItem(1, 'Yes'), new ListItem(2, 'No')];
  
    form: FormGroup;
  
    placeholder: string = "Select your choice!";
  
    data: any;
  
    private readonly criterionId: number;
  
    constructor(
      private criterionService: CriterionService,
      private dataService: DataService,
      private formBuilder: FormBuilder,
      private dialogConfig: DialogConfig) {
  
      this.criterionId = this.dialogConfig.data.id;
    }
  
    get external() {
      return this.form.get('external');
    }
  
    ngOnInit() {
      this.data = this.criterionService.getData(this.criterionId);
  
      this.form =  this.formBuilder.group({
        'external': this.formBuilder.control(this.data.external, [Validators.required]),
      });
  
      const value = this.form.value;
      value.invalid = this.form.invalid;
      value.description = this.buildDescription();
      this.criterionService.saveData(this.criterionId, this.form.value);
  
      this.form.valueChanges.subscribe(value => {
        value.description = this.buildDescription();
            value.invalid = this.form.invalid;
            console.log('==================================== = ' );
            console.log('Choice = ' + value['external']);
        this.criterionService.saveData(this.criterionId, value);
      });
    }
  
    buildDescription() {
      let description: string;
      if (this.form.invalid) { // is invalid
        description = 'Configuration has validation errors. Double click to configure.';
      } else if (this.externals.length == 0) { // is valid (means configured), but roless have not been loaded yet
        description = this.data.description;
      } else if (this.external.value) { // is valid and roles have already been loaded
        const name = this.externals.find(external => external.id == this.external.value).name;
        description = `AOs with externals... \'${name}\'.`
      } else {
        description = `Double click to configure...`;
      }
  
      return description;
    }
  }
  