<div class="dialog">
  <div class="header">
  	<div>Users Pr Agreement...</div>
  </div>
  <div class="content">
    
    <form [formGroup]="form" #ngForm="ngForm">
      
      
      <ng-select class="custom"
        style="left: 0px; width: 375px;" 
        [items]="agrtypes" 
        bindLabel="name" 
        bindValue="id" 
        placeholder="{{ agplaceholder }}"
        [formControl]="textValue" required>
      </ng-select>
    
      <ng-select 
      	class="custom" 
      	style="position: absolute; width: 170px; top: 35px;"
      	[items]="operators" 
      	bindLabel="name" 
      	bindValue="id" 
      	placeholder="{{ placeholder }}" 
      	[formControl]="operator">
      </ng-select>


      <label
        style="position: absolute; width: 80px; height: 24px; left: 180px; top: 35px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      	  <input type="text" maxlength="5096" 
      	    vmcOnlyNumber [formControl]="startCount" 
      	    style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;"> 
      	</span>
      </label>
          
      <!-- visible only if 'between'  -->
      <div *ngIf="operator.value == 'between'" style="position: absolute; left: 265px; top: 37px; font-weight: normal">and</div>

      <label *ngIf="operator.value == 'between'"
        style="position: absolute; width: 80px; height: 24px; left: 295px; top: 35px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="text" maxlength="5096" 
      		  vmcOnlyNumber [formControl]="endCount" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>
      
    
    </form>

  </div>
</div>