<div class="dialog">
  <div class="header">
  	<div>Contacts having Community country...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">
      <ng-select class="custom" style="left: 0px; width: 375px;"
      	[items]="countries" bindLabel="name" bindValue="id" placeholder="{{ placeholder }}"
      	[formControl]="textValue" required>
      </ng-select>
    </form>
  </div>
</div>