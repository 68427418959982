import { 
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	Renderer2,
	SimpleChange,
	SimpleChanges,
	TemplateRef,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'vmc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {

  private mouseDownEventTarget: EventTarget;

  @Input('disable')
  _disable: boolean = false;

  @Input('sel')
  _sel: boolean = false;

  @Output()
  execute: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ContentChild('content') 
  content: TemplateRef<any>;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    if (this._sel) {
      const htmlElement: HTMLElement = this.elementRef.nativeElement;
      const span = htmlElement.querySelector('span') as HTMLElement;
      this.renderer.addClass(span, 'sel');   
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  	const disable: SimpleChange = changes._disable;
    if (disable) {
  	  this._disable = disable.currentValue;

  	  if(this._disable) 
  		  this.disable();
  	  else
  		  this.enable();
    }
  }

  @HostListener('document:mousdown', ['$event'])
  onMouseDownOutside(event: MouseEvent) {
  	this.mouseDownEventTarget = event.target;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUpOutside(event: MouseEvent) {
  	this.mouseDownEventTarget = null;
  }    

  onMouseEnter(event: MouseEvent) {
  	if (this.elementRef.nativeElement.contains(event.target)) {
  		const htmlElement: HTMLElement = this.elementRef.nativeElement;
  		const span = htmlElement.querySelector('span') as HTMLElement;
  		this.renderer.addClass(span, 'hover');

  		if (this.elementRef.nativeElement.contains(this.mouseDownEventTarget)) {
  			this.renderer.addClass(span, 'active');
  		}
  	}
  }

  onMouseLeave(event: MouseEvent) {
  	if (this.elementRef.nativeElement.contains(event.target)) {
  		const htmlElement: HTMLElement = this.elementRef.nativeElement;
  		const span = htmlElement.querySelector('span') as HTMLElement;
  		this.renderer.removeClass(span, 'hover');
  		this.renderer.removeClass(span, 'active');
  	}

  }

  onMouseDown(event: MouseEvent) {
  	this.mouseDownEventTarget = event.target;
  	if (this.elementRef.nativeElement.contains(event.target)) {
  		const htmlElement: HTMLElement = this.elementRef.nativeElement;
  		const span = htmlElement.querySelector('span') as HTMLElement;
  		this.renderer.addClass(span, 'active');
  	} 	  	
  }

  onMouseUp(event: MouseEvent) {
  	if (this.elementRef.nativeElement.contains(event.target) && this.elementRef.nativeElement.contains(this.mouseDownEventTarget)) {
  		const htmlElement: HTMLElement = this.elementRef.nativeElement;
  		const span = htmlElement.querySelector('span') as HTMLElement;
  		this.renderer.removeClass(span, 'active');

  		if (!this._disable)
  			this.execute.emit(event);
  	}	
  }  

  private disable() {
  	const htmlElement: HTMLElement = this.elementRef.nativeElement;
  	const span = htmlElement.querySelector('span') as HTMLElement;

  	this.renderer.addClass(span, 'disabled');
  }

  private enable() {
  	const htmlElement: HTMLElement = this.elementRef.nativeElement;
  	const span = htmlElement.querySelector('span') as HTMLElement;
  	this.renderer.removeClass(span, 'disabled');
  }
}
