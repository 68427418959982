<div class="dialog">
  <div class="header">
  	<div>Active group of company contacts with industry code...</div>
  </div>
  <div class="content">
    
    <form [formGroup]="form" #ngForm="ngForm">

      <vmc-checkbox [text]="checkboxText" 
	    [initialValue]="negated.value" (check)="onCheckBoxClick($event)"
      style="position: absolute; left: 0px; top: 4px; height: 16px;"></vmc-checkbox>
        
      <ng-select 
      	class="custom" 
      	style="left: 50px; width: 170px;"
      	[items]="operators" 
      	bindLabel="name" 
      	bindValue="id" 
      	placeholder="{{ placeholder }}" 
      	[formControl]="operator">
      </ng-select>


      <label
        style="position: absolute; width: 60px; height: 24px; left: 230px;top: 0px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      	  <input type="text" maxlength="5096" 
      	    vmcOnlyNumber [formControl]="startCount" 
      	    style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;"> 
      	</span>
      </label>


      <!-- visible only if 'between' -->
      <div *ngIf="operator.value == 'between'" style="position: absolute; left: 300px; top: 5px; font-weight: normal">and</div>

      <label *ngIf="operator.value == 'between'"
        style="position: absolute; width: 60px; height: 24px; left: 330px; top: 0px; border: 1px solid #dfe4e7; background: #fcfdfe; border-radius: 2px;">
      	<span style="position: absolute; top: 0; right: 5px; bottom: 0; left: 5px">
      		<input type="text" maxlength="5096" 
      		  vmcOnlyNumber [formControl]="endCount" 
      		  style="width: 100%; height: 100%; position: absolute; padding: 0; border: 0; color: #333333;">
      	</span>
      </label>
</form>
    </div>
</div>