import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searched-lithium',
  templateUrl: './searched-lithium.component.html',
  styleUrls: ['./searched-lithium.component.scss']
})
export class SearchedLithiumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
