<div class="dialog">
  <div class="header">
  	<div>Contacts having Lithium role...</div>
  </div>
  <div class="content">
    <form [formGroup]="form" #ngForm="ngForm">
	  
	  <ng-select 
	  	class="custom" style="position: absolute; width: 300px; height: 24px; left: 0px;" 
	  	[items]="roles" bindLabel="name" bindValue="id" placeholder="{{ placeholder }}" [formControl]="role">
	  </ng-select>

  	  <vmc-button class="sc-view" [disable]="disableAdd" (execute)="onAdd($event)" style="left: 306px; width: 80px; height: 24px;">
  	    <ng-template #content>Add</ng-template>
      </vmc-button>
  	  
  	  <vmc-button class="sc-view" [disable]="disableRemove" (execute)="onRemove($event)" style="left: 401px; width: 80px; height: 24px;">
  	    <ng-template #content>Remove</ng-template>
      </vmc-button>
      
      <div class="sc-view scroll-view asset-list" style="left: 0px; right: 0px; top: 30px; height: 100px;">
      	<div *ngFor="let role of selectedRoles" class="sc-view asset-list-item" (mousedown)="onSelect({ id: role.id, event: $event })">
          <div class="sc-outline">
          	<label>{{ role.name }}</label>
          </div>
      	</div>
      </div>
    
    </form>
  </div>
</div>