<div class="dialog">
  <div class="header">
  	<img class="ic-warning" src="/assets/warning_24px.svg">
  	<span>{{dialogConfig.data.header}}</span>
  </div>
  <div class="content">
  	<p class="description">{{dialogConfig.data.content}}</p>
  </div>
  <div class="footer">

  	<vmc-button (execute)="onClose()" style="float: right; width: 115px; margin-right: 10px;">
      <ng-template #content>OK</ng-template>
    </vmc-button>  

  </div>
</div>