import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogConfig } from '../../../../shared/dialog/dialog-config';
import { CriterionService } from '../criterion.service';
import { DataService, ListItem } from '../../../data.service';

@Component({
  selector: 'app-ec-dk-users-pr-agreement',
  templateUrl: './ec-dk-users-pr-agreement.component.html',
  styleUrls: ['./ec-dk-users-pr-agreement.component.scss']
})
export class EcDkUsersPrAgreementComponent implements OnInit {

  agrtypes: ListItem<string, string>[] = [
    {'id': 'Company', 'name': 'Company'},
    {'id': 'Group of Companies', 'name': 'Group of Companies'},
    {'id': 'AO', 'name': 'AO'},
    ];

  operators = [
  	{ id: 'equal', name: 'Exactly' },
  	{ id: 'greater', name: 'Greater Than' },
  	{ id: 'less', name: 'Fewer Than' },
    { id: 'between', name: 'Between' }
  ];  

  form: FormGroup;

  agplaceholder: string = "Select agreement type";
  placeholder: string = "Select an operator";

   data: any;

  private readonly criterionId: number;

  constructor(
    private formBuilder: FormBuilder, 
    private dialogConfig: DialogConfig, 
    private criterionService: CriterionService) {
  	this.criterionId = this.dialogConfig.data.id;
  }

  get textValue() {
    return this.form.get('textValue');
  }

  get operator() { 
  	return this.form.get('operator'); 
  }
  get startCount() {
    return this.form.get('startCount');
  }
  get endCount() {
    return this.form.get('endCount');
  }

  ngOnInit() {
  	const data = this.criterionService.getData(this.criterionId);

  	this.form =  this.formBuilder.group({
      'textValue': this.formBuilder.control(data.textValue, [Validators.required]),
   		'operator': this.formBuilder.control(data.operator, [Validators.required]),
      'startCount': this.formBuilder.control(data.startCount, [Validators.required]),
      'endCount': this.formBuilder.control(data.endCount, [Validators.required])
  	});

    if (['equal', 'greater', 'less'].includes(data.operator)) {
  		this.endCount.disable({ emitEvent: false });
  	} else if (data.operator == 'between') {
  		this.endCount.enable({ emitEvent: false });
  	}

    let formValue = this.form.value;
  	formValue.invalid = this.form.invalid;
    formValue.description = this.buildDescription(formValue);
    this.criterionService.saveData(this.criterionId, formValue);

    this.form.valueChanges.subscribe(value => {

      if (['equal', 'greater', 'less'].includes(value.operator)) {
    		this.endCount.disable({ emitEvent: false });
      } 
      if (value.operator == 'between') {
        this.endCount.enable({ emitEvent: false });
      }
    

      value.invalid = this.form.invalid;
      console.log('==================================== = ' );
     // console.log('AGREEMENT_TYPE = ' + value['agrtype']);
      value.startCount = value.startCount && value.startCount.toString().replace(/^0+(?=.)/, '');
      value.endCount = value.endCount && value.endCount.toString().replace(/^0+(?=.)/, '');
      value.description = this.buildDescription(value);
  		this.criterionService.saveData(this.criterionId, value);

  	});
  }

  buildDescription(value: any) {
  	let activityRestriction: string;
  	let description: string;

  	if (this.form.invalid) {
  		description = 'Configuration has validation errors. Double click to configure.';
  	} else {
  		  const operatorIndex = this.operators.findIndex(operator => operator.id == value.operator);

        if (operatorIndex > -1 && value.operator == 'between' && value.startCount && value.endCount) {
  			  activityRestriction = `${this.operators[operatorIndex].name} ${value.startCount} and ${value.endCount}`;
  	  	} else if (operatorIndex > -1 && ['equal', 'greater', 'less'].includes(value.operator) && value.startCount !== '') {
  			    activityRestriction = `${this.operators[operatorIndex].name} ${value.startCount}`;
  	    }
      
        if (value.textValue && activityRestriction) {
          const name = this.agrtypes.find(agrtype => agrtype.id == value.textValue).name;
  			  description = `Number of users of the agreetent type  \'${name}\ '  ${activityRestriction}`;
        //  description = `Contacts with the agreetent type  ${activityRestriction}`;
  		  } else {
  			  description = "Double click to configure...";
  		}		
  	}
  	return description;
  }
}